export function getMessage(
  model: string,
  type: 'create' | 'update' | 'delete' | 'error' | 'import' = 'create',
  suffixAction: string = 'o'
): string {
  switch (type) {
    case 'create':
      return `${model} criad${suffixAction} com sucesso!`;
    case 'update':
      return `${model} atualizad${suffixAction} com sucesso!`;
    case 'delete':
      return `${model} removid${suffixAction} com sucesso!`;
    case 'import':
      return `${model} importad${suffixAction} com sucesso!`;
    case 'error':
      return `Não foi possível prosseguir. Tenta novamente mais tarde!`;
  }
}
