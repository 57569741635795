import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import Sku_Api from 'app/api/Sku_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSku, IDataSkuErrors, SkuExtraOptions, SkuRelations } from 'app/types/data/IDataSku';
import React from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';

export interface ISelectSku {
  id?: string;
  value?: IDataSku;
  disabled?: boolean;
  error?: string;
  relation?: IDataRelation<SkuRelations>;
  onChange?: (product?: IDataSku) => void;
  onChangeExtraOptions?: (options: SkuExtraOptions[]) => void;
  label?: string;
}

const to_keep_options = [
  { name: 'Atributos', value: 'atributos' },
  { name: 'Unidades', value: 'unidades' },
  { name: 'Mídia', value: 'midia' },
  { name: 'Orientações', value: 'orientacoes' },
];

const SelectSku = (props: ISelectSku) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataSku>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataSku> | undefined>(
    undefined
  );

  const [errors, setErros] = React.useState(props.error);

  const [toKeep, setToKeep] = React.useState<SkuExtraOptions[]>(
    to_keep_options.map<SkuExtraOptions>((obj) => obj.value as SkuExtraOptions)
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      load();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (props.onChangeExtraOptions) props.onChangeExtraOptions(toKeep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toKeep]);

  React.useEffect(() => {

    const comboValue: ISelectOption<IDataSku> | undefined = props.value
      ? {
        label: props.value?.name,
        value: props.value?.id as number,
        data: props.value,
      }
      : undefined;

    setComboOption(comboValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await Sku_Api.list({ q: search }, props.relation);

      const combo: ISelectOption<IDataSku>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <Autocomplete
        id={props.id}
        key={comboOption?.data?.id}
        noOptionsText="Nenhum produto encontrado"
        options={comboData}
        defaultValue={comboOption ? comboOption : undefined}
        value={comboOption}
        loading={loading}
        onClose={() => setSearch('')}
        //disabled={props.disabled}
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option && option.data) {
            setComboOption(option);
            if (props.onChange) props.onChange(option.data);
          } else {
            setComboOption(undefined);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(props, option) => (
          <Option key={option.value} props={props} option={option} />
        )}
        renderInput={(params) => (
          <TimedTextField
            value={props.value}
            {...params}
            label={props.label || 'SKUs da Base Umami'}
            placeholder="Pesquisar um sku"
            type="text"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors || props.error}
              </Typography>
            }
          />
        )}
      />
      {comboOption && props.onChangeExtraOptions && (
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={toKeep?.includes(option.value as SkuExtraOptions)}
                      onChange={() => {
                        if (toKeep?.includes(option.value as SkuExtraOptions)) {
                          const filteredOptions = toKeep.filter((opt) => opt !== option.value);
                          setToKeep(filteredOptions);
                        } else {
                          setToKeep([
                            ...(toKeep as SkuExtraOptions[]),
                            option.value as SkuExtraOptions,
                          ]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SelectSku;
