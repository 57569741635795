import { Autocomplete, Typography } from '@mui/material';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';

import Activity_Api from 'app/api/Activity_Api';
import {
  ActivityRelations,
  IDataActivity,
  IDataActivityErrors,
} from 'app/types/data/IDataActivity';
import React from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';

export interface ISelectActivity {
  id?: string;
  value?: IDataActivity | null;
  disabled?: boolean;
  label?: string;
  error?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  activityActual?: IDataActivity | null;
  onChange?: (Activity?: IDataActivity) => void;
  relation?: IDataRelation<ActivityRelations>;
  getCurrentSelected?: (data?: IDataActivity) => void;
}

const SelectPredecessorActivity = (props: ISelectActivity) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataActivity>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataActivity> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataActivity> | undefined = props.value
      ? {
          label: props.value?.summary,
          value: props.value?.id as number,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await Activity_Api.listPredecessores(
        {
          q: search,
          per_page: 5,
          //sort: 'start_date',
          //order: 'asc',
          atividade_atual: props.activityActual?.id || undefined,
        },
        props.relation
      );

      const combo: ISelectOption<IDataActivity>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.summary,
        data: item,
      }));

      setComboData(combo);

      //This is used to set the value of combo from where it was called
      if (!comboOption && !props.value)
        props.getCurrentSelected && props.getCurrentSelected(combo?.[0]?.data);
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        const err = error as IDataActivityErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <Autocomplete
      id={props.id}
      key={comboOption?.data?.id}
      noOptionsText="Nenhuma atividade encontrada"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption}
      loading={loading}
      onClose={() => setSearch('')}
      disabled={props.disabled}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      renderOption={(props, option) => (
        <Option
          key={option.value}
          props={props}
          option={option}
          secondary={`W: ${option.data?.workflow?.name} > E: ${option.data?.stage?.name}`}
        />
      )}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label={props.label || 'Atividade'}
          placeholder={`Pesquisar ${'um ' + props.label || 'uma atividade'}`}
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          variant={props.variant || 'outlined'}
          helperText={
            <Typography color="red" variant="caption" component="span">
              {props.error || errors}
            </Typography>
          }
        />
      )}
    />
  );
};

export default SelectPredecessorActivity;
