import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  WorkflowFields,
  WorkflowRelations,
  IWorkflowPagination,
  IDataWorkflow,
  IDataWorkflowErrors,
  IHookFormWorkflow,
  IWorkflowFilter,
} from 'app/types/data/IDataWorkflow';
import { AxiosError } from 'axios';
import { format, toDate } from 'date-fns';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';
import QueryString from 'qs';

export default class Workflow_Api extends Model_Api {
  static self = new Workflow_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataWorkflowErrors {
    const resp: IDataWorkflowErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormWorkflow[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as WorkflowFields;
      const item: IHookFormWorkflow = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IWorkflowFilter,
    relation?: IDataRelation<WorkflowRelations>
  ): Promise<IWorkflowPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/workflow`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as IWorkflowPagination;
      else return (await Api.get(Endpoints.Workflow.main, { params })).data as IWorkflowPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Workflows', handleErros);
      throw handleErros;
    }
  }

  static async all(params?: IWorkflowFilter): Promise<IWorkflowPagination> {
    try {
      return (await Api.get(`${Endpoints.Workflow.main}/tudo`, { params }))
        .data as IWorkflowPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Workflows', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataWorkflow,
    relation?: IDataRelation<WorkflowRelations>,
    toKeep?: string[]
  ): Promise<IDataWorkflow> {
    try {
      var started_in = data.started_in ? format(data.started_in, 'yyyy-MM-dd') : null;
      var ends_in = data.ends_in ? format(data.ends_in, 'yyyy-MM-dd HH:mm:ss') : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/workflow`, {
            ...data,
            started_in,
            ends_in,
            to_keep: toKeep,
          })
        ).data.workflow as IDataWorkflow;
      } else {
        resp = (
          await Api.post(Endpoints.Workflow.main, {
            ...data,
            started_in,
            ends_in,
          })
        ).data.workflow as IDataWorkflow;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Workflows', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataWorkflow,
    relation?: IDataRelation<WorkflowRelations>
  ): Promise<IDataWorkflow> {
    try {
      const started_in =
        data.started_in && Boolean(Date.parse(data.started_in + ''))
          ? format(toDate(Date.parse(data.started_in + '')), 'yyyy-MM-dd')
          : null;
      const ends_in =
        data.ends_in && Boolean(Date.parse(data.ends_in + ''))
          ? format(toDate(Date.parse(data.ends_in + '')), 'yyyy-MM-dd')
          : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/workflow`, {
            ...data,
            started_in,
            ends_in,
          })
        ).data.workflow as IDataWorkflow;
      } else {
        resp = (
          await Api.put(`${Endpoints.Workflow.main}/${data.id}`, {
            ...data,
            started_in,
            ends_in,
          })
        ).data.workflow;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Workflows', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataWorkflow,
    relation?: IDataRelation<WorkflowRelations>
  ): Promise<IDataWorkflow> {
    try {
      var resp: IDataWorkflow;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/workflow/${data.id}`
          )
        ).data.workflow;
      else resp = (await Api.delete(`${Endpoints.Workflow.main}/${data.id}`)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Workflows', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    Workflow?: IDataWorkflow
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          Workflow
            ? `${Endpoints.Workflow.main}/${Workflow.id}/attachments`
            : `/media${Endpoints.Workflow.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Workflow', handleErros);
      throw handleErros;
    }
  }

  static async attach(Workflow: IDataWorkflow, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Workflow.main}/${Workflow.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Workflow', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Workflow.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Workflow', handleErros);
      throw handleErros;
    }
  }
}
