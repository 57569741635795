import React, { Fragment } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import propTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function NumberFormtCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      isNumericString
      allowLeadingZeros
      thousandSeparator="."
    />
  );
}

NumberFormtCustom.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

const NumberInput = (props: TextFieldProps) => {
  return (
    <Fragment>
      <TextField
        InputLabelProps={{ shrink: true }}
        {...props}
        InputProps={{
          ...props.InputProps,
          inputComponent: NumberFormtCustom,
        }}
      />
    </Fragment>
  );
};

export default NumberInput;
