import FormContainer from 'app/components/Containers/FormContainer';
import FormSegmentation from 'app/components/Forms/Segmentation';
import SegmentationContext from 'app/contexts/SegmentationContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSegmentation, SegmentationRelations } from 'app/types/data/IDataSegmentation';
import DisplayCategoriesAttribute from 'app/views/curated/category_attribute/components/DisplayCategoriesAttribute';
import DisplaySegmentationTypes from 'app/views/curated/segmentation_types/components/DisplaySegmentationTypes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface ISegmentationFormContainer {
  data?: IDataSegmentation;
  relation?: IDataRelation<SegmentationRelations>;
  editDisabled?: boolean;
  onClickAttach?: () => void;
  onSubmit?: (segmentation?: IDataSegmentation) => void;
  cancel: () => void;
  refreshGrid?: () => void;
  setSelected: (data) => void;
  uuid?: string;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const SegmentationFormContainer = (props: ISegmentationFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('0')
  const [data, setData] = React.useState<IDataSegmentation | undefined>(props.data);

  async function getItemsMenu() {
    try {
      let tabMenu: any = [
        {
          title: 'Árvore de Categorias',
          component: (
            <DisplayCategoriesAttribute
              displayName="Árvore de Categorias"
              view="tree"
              relation={{ id: props.data!.id as number, relation: 'Segmentation' }}

            />
          ),
        },
        {
          title: 'Tipos',
          component: (
            <DisplaySegmentationTypes
              relation={{ id: props.data!.id as number, relation: 'Segmentation' }}

            />
          ),
        },
      ]
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'BIBLIOTECA DE ÁRVORES')) {

          const filho = funcFilhos.find(f => f.nome === 'BIBLIOTECA DE ÁRVORES')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("SEGMENTAÇÕES", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("BIBLIOTECA DE ÁRVORES", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }
      if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hidden = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Árvore de Categorias',
            component: (
              <DisplayCategoriesAttribute
                displayName="Árvore de Categorias"
                view="tree"
                relation={{ id: props.data!.id as number, relation: 'Segmentation' }}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de categorias')?.campos}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de categorias')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de categorias')?.sequency].nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de categorias')?.sequency}

              />
            ),
          },
          {
            title: 'Tipos',
            component: (
              <DisplaySegmentationTypes
                relation={{ id: props.data!.id as number, relation: 'Segmentation' }}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'tipos')?.campos}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'tipos')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'tipos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'tipos')?.sequency].nome)
                }}
              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);

  return (
    <SegmentationContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <FormSegmentation
            data={data}
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => {
              if (props.onSubmit)
                props.onSubmit(item)
            }}
            onCancel={props.cancel}
            refreshGrid={props?.refreshGrid}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </SegmentationContext.Provider>
  );
};

export default SegmentationFormContainer;
