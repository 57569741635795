import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const WorkflowPhasesSchema = yup.object().shape({
  name: yup.string().required(FormMessages('Nome').validations.required),
  description: yup.string().nullable(),
  started_in: yup.date().typeError('O Valor deve ser uma data válida').nullable(),
  solve_until: yup
    .date()
    .typeError('O Valor deve ser uma data válida')
    .nullable()
    .test(
      'maiorData',
      'A data que termina deve ser maior do que a data que inicia',
      function (value) {
        const { started_in } = this.parent;
        if (value) return value >= started_in;
        else return true;
      }
    ),
});
