import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const SegmentationSchema = yup.object().shape({
  name: yup.string().required(FormMessages('Nome').validations.required),
  modelo_segmentacao_id: yup
    .number()
    .required(FormMessages('Modelo de segmentação').validations.required)
    .nullable(),
  segmento_segmentacao_id: yup
    .number()
    .required(FormMessages('Segmento').validations.required)
    .nullable(),
});
