import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Paper } from '@mui/material';
import StepperNavigationButton from './StepperNavigationButton';
import Steps from './Steps';
import { KanbanStep } from 'app/types/IKanban';
import { useKanban } from 'app/contexts/Kanban/KanbanStepsContext';

interface KanbanStepperProps {
  steps: KanbanStep[];
}

export default function KanbanStepper({ steps }: KanbanStepperProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [hideLeftArrow, setHideLeftArrow] = React.useState(false);
  const [hideRightArrow, setHideRightArrow] = React.useState(false);
  const { setCurrentStep } = useKanban();

  const handleScroll = () => {
    const container = containerRef.current;

    if (container) {
      // Check if there's scrollable content
      const hasScrollableContent = container.scrollWidth > container.offsetWidth;

      // Check if the container is scrolled all the way to the left
      if (container.scrollLeft === 0 || !hasScrollableContent) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }

      // Check if the container is scrolled all the way to the right
      if (
        container.scrollLeft + container.offsetWidth >= container.scrollWidth ||
        !hasScrollableContent
      ) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
    }
  };

  const scrollToLeft = () => {
    const container = containerRef.current;
    container?.scrollBy({ left: -600, behavior: 'smooth' });
  };

  const scrollToRight = () => {
    const container = containerRef.current;
    container?.scrollBy({ left: 600, behavior: 'smooth' });
  };
  return (
    <Paper
      sx={{
        mb: 3,
        background: '#f4f5f7',
        overflow: 'hidden',
      }}
      elevation={0}
    >
      <Box sx={{ width: '100%' }} display="flex" alignItems="center">
        <StepperNavigationButton
          iconComponent={<ArrowBackIosIcon />}
          onClick={scrollToLeft}
          hideArrow={hideLeftArrow}
        />
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          sx={{ maxWidth: '100%', margin: '0 15px', overflow: 'hidden' }}
          ref={containerRef}
          onScroll={handleScroll}
        >
          <Steps steps={steps} onStepChange={(step, data) => setCurrentStep({ step, data })} />
        </Box>
        <StepperNavigationButton
          iconComponent={<ArrowForwardIosIcon />}
          onClick={scrollToRight}
          hideArrow={hideRightArrow}
          alignRight={true}
        />
      </Box>
    </Paper>
  );
}
