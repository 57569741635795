import React from 'react';
import { Icon, IconButton, TextField } from '@mui/material';

declare interface ISearchInput {
  value?: string;
  onPressEnter?: (value: string) => void;
  onIconClick?: (value: string) => void;
  onClear?: (value: string) => void;
}

const SearchInput = (props: ISearchInput) => {
  const [val, setVal] = React.useState(props.value || '');

  React.useEffect(() => {
    if ((props.value ?? '') != val) {
      setVal(props.value ?? '');
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.onClear) {
      if (!val) {
        props.onClear('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (props.onPressEnter) {
        props.onPressEnter(val);
      }
    }
  };

  const handleIconClick = () => {
    if (props.onIconClick) {
      props.onIconClick(val);
    }
  };

  return (
    <TextField
      sx={{
        '& .MuiInputBase-input': { padding: 0, paddingLeft: '10px' },
        '& .MuiInputBase-root': { padding: 0 },
        mr: 2,
      }}
      id="standard-bare"
      value={val}
      onKeyDown={handleKeyPress}
      onChange={(e) => setVal(e.target.value)}
      variant="standard"
      placeholder="Pesquisar registros..."
      size="small"
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleIconClick}>
            <Icon>search</Icon>
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchInput;
