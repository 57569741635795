import React, { createContext, useContext, useState } from 'react';

interface FormData {
  [key: string]: any;
}

declare interface UtilsContextValues {
  resetFormList: Record<string, FormData>;
  handleResetForm: (value: string) => void;
  setResetFormData: (value: object) => void;
}

const initialValues: UtilsContextValues = {
  handleResetForm: () => {},
  resetFormList: {},
  setResetFormData: () => {},
};

const UtilsContext = createContext(initialValues);

export const UtilsContextProvider = ({ children }) => {
  const [resetFormList, setResetFormData] = useState<any>({});
  const handleResetForm = (formName: string) => {
    setResetFormData((prev) => ({ ...prev, [formName]: !prev[formName] }));
  };

  return (
    <UtilsContext.Provider value={{ resetFormList, setResetFormData, handleResetForm }}>
      {children}
    </UtilsContext.Provider>
  );
};

export const useUtilsContext = () => useContext(UtilsContext);
