import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Icon,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material';
import React from 'react';
import RelationCheckboxes from './RelationCheckboxes';

type NonEmptyArray<T> = [T, ...T[]];

type RelationsAvailableType = 'skus' | 'produtos';

interface ToolbarItem {
  type: 'copy_to_base';
  disabled?: boolean;
  visible: boolean;
  icon: string;
  title: string;
  onClick: (data) => void;
  loading: boolean;
  relationsToCopyOptions: NonEmptyArray<RelationsAvailableType>;
}

interface CopyToBaseProps {
  item: ToolbarItem;
  key: React.Key;
  toolBarProps: any;
}

const CopyToBase = ({ item, key, toolBarProps }: CopyToBaseProps) => {
  const [copyToBaseAnchorEl, setCopyToBaseAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [relations, setRelations] = React.useState<Record<string, string[]>>({});
  const open = Boolean(copyToBaseAnchorEl);
  const id = open ? 'copy_to_base_popover' : undefined;

  return (
    <React.Fragment>
      {!item?.loading ? (
        <Tooltip title={item.title}>
          <Button
            disabled={item?.disabled}
            color="inherit"
            sx={{ mr: 3, p: 0, minWidth: 'initial' }}
            onClick={(event) => {
              setCopyToBaseAnchorEl(event.currentTarget);
            }}
          >
            <Icon>{item.icon}</Icon>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Enviando...">
          <CircularProgress size={25} sx={{ mr: 3, p: 0 }} />
        </Tooltip>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={copyToBaseAnchorEl}
        onClose={() => setCopyToBaseAnchorEl(null)}
        sx={{ zIndex: 7 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Box width="300px" p={2}>
            <FormLabel component="legend">Quais relações deseja copiar?</FormLabel>
            <RelationCheckboxes
              onChange={(data) => setRelations(data)}
              relationsToCopy={item.relationsToCopyOptions}
            />
            <Box mt={1} width="100%" display="flex" justifyContent="flex-end" gap={2}>
              <Button
                size="small"
                variant="text"
                color="info"
                onClick={() => setCopyToBaseAnchorEl(null)}
              >
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={() => item.onClick(relations)}>
                Enviar para base
              </Button>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default CopyToBase;
