import { Grid } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import useAuth from 'app/hooks/useAuth';
import Attributes_Api from 'app/api/Attributes_Api';
import PartnerAttributes_Api from 'app/api/PartnerAttributes_Api';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import TableMUI from 'app/components/Table/TableMUI';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import ProjectContext from 'app/contexts/ProjectContext';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  AttributeRelations,
  IDataAttribute,
  IDataAttributeErrors,
} from 'app/types/data/IDataAttribute';
import { IDataPartner } from 'app/types/data/IDataPartner';
import {
  IDataPartnerAttribute,
  IDataPartnerAttributeErrors,
  PartnerAttributeRelations,
} from 'app/types/data/IDataPartnerAttribute';
import { IDataUser } from 'app/types/data/IDataUser';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import TreeViewAttributes from 'app/views/curated/attribute/components/AttributeTreeView';
import React, { Fragment, SetStateAction, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import AttributeFormContainer from '../AttributeFormContainer';
import { LocalsForm } from 'app/components/Forms/@Types/IFormAttribute';
import handleOnSortModelChange from 'app/utils/handleOnSortModelChange';
import selectRow from 'app/utils/selectRow';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { useLocation, useParams } from 'react-router-dom';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
import * as XLSX from 'xlsx';

const hiddenColumns = {
  projeto_id: false,
  parent_id: false,
  parent: false,
  classificacao: false,
  create_user_id: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.2, type: 'number' },
  /* {
    field: 'nivel',
    headerName: 'Nível',
    flex: 0.25,
    editable: false,
    filterable: false,
  }, */
  {
    field: 'nm_atributo',
    headerName: 'Atributo',
    flex: 1,
    editable: false,
  },
  {
    field: 'parent_id',
    headerName: 'ID do Pai',
    hideable: true,
    filterable: false,
  },
  {
    field: 'parent',
    headerName: 'Atributo Pai',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataAttribute>) =>
      params.value ? params.value.nm_atributo : '',
  },
  {
    field: 'hierarquia',
    headerName: 'Estrutura',
    flex: 1,
    editable: false,
    filterable: false,
  },
  {
    field: 'classificacao',
    headerName: 'Classificação',
    hideable: true,
    filterable: false,
  },
  {
    field: 'nm_host',
    headerName: 'Nome HOST',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Atributo', 'update');
const DELETE_MSG = getMessage('Atributo', 'delete');

const initialValues: IDataAttribute | IDataPartnerAttribute = {
  id: null,
  parceiro_id: null,
  atributo_id: undefined,
  nm_atributo: '',
  alias: '',
  parent_id: null,
  nivel: '',
  classificacao: '',
  parent: null,
  label: { value: 1, label: 'Obrigatório' },
  relative_to: { value: 'sku', label: 'SKU' },
  select_option: { value: '', label: '' },
};

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: false,
};

declare interface IDisplayAttribute {
  view?: 'tree' | 'grid' | 'form';
  partner?: IDataPartner;
  relation?: IDataRelation<AttributeRelations>;
  onSubmit?: (item?: any) => void;
  sequency?: any;
  local?: LocalsForm;
  campos?: IDataBpmCampo[]
}

const DisplayAttributes = (props: IDisplayAttribute) => {
  const { handleResetForm } = useUtilsContext();
  const { permissionsTags, showAction } = useAuth();
  const ProjectCtx = React.useContext(ProjectContext);
  const sequencyName = localStorage.getItem('sequencyName')
  const [isGridView, toggleView] = React.useState(!(sequencyName && sequencyName.toLowerCase().includes('atributo')));
  const [typeForm, setTypeForm] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [dataGrid, setDataGrid] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [selected, setSelected] = React.useState<
    IDataAttribute | IDataPartnerAttribute | undefined
  >(undefined);
  const [created, setCreated] = React.useState<IDataAttribute | IDataPartnerAttribute | undefined>(
    undefined
  );
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  const [rowStopReason, setRowStopReason] = React.useState<string>();
  const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const csvLink = React.useRef<any | null>();
  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.nm_atributo,
    displayName: 'Atributos',
    toggleView,
  });

  const gridRef = React.useRef<any | null>();

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      setDataGrid([]);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);
  useEffect(() => {
    setDataGrid([]);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation?.id]);

  React.useEffect(() => {
    if (sequencyName && sequencyName.toLowerCase().includes('atributo')) {
      toggleView(false);
      setSelected(undefined);
      setTypeForm('create')
    }

    return () => {
      const sequencyName = localStorage.getItem('sequencyName')
      if (sequencyName && sequencyName.toLowerCase().includes('atributo')) {
        localStorage.removeItem('sequencyName')
      }
    };
  }, []);


  async function load(item?: any) {


    setLoading(true);
    try {
      const paramas = {
        q: search,
        page: (props.view ?? "") === 'form' ? 1 : (page + 1),
        per_page: (props.view ?? "") === 'form' ? pageSize : 100,
        projeto_id: ProjectCtx ? (ProjectCtx.id as number) : undefined,
        is_null: !ProjectCtx ? 'projeto_id' : undefined,
        ...params,
      };
      const Attributes = await Attributes_Api.list(
        paramas,
        props.relation
      );


      setDataGrid(Attributes.data);
      const filteredData = Attributes.data.map((x, index) => {
        return {
          id: x.id,
          parent: x.parent_id || 0,
          text: x.nm_atributo,
          droppable: true,
          data: x,
        };
      });
      setData(filteredData);
      setTotal(Attributes.pagination.total);
      setTotalPages(Attributes.pagination.total_pages);
      if (!item && isGridView) {
        selectRow<IDataAttribute>({
          data: Attributes.data,
          selected,
          currentSelectedRowIndex,
          rowToSelect,
          setSelected,
        });
      }
    } catch (error: IDataAttributeErrors | IDataPartnerAttributeErrors | unknown) {
      setLoading(false);
      if (error) {
        const err = error as IDataAttributeErrors | IDataPartnerAttributeErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  async function handleUpdate(nm_atributo: string) {
    if (selected) {
      setLoading(true);

      try {
        await Attributes_Api.update(
          { ...(selected as IDataAttribute), nm_atributo }
          /* props.relation */
        );

        load();
        setToast({
          open: true,
          message: UPDATE_MSG,
          severity: initialToast.severity,
        });
      } catch (error: IDataAttributeErrors | IDataPartnerAttributeErrors | unknown) {
        const err = error as IDataAttributeErrors | IDataPartnerAttributeErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal({ ...alertModal, open: false }),
            onConfirm: () => setAlertModal({ ...alertModal, open: false }),
          });
      } finally {
        setLoading(false);
      }
    }
  }

  const processRowUpdate = async (newRow, oldRow) => {
    if (rowStopReason === 'enterKeyDown' || onSaveRow) {
      var response;
      if (props.partner) response = await PartnerAttributes_Api.update(newRow);
      else response = await Attributes_Api.update(newRow);

      load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
      setOnSaveRow(null);
      return response;
    }
    setOnSaveRow(null);
    return oldRow;
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (props.partner) {
        if (selected)
          await PartnerAttributes_Api.delete(
            selected as IDataPartnerAttribute,
            props.relation as IDataRelation<PartnerAttributeRelations>
          );
      } else {
        if (selected) await Attributes_Api.delete(selected, props.relation);
      }
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataAttributeErrors | unknown) {
      const err = error as IDataAttributeErrors;
      if (err.errors.status && err.errors.message)
        setAlertModal({
          open: true,
          title: `${err.errors.status} - Não foi possível prosseguir`,
          message: err.errors.message,
          onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
        });
    }
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: `${props.relation ? 'Retirar' : 'Remover'} atributo`,
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} o atributo{' '}
          <strong>
            {selected?.id} - {selected?.nm_atributo}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  function handleClickView() {
    if (!isGridView) setSelected(undefined);
    toggleView(!isGridView);
  }

  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      var Attributes;
      if (props.partner) {
        Attributes = await PartnerAttributes_Api.list(
          {
            q: search,
            page: 1,
            per_page: total,
            parceiro_id: props.partner.id as number,
            projeto_id: ProjectCtx ? (ProjectCtx.id as number) : null,
          },
          props.relation as IDataRelation<PartnerAttributeRelations>
        );
      } else {
        Attributes = await Attributes_Api.list(
          {
            q: search,
            page: 1,
            per_page: total,
            projeto_id: ProjectCtx ? (ProjectCtx.id as number) : undefined,
            is_null: !ProjectCtx ? 'projeto_id' : undefined,
            ...params,
          },
          props.relation
        );
      }
      Attributes.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = columns.map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...Attributes.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result);
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      const dados = await filterObjectColumns(result, 'nm_parceiro');
      const ws = XLSX.utils.aoa_to_sheet(dados);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Projetos');
      XLSX.writeFile(wb, 'Projetos.xlsx');
      // csvLink?.current?.link?.click();
    } catch (error: IDataAttributeErrors | IDataPartnerAttributeErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataAttributeErrors | IDataPartnerAttributeErrors;
        if (err.errors?.status && err.errors?.message) {
          setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      options={{
        view: {
          state: isGridView ? 'grid' : 'form',
          //disabled: props.view === 'grid' ? false : !Boolean(selected),
        },
        cancel: {
          onClick: props.view !== 'grid' ? () => setSelected(undefined) : undefined,
        },
        total: {
          value: total,
          total_pages: totalPages,
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
        typeItem: 'atributo'
      }}
      onAddClick={() => {
        toggleView(false);
        setCreated({ ...initialValues, parent_id: selected?.id || null, parent: selected });
        setSelected(undefined);
        setTypeForm('create')
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={handleClickView}
      onClickRefresh={() => load()}
      onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      onResetForm={() => handleResetForm('attributesForm')}
      onSaveRow={() => setOnSaveRow('save')}
      exportGridData={() => loadAllGridRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid container display="flex" flexDirection="column" spacing={3} >
          {!isGridView && <ToolbarComponent />}
          {isGridView && props.view === 'grid' && (
            <TableMUI
              initialState={{
                columns:

                  { columnVisibilityModel: hiddenColumns },

              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={columns}
              rows={dataGrid}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => {
                setPage(newPage);
                setRowToSelect(null);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                if (!!row.length) {
                  setSelected(row[0]?.data || row[0]);
                }
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ UNIDADES" />
              )}
            />
          )}
          {isGridView && props.view !== 'grid' && (
            <>
              <ToolbarComponent hasExportButton />
              <TreeViewAttributes
                loading={loading}
                nodeSelected={
                  selected
                    ? {
                      id: selected.id as number,
                      text: selected.nm_atributo,
                      parent: selected.parent_id as number,
                      data: selected,
                    }
                    : undefined
                }
                clearSelected={!Boolean(selected)}
                onClickNode={(node) => setSelected(node.data)}
                changePage={(value) => setPage(value)}
                data={data}
                onDrop={() => {
                  console.log('drop');
                  load();
                }}
                onUpdate={(node, newValue) => {
                  handleUpdate(newValue);
                }}
                relation={props.relation}
                totalPages={totalPages}
                page={page}
                total={total}
              />
            </>
          )}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Atributos"
            separator=";"
          />
          {!isGridView && (
            <AttributeFormContainer
              uuid={uuid}
              setSelected={(data) => setSelected(data)}
              relation={props.relation}
              data={selected || created}
              onSubmit={(item) => {
                if (props.sequency && props.onSubmit) {
                  props.onSubmit()
                } else {
                  load()
                  setSelected(item)
                }
              }}
              cancel={() => toggleView(!isGridView)}
              partner={props.partner}
              local={props.local}
              editDisabled={!showAction({ action: 'edit', tag: 'atributo' }, permissionsTags)}
              campos={props.campos}
              sequency={props.sequency}
              typeForm={typeForm}

            />
          )}
        </Grid>
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplayAttributes;
