import React from 'react';
import Modal, { IModal } from 'app/components/Modal';
import { getMessage } from 'app/utils/messages';
import Toast from 'app/components/Toast';
import FormAttachments from 'app/components/Forms/Attachments';
import { IDataErrors } from 'app/types/data/IData';
import AlertMessage from 'app/components/Alert';

export declare interface IAttachmentModal extends IModal {
  maxSize?: number;
  clearFiles?: boolean;
  errors?: IDataErrors;
  loading?: boolean;
  format?: 'attachments' | 'image' | 'media';
  onSubmit?: (files: File[], links?: String[]) => void;
  onCancel?: () => void;
  multiple?: boolean;
}

const AttachmentModal = (props: IAttachmentModal) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const CREATE_MSG = getMessage('Anexo', 'create');

  function handleClose() {
    setOpenToast(false);
  }
  return (
    <>
      <Modal open={props.open} maxWidth="sm" onClose={props.onClose} title="Anexar arquivos">
        {props.errors?.message && (
          <AlertMessage title={props.errors.status || '500'} message={props.errors.message} />
        )}
        <FormAttachments
          maxSize={props.maxSize}
          format={props.format}
          loading={props.loading}
          clearFiles={props.clearFiles}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
          errors={props.errors}
          multiple={props?.multiple}
        />
      </Modal>
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default AttachmentModal;
