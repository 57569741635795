import { useLocation } from 'react-router-dom';
import Activity_Api from 'app/api/Activity_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import FormActivity from 'app/components/Forms/Activity';
import NotificationConfForm from 'app/components/Forms/Activity/NotificationConfForm';
import WorkflowActivityContext from 'app/contexts/WorkflowActivityContext';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import DisplayComment from 'app/views/@general/comment/components/DisplayComment';
import React from 'react';
import DisplayReasons from '../../../../curated/reasons/components/DisplayReasons';
import DisplayActivity from '../DisplayActivity';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface IActivityFormContainer {
  data?: IDataActivity;
  predecessora?: IDataActivity | null;
  relation?: IDataRelation<ActivityRelations>;
  onClickAttach?: () => void;
  onSubmit?: (item?: IDataActivity) => void;
  cancel?: () => void;
  setSelected?: (data) => void;
  onFormSave?: () => void;
  uuid?: string;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const ActivityFormContainer = (props: IActivityFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('0')
  const [data, setData] = React.useState<IDataActivity | undefined>(props.data);

  async function getItemsMenu() {
    try {
      let tabMenu: any = [
        {
          title: 'Sub-Atividades',
          component: (
            <DisplayActivity
              displayName="Sub-Atividades"
              relation={{ id: data?.id as number, relation: 'Activity' }}
              view="grid"
              type="subatividades"

            />
          ),
        },
        {
          title: 'Sucessoras',
          component: (
            <DisplayActivity
              displayName="Sucessoras"
              relation={{ id: data?.id as number, relation: 'Activity' }}
              view="grid"
              type="sucessoras"
            />
          ),
        },
        {
          title: 'Motivos',
          component: (
            <DisplayReasons relation={{ id: props.data!.id as number, relation: 'Activity' }} />
          ),
        },
        {
          title: 'Comentários',
          component: (
            <DisplayComment relation={{ id: props.data!.id as number, relation: 'Activity' }} />
          ),
        },
        {
          title: 'Notificações',
          component: (
            <NotificationConfForm

              relation={{ id: data?.id as number, relation: 'Activity' }}
              refreshGrid={props.onSubmit}
            />
          ),
        },
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={Activity_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'ATIVIDADES')) {

          const filho = funcFilhos.find(f => f.nome === 'ATIVIDADES')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("ATIVIDADES", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("ATIVIDADES", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }
      if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hidden = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Sub-Atividades',
            component: (
              <DisplayActivity
                displayName="Sub-Atividades"
                relation={{ id: data?.id as number, relation: 'Activity' }}
                view="grid"
                type="subatividades"
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.campos}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sub-atividades')?.sequency]?.nome)
                }}

              />
            ),
          },
          {
            title: 'Sucessoras',
            component: (
              <DisplayActivity
                displayName="Sucessoras"
                relation={{ id: data?.id as number, relation: 'Activity' }}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sucessoras')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sucessoras')?.sequency]?.nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'sucessoras')?.sequency}
                view="grid"
                type="sucessoras"
              />
            ),
          },
          {
            title: 'Motivos',
            component: (
              <DisplayReasons relation={{ id: props.data!.id as number, relation: 'Activity' }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'motivos')?.sequency]?.nome)
                }}
              />
            ),
          },
          {
            title: 'Comentários',
            component: (
              <DisplayComment relation={{ id: props.data!.id as number, relation: 'Activity' }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'comentários')?.sequency]?.nome)
                }}
              />
            ),
          },
          {
            title: 'Notificações',
            component: (
              <NotificationConfForm
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.campos}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.sequency}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'notificações')?.sequency)}
                relation={{ id: data?.id as number, relation: 'Activity' }}
                refreshGrid={props.onSubmit}
              />
            ),
          },
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                relationData={data}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency)}
                apiModel={Activity_Api}
                onClickAttach={props.onClickAttach}
              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);

  return (
    <WorkflowActivityContext.Provider value={{ data, onFormSave: props?.onFormSave }}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <FormActivity
            setSelected={(data) => props?.setSelected && props.setSelected(data.data)}
            data={data}
            defaultPredecessora={props.predecessora}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => {
              if (props.onSubmit)
                props.onSubmit(item)
            }}
            onCancel={props.cancel}
            isSub={false}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </WorkflowActivityContext.Provider>
  );
};

export default ActivityFormContainer;
