import { Box, CircularProgress, Stack } from '@mui/material';
import useWorkflowActivities from 'app/hooks/useWorkflowActivities';
import useWorkflowPhases from 'app/hooks/useWorkflowPhases';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations } from 'app/types/data/IDataActivity';
import KanbanBoard from '../../components/KanbanBoard';
import KanbanStepper from '../../components/KanbanStepper';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';

interface ActivityKanbanProps {
  relation?: IDataRelation<ActivityRelations>;
  campos?: IDataBpmCampo[]
  sequency?: any
}

const KanbanContainer = ({ relation }: ActivityKanbanProps) => {
  const { filteredWorkflowSteps, status: workflowPhaseStatus } = useWorkflowPhases({ relation });
  const {
    filteredForKanban,
    status: activityStatus,
    fetchWorkflowActivities,
  } = useWorkflowActivities({ relation });

  if (
    (activityStatus === 'pending' && !filteredForKanban.length) ||
    (workflowPhaseStatus === 'pending' && !filteredForKanban.length)
  )
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );

  const disableKanbanStepper =
    activityStatus === 'pending' ? { pointerEvents: 'none', opacity: '0.4' } : null;

  return (
    <Box overflow="hidden" width="100%" p={0}>
      {disableKanbanStepper && (
        <Box position="absolute" top="50%" left="50%">
          <CircularProgress />
        </Box>
      )}
      {!!filteredWorkflowSteps?.length && (
        <Box sx={{ position: 'relative', ...disableKanbanStepper }}>
          <KanbanStepper steps={filteredWorkflowSteps} />
        </Box>
      )}

      <KanbanBoard
        key={JSON.stringify(filteredForKanban)}
        kanbanData={filteredForKanban}
        relation={relation}
        fetchKanbanData={fetchWorkflowActivities}
      />
    </Box>
  );
};

export default KanbanContainer;
