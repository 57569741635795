import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NodeModel } from '@minoru/react-dnd-treeview';
import styles from '../CustomNode.module.css';
import { IDataAttribute } from 'app/types/data/IDataAttribute';
import { TextField, useTheme } from '@mui/material';

type Props = {
  node: NodeModel<IDataAttribute>;
  depth: number;
  isOpen: boolean;
  isSelected?: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (node: NodeModel<IDataAttribute>) => void;
  onUpdate?: (value: string) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
  const { data } = props.node;
  const indent = props.depth * 24;
  const [visibleInput, setVisibleInput] = React.useState(false);
  const [inputText, setInputText] = React.useState<string>(data?.nm_atributo || '');

  const theme = useTheme();

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const handleSelect = () => props.onSelect(props.node);

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{
        paddingInlineStart: indent,
        cursor: 'pointer',
        backgroundColor: props.isSelected
          ? theme.palette.grey[200]
          : theme.palette.background.default,
      }}
      onClick={handleSelect}
      onDoubleClick={() => setVisibleInput(true)}
    >
      <div className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ''}`}>
        {props.node.droppable && !props.node.data?.parent_id && (
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      {visibleInput && (
        <>
          <TextField
            //label="Nome*"
            type="text"
            value={inputText}
            autoComplete={'off'}
            fullWidth
            inputProps={{
              maxLength: Boolean(props.node.data?.parent_id) ? 10000 : 255,
            }}
            variant="outlined"
            size="small"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setVisibleInput(false);
                if (props.onUpdate) props.onUpdate(inputText);
              }
            }}
            /* helperText={
              <Typography color="red" variant="caption" component="span">
                {errors.nm_atributo?.message}
              </Typography>
            } */
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          />
        </>
      )}
      {!visibleInput && (
        <>
          {/* <div>
            <strong>{data?.nivel}.</strong>{' '}
          </div> */}
          <div className={styles.labelGridItem}>
            <Typography
              noWrap
              className={styles.textName}
              variant="body1"
            >{`${props.node.text}`}</Typography>
            <Typography noWrap className={styles.alias} color="GrayText" variant="caption">
              (
              {`ID: ${props.node.data?.id} / Alias: ${
                props.node.data?.alias?.substring(0, 50) +
                  (props.node.data?.alias?.length && props.node.data?.alias?.length > 50
                    ? '...'
                    : '') || '-'
              }`}
              )
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
