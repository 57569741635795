import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  VirtualShowcaseFields,
  IDataVirtualShowcase,
  IDataVirtualShowcaseErrors,
  IHookFormVirtualShowcase,
  VirtualShowcaseRelations,
  IVirtualShowcasePagination,
  IVirtualShowcaseFilter,
} from 'app/types/data/IDataVirtualShowcase';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class VirtualShowcase_Api extends Model_Api {
  static self = new VirtualShowcase_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataVirtualShowcaseErrors {
    const resp: IDataVirtualShowcaseErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormVirtualShowcase[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as VirtualShowcaseFields;
      const item: IHookFormVirtualShowcase = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IVirtualShowcaseFilter,
    relation?: IDataRelation<VirtualShowcaseRelations>
  ): Promise<IVirtualShowcasePagination> {
    try {
      const allParams = { ...params, sort: params?.model_sort };
      delete allParams.model_sort;
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/vitrine`, {
            params: allParams,
            paramsSerializer(allParams) {
              return QueryString.stringify(allParams);
            },
          })
        ).data as IVirtualShowcasePagination;
      else
        return (
          await Api.get(Endpoints.VirtualShowcase.main, {
            params: allParams,
            paramsSerializer(allParams) {
              return QueryString.stringify(allParams);
            },
          })
        ).data as IVirtualShowcasePagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataVirtualShowcase): Promise<IDataVirtualShowcase> {
    try {
      return (await Api.get(`${Endpoints.VirtualShowcase.main}/${data.id}`)).data
        .vitrine as IDataVirtualShowcase;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async find(produto_id: number | string): Promise<IDataVirtualShowcase> {
    try {
      return (await Api.get(`publica/${Endpoints.VirtualShowcase.main}/${produto_id}`)).data
        .produto as IDataVirtualShowcase;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataVirtualShowcase,
    relation?: IDataRelation<VirtualShowcaseRelations>
  ): Promise<IDataVirtualShowcase> {
    try {
      const resp = (await Api.post(Endpoints.VirtualShowcase.main, data)).data
        .vitrine as IDataVirtualShowcase;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/vitrine/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataVirtualShowcase,
    relation?: IDataRelation<VirtualShowcaseRelations>
  ): Promise<IDataVirtualShowcase> {
    try {
      const resp = (await Api.put(`${Endpoints.VirtualShowcase.main}/${data.id}`, data)).data
        .vitrine;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/vitrine/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataVirtualShowcase,
    relation?: IDataRelation<VirtualShowcaseRelations>
  ): Promise<IDataVirtualShowcase> {
    try {
      var resp: IDataVirtualShowcase;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/vitrine/${data.id}`)
        ).data.vitrine;
      else resp = (await Api.delete(`${Endpoints.VirtualShowcase.main}/${data.id}`)).data.vitrine;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataVirtualShowcase
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.VirtualShowcase.main}/${model.id}/attachments`
            : `/media${Endpoints.VirtualShowcase.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(
    vitrine: IDataVirtualShowcase,
    files: File[],
    links?: String[],
    as_copy?: number
  ) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      links?.forEach((link, index) => {
        formData.append(`link[${index}]`, link + '');
      });
      return (
        await Api.post(`${Endpoints.VirtualShowcase.main}/${vitrine.id}/attach`, formData, {
          params: { as_copy },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.VirtualShowcase.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create VirtualShowcase', handleErros);
      throw handleErros;
    }
  }

  static async detachMetaImage(midia_id: number) {
    try {
      return (await Api.delete(`${Endpoints.VirtualShowcase.main}/${midia_id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Products', handleErros);
      throw handleErros;
    }
  }
}
