import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  SellerChannelsFields,
  IDataSellerChannels,
  IDataSellerChannelsErrors,
  IHookFormSellerChannels,
  SellerChannelsRelations,
  ISellerChannelsPagination,
} from 'app/types/data/IDataSellerChannels';
import { IParamsQs } from 'app/types/IParams';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class SellerChannels_Api extends Model_Api {
  static self = new SellerChannels_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSellerChannelsErrors {
    const resp: IDataSellerChannelsErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSellerChannels[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SellerChannelsFields;
      const item: IHookFormSellerChannels = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IParamsQs,
    relation?: IDataRelation<SellerChannelsRelations>
  ): Promise<ISellerChannelsPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/canal_vendas`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISellerChannelsPagination;
      else
        return (
          await Api.get(Endpoints.SellerChannels.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISellerChannelsPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSellerChannels): Promise<IDataSellerChannels> {
    try {
      return (await Api.get(`${Endpoints.SellerChannels.main}/${data.id}`))
        .data as IDataSellerChannels;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSellerChannels,
    relation?: IDataRelation<SellerChannelsRelations>
  ): Promise<IDataSellerChannels> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/canal_vendas`, data)
        ).data.canal_vendas;
      } else {
        resp = (await Api.post(Endpoints.SellerChannels.main, data)).data.canal_vendas;
      }

      return resp as IDataSellerChannels;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataSellerChannels,
    relation?: IDataRelation<SellerChannelsRelations>
  ): Promise<IDataSellerChannels> {
    try {
      var resp;

      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/canal_vendas`, data)
        ).data.canal_vendas;
      } else {
        resp = (await Api.put(`${Endpoints.SellerChannels.main}/${data.id}`, data)).data
          .canal_vendas;
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataSellerChannels,
    relation?: IDataRelation<SellerChannelsRelations>
  ): Promise<IDataSellerChannels> {
    try {
      var resp: IDataSellerChannels;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/canal_vendas/${data.id}`
          )
        ).data.canal_vendas;
      else
        resp = (await Api.delete(`${Endpoints.SellerChannels.main}/${data.id}`)).data.canal_vendas;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataSellerChannels
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.SellerChannels.main}/${model.id}/attachments`
            : `/media${Endpoints.SellerChannels.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(canal_vendas: IDataSellerChannels, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (
        await Api.post(`${Endpoints.SellerChannels.main}/${canal_vendas.id}/attach`, formData)
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SellerChannelss', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.SellerChannels.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SellerChannelss', handleErros);
      throw handleErros;
    }
  }
}
