import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Activity_Api from 'app/api/Activity_Api';
import Toast from 'app/components/Toast';
import WorkflowActivityContext from 'app/contexts/WorkflowActivityContext';
import WorkflowSubActivityContext from 'app/contexts/WorkflowSubActivityContext';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity, NotificationConf } from 'app/types/data/IDataActivity';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: NotificationConf = {
  sending_frequency: null,
  start_in: null,
  send_email: false,
  email_text: null,
  send_sms: false,
  sms_text: null,
  send_whatsapp: false,
  whatsapp_text: null,
};

const initialToast: IToast = {
  open: false,
  message: '',
  severity: 'success',
};

const sendingFrequencyOptions = [
  { value: 'daily', label: 'Diariamente' },
  { value: 'weekly', label: 'Semanalmente' },
  { value: 'monthly', label: 'Mensalmente' },
];

interface NotificationConfProps {
  refreshGrid?: (data: IDataActivity) => void;
  relation?: IDataRelation<ActivityRelations>;
  campos?: IDataBpmCampo[]
  sequency?: any
  onSubmit?: () => void
}

const NotificationConfForm = (props: NotificationConfProps) => {
  const activityData = React.useContext(WorkflowActivityContext);
  const subActivityData = React.useContext(WorkflowSubActivityContext);
  const { getConfigCampo } = useAuth();

  const [notificationData, setNotificationData] = React.useState<NotificationConf>(
    subActivityData?.notification_conf || activityData?.data?.notification_conf || initialValues
  );
  const [status, setStatus] = React.useState<string>('idle');
  const [toast, setToast] = React.useState<IToast>(initialToast);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NotificationConf>({
    defaultValues: notificationData,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataActivity['notification_conf']) {
    const campos = [{
      nome: 'Frequência de envio',
      campo: 'sending_frequency'
    },
    {
      nome: 'Início',
      campo: 'start_in'
    },
    {
      nome: 'Texto do E-mail',
      campo: 'email_text'
    },
    {
      nome: 'Texto do SMS',
      campo: 'sms_text'
    },
    {
      nome: 'Texto Whatsapp',
      campo: 'whatsapp_text'
    },
    ]

    campos.forEach(x => {

      if (data && !data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required)
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    const actvData = subActivityData || activityData?.data;
    const payload = {
      ...actvData,
      notification_conf: {
        ...data,
        sending_frequency: data?.sending_frequency?.value || null,
        start_in: data?.start_in && format(new Date(data.start_in), 'yyyy-MM-dd'),
      },
    };
    try {
      setStatus('pending');
      const resp = await Activity_Api.update(payload as IDataActivity, props.relation);
      setNotificationData(resp.notification_conf as NotificationConf);
      props?.refreshGrid && props.refreshGrid(resp);
      setStatus('resolved');
      setToast({
        open: true,
        message: 'Notificações salva com sucesso!',
        severity: 'success',
      });
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível salvar notificações',
        severity: 'error',
      });
      setStatus('rejected');
    }
  }

  return (
    <>
      <FormBox id="formik-orientation" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          {getConfigCampo && !getConfigCampo('Frequência de envio', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="sending_frequency"
                control={control}
                defaultValue={notificationData?.sending_frequency}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={sendingFrequencyOptions}
                    getOptionLabel={(option: ISelectOption) => option.label ?? ''}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    value={notificationData?.sending_frequency || undefined}
                    onChange={(event, option: any, reason) => {
                      setNotificationData({
                        ...notificationData,
                        sending_frequency: option ? option : null,
                      });
                      onChange(option);
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="sending_frequency"
                        label="Frequência de envio"
                        type="text"
                        size="small"
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.sending_frequency?.message}
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          }
          {getConfigCampo && !getConfigCampo('Início', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="start_in"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Início"
                      value={moment(notificationData.start_in).toDate()}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setNotificationData({
                          ...notificationData,
                          start_in: moment(newDate).toDate(),
                        });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Início"
                          id="mui-pickers-date"
                          fullWidth
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.start_in?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }
          {getConfigCampo && !getConfigCampo('Enviar E-mail', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="send_email"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationData.send_email}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setNotificationData((prev) => ({
                              ...prev,
                              send_email: isChecked,
                              email_text: isChecked ? prev.email_text : null,
                            }));
                            onChange(isChecked);
                          }}
                        />
                      }
                      label="Enviar E-mail"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          }
          {notificationData?.send_email && getConfigCampo && !getConfigCampo('Texto do E-mail', props.campos ?? []).hidden && (
            <Grid item xs={12}>
              <Controller
                name="email_text"
                control={control}
                defaultValue={notificationData.email_text}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-email_text"
                    label="Texto do E-mail"
                    multiline
                    rows={5}
                    type="text"
                    value={notificationData.email_text}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.email_text?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setNotificationData({ ...notificationData, email_text: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {getConfigCampo && !getConfigCampo('Enviar SMS', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="send_sms"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationData.send_sms}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setNotificationData((prev) => ({
                              ...prev,
                              send_sms: isChecked,
                              sms_text: isChecked ? prev.sms_text : null,
                            }));
                            onChange(isChecked);
                          }}
                        />
                      }
                      label="Enviar SMS"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          }
          {notificationData?.send_sms && getConfigCampo && !getConfigCampo('Texto do SMS', props.campos ?? []).hidden && (
            <Grid item xs={12}>
              <Controller
                name="sms_text"
                control={control}
                defaultValue={notificationData.sms_text}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-sms_text"
                    label="Texto do SMS"
                    multiline
                    rows={5}
                    type="text"
                    value={notificationData.sms_text}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.sms_text?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setNotificationData({ ...notificationData, sms_text: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {getConfigCampo && !getConfigCampo('Enviar mensagem pelo Whatsapp', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="send_whatsapp"
                control={control}
                render={({ field: { onChange } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationData.send_whatsapp}
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setNotificationData((prev) => ({
                              ...prev,
                              send_whatsapp: isChecked,
                              whatsapp_text: isChecked ? prev.whatsapp_text : null,
                            }));
                            onChange(isChecked);
                          }}
                        />
                      }
                      label="Enviar mensagem pelo Whatsapp"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
          }
          {notificationData?.send_whatsapp && getConfigCampo && !getConfigCampo('Texto Whatsapp', props.campos ?? []).hidden && (
            <Grid item xs={12}>
              <Controller
                name="whatsapp_text"
                control={control}
                defaultValue={notificationData.whatsapp_text}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-whatsapp_text"
                    label="Texto Whatsapp"
                    multiline
                    rows={5}
                    type="text"
                    value={notificationData.whatsapp_text}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.whatsapp_text?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setNotificationData({ ...notificationData, whatsapp_text: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <LoadingButton
            type="submit"
            color="primary"
            loading={status === 'pending'}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default NotificationConfForm;
