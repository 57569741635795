import FormContainer from 'app/components/Containers/FormContainer';
import UnitForm from 'app/components/Forms/Unit';
import { IDataRelation } from 'app/types/data/IData';
import { IDataUnit, UnitRelations } from 'app/types/data/IDataUnit';
import DisplayOrientation from 'app/views/curated/orientation/components/DisplayOrientation';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface IUnitFormContainer {
  data?: IDataUnit;
  relation?: IDataRelation<UnitRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataUnit) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const UnitFormContainer = (props: IUnitFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataUnit | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')
  function getMenuSequency(sequency?: any, menus?: any[]) {
    return menus?.find(x => x.sequency === sequency)
  }
  async function getItemsMenu() {
    try {
      let tabMenu: any = [
        {
          tag: 'orientacao',
          title: 'Orientações',
          component: (
            <DisplayOrientation relation={{ id: props.data!.id as number, relation: 'Unit' }} />
          ),
        },
      ]

      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'UNIDADES')) {

          const filho = funcFilhos.find(f => f.nome === 'UNIDADES')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("UNIDADES", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("UNIDADES", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }
      if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hidden = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            tag: 'orientacao',
            title: 'Orientações',
            component: (
              <DisplayOrientation onSubmit={() => {
                setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency)
                localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency].nome)
              }} campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.campos} sequency={getMenuSequency(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency, nItems)} relation={{ id: props.data!.id as number, relation: 'Unit' }} />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

            if (menuItem) {
              const menuItem = tabMenu.find(m => (m.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()) || (m.tag && m.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' - ')[0].toLowerCase()))

              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  return (
    <>
      <FormContainer
        activeTab={activeTab}
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <UnitForm
            setSelected={(data) => props.setSelected(data)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </>
  );
};

export default UnitFormContainer;
