import { FilterAlt } from '@mui/icons-material';
import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import Segmentation_Api from 'app/api/Segmentation_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataSegmentation,
  IDataSegmentationErrors,
  SegmentationRelations,
} from 'app/types/data/IDataSegmentation';
import React from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';

export interface ISelectTreeLibrary {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: IDataSegmentation;
  disabled?: boolean;
  error?: string;
  onChange?: (segmentation?: IDataSegmentation) => void;
  relation?: IDataRelation<SegmentationRelations>;
}

const SelectTreeLibrary = (props: ISelectTreeLibrary) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataSegmentation>[]>([]);
  const [comboOption, setComboOption] = React.useState<
    ISelectOption<IDataSegmentation> | undefined
  >(undefined);
  const [errors, setErros] = React.useState(props.error);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    load();
    return () => {
      // Clear the timeout when the component unmounts or when search changes
      if (timer) {
        clearTimeout(timer);
        setTimer(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataSegmentation> | undefined = props.value
      ? {
        label: props.value?.name,
        value: props.value?.id as number,
        data: props.value,
      }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    try {
      setLoading(true);
      // Debounce the function call by waiting for 500ms after the user stops typing
      const t = setTimeout(async () => {
        const items = await Segmentation_Api.list({ term: search }, props.relation);

        const combo: ISelectOption<IDataSegmentation>[] = items.data.map((item) => ({
          value: `${item.id}`,
          label: item?.name,
          data: item,
        }));
        setComboData(combo);
        setLoading(false);
      }, 700);

      // Keep track of the timer so we can clear it later
      setTimer(t);
    } catch (error: IDataSegmentationErrors | unknown) {
      if (error) {
        const err = error as IDataSegmentationErrors;
        setErros(err.errors?.message);
      }
    }
  }

  return (
    <Autocomplete
      id={props.id}
      noOptionsText="Nenhuma Biblioteca de Árvores encontrada"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption || null}
      loading={loading}
      disabled={props.disabled}
      loadingText={'Carregando...'}
      onOpen={() => setOpen(true)}
      open={open}
      filterOptions={(x) => x}
      //isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      onClose={() => {
        setOpen(false);
        setSearch('');
      }}
      renderOption={(props, option) => (
        <Option
          key={option.value}
          props={props}
          option={option}
          image={
            option.data?.image && option.data?.image.length > 0
              ? option.data?.image[0].original_url
              : '/assets/images/placeholder/placeholder_umami.jpg'
          }
          secondary={`Modelo: ${option.data?.modelo_segmentacao?.name}, Segmento: ${option.data?.segmento_segmentacao?.name}`}
        />
      )}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label={props.label || "Biblioteca de Árvores"}
          placeholder={props.placeholder || "Pesquise pelo nome, modelo ou segmento das Bibliotecas de Árvores"}
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FilterAlt />
              </InputAdornment>
            ),
          }}
          helperText={
            <Typography color="red" variant="caption" component="span">
              {errors}
            </Typography>
          }
        />
      )}
    />
  );
};

export default SelectTreeLibrary;
