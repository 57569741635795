import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NodeModel } from '@minoru/react-dnd-treeview';
import styles from '../CustomNode.module.css';
import { IDataCategory } from 'app/types/data/IDataCategory';
import { TextField, useTheme } from '@mui/material';

type Props = {
  node: NodeModel<IDataCategory>;
  depth: number;
  isOpen: boolean;
  isSelected?: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (node: NodeModel<IDataCategory>) => void;
  onUpdate?: (value: string) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
  const { data } = props.node;
  const indent = props.depth * 24;
  const [visibleInput, setVisibleInput] = React.useState(false);
  const [inputText, setInputText] = React.useState<string>(data?.nm_categoria || '');

  const theme = useTheme();

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const handleSelect = () => props.onSelect(props.node);

  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{ paddingInlineStart: indent, cursor: 'pointer', backgroundColor: props.isSelected ? theme.palette.grey[200] : theme.palette.background.default }}
      onClick={handleSelect}
      onDoubleClick={() => setVisibleInput(true)}
    >
      <div className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ''}`}>
        {props.node.droppable && (
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      {visibleInput && (
        <>
          <TextField
            //label="Nome*"
            type="text"
            value={inputText}
            autoComplete={'off'}
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            variant="outlined"
            size="small"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setVisibleInput(false);
                if (props.onUpdate) props.onUpdate(inputText)
              }
            }}
            /* helperText={
              <Typography color="red" variant="caption" component="span">
                {errors.nm_categoria?.message}
              </Typography>
            } */
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          />
        </>
      )}
      {!visibleInput && (
        <>
          <div><strong>{data?.nivel}.</strong> </div>
          <div className={styles.labelGridItem}>
            <Typography variant="body1">{`${props.node.text}`}</Typography>
          </div>
        </>
      )}

    </div>
  );
};
