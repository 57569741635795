import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const DialogRoot = styled('div')(({ theme }) => ({}));

export declare interface IAlertModal {
  title: string;
  message: string | React.ReactNode;
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  confirmTextButton?: string;
}

const AlertModal = (props: IAlertModal) => {
  return (
    <DialogRoot>
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="sm"
        onClose={props.onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{props.title}</DialogTitle>

        <DialogContent style={{ paddingTop: 8, paddingBottom: 16 }}>{props.message}</DialogContent>

        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Fechar
          </Button>
          <LoadingButton
            onClick={props.onConfirm}
            loading={props.loading}
            variant="contained"
            color="primary"
          >
            {props.confirmTextButton ?? "Confirmar"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </DialogRoot>
  );
};

export default AlertModal;
