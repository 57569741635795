import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  AttributeFields,
  AttributeRelations,
  IAttributePagination,
  IDataAttribute,
  IDataAttributeErrors,
  IDataFilterAttribute,
  IHookFormAttribute,
} from 'app/types/data/IDataAttribute';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';
import FixedSelectOptions from 'app/utils/FixedSelectOptions';
import {
  IAttributeSimple,
  ValueOption,
  ValueOptionLong,
} from 'app/views/curated/attribute/components/DisplayAttributesToSelect';

export default class Attributes_Api extends Model_Api {
  static self = new Attributes_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataAttributeErrors {
    const resp: IDataAttributeErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormAttribute[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as AttributeFields;
      const item: IHookFormAttribute = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IDataFilterAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IAttributePagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}${relation.id ? `/${relation.id}` : ""}/atributo`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      } else {
        const resp = (
          await Api.get(Endpoints.Attribute.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Attributes', handleErros);
      throw handleErros;
    }
  }

  static async listProduto(
    params?: IDataFilterAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IAttributePagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/atributo/produto`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      } else {
        const resp = (
          await Api.get(`${Endpoints.Attribute.main}/produto`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: listProduto Attributes', handleErros);
      throw handleErros;
    }
  }

  static async listSku(
    params?: IDataFilterAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IAttributePagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/atributo/sku`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      } else {
        const resp = (
          await Api.get(`${Endpoints.Attribute.main}/sku`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp.data.forEach((item) => {
          item['label'] = FixedSelectOptions.Atrribute.label[item.label as string];
          item['relative_to'] =
            FixedSelectOptions.Atrribute.relative_to[item.relative_to as string];
        });
        return resp as IAttributePagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: listProduto Attributes', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IDataAttribute> {
    try {
      const { label, relative_to, active, display, filter, ...rest } = data;
      const relationNames = ['Category', 'Sku', 'Product'];
      var resp;
      const payload = {
        ...data,
        label: data?.label?.value || null,
        relative_to: data?.relative_to?.value || null,
        display: +Boolean(data?.display),
        filter: +Boolean(data?.filter),
        active: +Boolean(data?.active),
      };
      if (relationNames.includes(relation?.relation as string)) {
        resp = (
          await Api.post(
            `${Endpoints[relation?.relation].main}/${relation?.id}/atributo`,
            data.parent_id ? rest : payload
          )
        ).data.atributo;
      } else {
        resp = (await Api.post(Endpoints.Attribute.main, data.parent_id ? rest : payload)).data
          .atributo as IDataAttribute;

        /* if (relation)
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atributo/${resp.id}`); */
      }

      resp['label'] = FixedSelectOptions.Atrribute.label[resp.label as string];
      resp['relative_to'] = FixedSelectOptions.Atrribute.relative_to[resp.relative_to as string];
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Attributes', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IDataAttribute> {
    try {
      const { label, relative_to, active, display, filter, ...rest } = data;
      const relationNames = ['Category', 'Sku', 'Product'];
      var resp;
      const payload = {
        ...data,
        label: data?.label?.value || null,
        relative_to: data?.relative_to?.value || null,
        display: +Boolean(data?.display),
        filter: +Boolean(data?.filter),
        active: +Boolean(data?.active),
      };
      if (relationNames.includes(relation?.relation as string)) {
        resp = (
          await Api.post(
            `${Endpoints[relation?.relation].main}/${relation?.id}/atributo`,
            data.parent_id ? rest : payload
          )
        ).data.atributo;
      } else {
        resp = (
          await Api.put(`${Endpoints.Attribute.main}/${data.id}`, data.parent_id ? rest : payload)
        ).data.atributo;

        /* if (relation)
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atributo/${data.id}`); */
      }
      resp['label'] = FixedSelectOptions.Atrribute.label[resp.label as string];
      resp['relative_to'] = FixedSelectOptions.Atrribute.relative_to[resp.relative_to as string];
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Attributes', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataAttribute,
    relation?: IDataRelation<AttributeRelations>
  ): Promise<IDataAttribute> {
    try {
      var resp: IDataAttribute;

      if (relation?.relation) {
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/atributo/${data.id}`
          )
        ).data;
      } else resp = (await Api.delete(`${Endpoints.Attribute.main}/${data.id}`)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Attributes', handleErros);
      throw handleErros;
    }
  }

  static async combo(search: string = '') {
    try {
      return (await Api.get(Endpoints.Attribute.combo, { params: { q: search } })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Combo Attributes', handleErros);
      throw handleErros;
    }
  }

  static async listAttributeValues(
    relation: IDataRelation<AttributeRelations>
  ): Promise<IAttributeSimple[]> {
    try {
      const resp = (
        await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/atributo-valores`)
      ).data;
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: list Attributes', handleErros);
      throw handleErros;
    }
  }

  static async updateAtributeValues(
    relation: IDataRelation<AttributeRelations>,
    values?: ValueOption[] | number,
    longTypeValues?: ValueOptionLong
  ) {
    try {
      const resp = (
        await Api.post(
          `${Endpoints[relation?.relation].main}/${relation?.id}/atributo-valores`,
          longTypeValues
            ? longTypeValues
            : {
              [relation?.relation === 'Sku' ? 'atributo' : 'valores']: values,
            }
        )
      ).data;
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Attributes Values', handleErros);
      throw handleErros;
    }
  }
}
