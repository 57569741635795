import { Autocomplete, InputAdornment, Typography } from '@mui/material';
import WorkflowPhases_Api from 'app/api/WorkflowPhases_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataWorkflowPhases,
  IDataWorkflowPhasesErrors,
  WorkflowPhasesRelations,
} from 'app/types/data/IDataWorkflowPhases';
import React from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';
import { Search } from '@mui/icons-material';
import { formatDateBR } from 'app/utils/format';

export interface ISelectWorkflowPhases {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: IDataWorkflowPhases | null;
  disabled?: boolean;
  error?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: (WorkflowPhases?: IDataWorkflowPhases) => void;
  relation?: IDataRelation<WorkflowPhasesRelations>;
  getCurrentSelected?: (data?: IDataWorkflowPhases) => void;
}

const SelectWorkflowPhases = (props: ISelectWorkflowPhases) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataWorkflowPhases>[]>([]);
  const [comboOption, setComboOption] = React.useState<
    ISelectOption<IDataWorkflowPhases> | undefined
  >(undefined);
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      load();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, props.relation]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataWorkflowPhases> | undefined = props.value
      ? {
          label: props.value?.name,
          value: props.value?.id as number,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);

    try {
      const items = await WorkflowPhases_Api.list(
        { q: search, sort: 'started_in', order: 'asc' },
        props.relation
      );

      const combo: ISelectOption<IDataWorkflowPhases>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);

      //This is used to set the value of combo from where it was called
      if (!comboOption && !props.value)
        props.getCurrentSelected && props.getCurrentSelected(combo?.[0]?.data);
    } catch (error: IDataWorkflowPhasesErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowPhasesErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Autocomplete
      id={props.id}
      noOptionsText="Nenhuma etapa encontrada"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption || comboData[0] || null}
      loading={loading}
      onClose={() => setSearch('')}
      disabled={props.disabled}
      //isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      renderOption={(props, option) => {
        const startDate = option.data?.started_in
          ? formatDateBR(option.data?.started_in, false)
          : '';
        const endDate = option.data?.solve_until
          ? formatDateBR(option.data?.solve_until, false)
          : '';

        const secondaryText = startDate && endDate ? `${startDate} -  ${endDate}` : undefined;

        return (
          <Option key={option.value} props={props} option={option} secondary={secondaryText} />
        );
      }}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label={props.label || 'Etapa'}
          placeholder={props.placeholder || 'Pesquisar uma etapa'}
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          variant={props.variant || 'outlined'}
          helperText={
            <Typography
              color={
                props.error || errors
                  ? 'red'
                  : comboOption?.data?.started_in && comboOption?.data?.solve_until
                  ? 'GrayText'
                  : undefined
              }
              variant="caption"
              component="span"
            >
              {props.error ||
                errors ||
                (comboOption?.data?.started_in && comboOption?.data?.solve_until
                  ? formatDateBR(comboOption?.data?.started_in, false) +
                    ' - ' +
                    formatDateBR(comboOption?.data?.solve_until, false)
                  : undefined)}
            </Typography>
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectWorkflowPhases;
