import React from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Segments_Api from 'app/api/Segments_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataSegments, IDataSegmentsErrors } from 'app/types/data/IDataSegments';
import Option from '../Option/Option';
import { FieldError } from 'react-hook-form';
import TimedTextField from '../../TimedTextField/TimedTextField';

export interface ISelectSegments {
  id?: string;
  value?: IDataSegments;
  disabled?: boolean;
  error?: string;
  onChange?: (Segments?: IDataSegments) => void;
  errors?: FieldError;
}

const SelectSegments = (props: ISelectSegments) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataSegments>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataSegments> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataSegments> | undefined = props.value
      ? {
          label: props.value?.name,
          value: props.value?.id as number,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await Segments_Api.list({ q: search });

      const combo: ISelectOption<IDataSegments>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataSegmentsErrors | unknown) {
      if (error) {
        const err = error as IDataSegmentsErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Autocomplete
      id={props.id}
      noOptionsText="Nenhum segmento encontrado"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption || null}
      loading={loading}
      onClose={() => setSearch('')}
      disabled={props.disabled}
      //isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      renderOption={(props, option) => <Option key={option.value} props={props} option={option} />}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label="Segmento"
          placeholder="Pesquisar um segmento"
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          helperText={
            <Typography color="red" variant="caption" component="span">
              {errors || props?.errors?.message}
            </Typography>
          }
        />
      )}
    />
  );
};

export default SelectSegments;
