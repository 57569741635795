import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  IDataSku,
  IDataSkuErrors,
  IHookFormSku,
  ISkuFilter,
  ISkuPagination,
  SkuExtraOptions,
  SkuFields,
  SkuRelations,
} from 'app/types/data/IDataSku';
import { IDataIDCurate } from 'app/types/data/IDataStore';
import FixedSelectOptions from 'app/utils/FixedSelectOptions';
import { AxiosError } from 'axios';
import { format, toDate } from 'date-fns';
import { default as QueryString, default as qs } from 'qs';
import Model_Api from './Model_Api';
import Api from './api';

export default class Sku_Api extends Model_Api {
  static self = new Sku_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSkuErrors {
    const resp: IDataSkuErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSku[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SkuFields;
      const item: IHookFormSku = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: ISkuFilter,
    relation?: IDataRelation<SkuRelations>
  ): Promise<ISkuPagination> {
    try {
      if (relation) {
        const resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/sku`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp?.data?.forEach((item) => {
          item['integration_situation'] =
            FixedSelectOptions.Sku.integration_situation[item.integration_situation] || null;
          item['integration_status'] =
            FixedSelectOptions.Sku.integration_status[item.integration_status] || null;
        });
        return resp as ISkuPagination;
      } else {
        const resp = (
          await Api.get(Endpoints.Sku.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data;
        resp?.data?.forEach((item) => {
          item['integration_situation'] =
            FixedSelectOptions.Sku.integration_situation[item.integration_situation] || null;
          item['integration_status'] =
            FixedSelectOptions.Sku.integration_status[item.integration_status] || null;
        });
        return resp as ISkuPagination;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Skus', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSku) {
    try {
      return (await Api.get(`${Endpoints.Sku.main}/${data.id}`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Skus', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSku,
    relation?: IDataRelation<SkuRelations>,
    options?: SkuExtraOptions[],
    config?: { params?: Record<string, string> } // Novo parâmetro opcional para query strings
  ): Promise<IDataSku> {
    try {
      const quality_status = data?.quality_status?.value || null;
      const status_umami = data?.status_umami?.value || null;
      const integration_situation = data?.integration_situation?.value || null;
      const integration_status = data?.integration_status?.value || null;
      const integrated_at = data.integrated_at
        ? format(toDate(new Date(data.integrated_at)), 'yyyy-MM-dd HH:mm:ss')
        : null;

      let url: string;
      if (relation) {
        url = `${Endpoints[relation.relation].main}/${relation.id}/${Endpoints.Sku.main}`;
      } else {
        url = Endpoints.Sku.main;
      }

      // Adicionando parâmetros de query string à URL
      if (config?.params) {
        const queryString = new URLSearchParams(config.params).toString();
        url += `?${queryString}`;
      }

      const payload = {
        ...data,
        quality_status,
        status_umami,
        integrated_at,
        integration_situation,
        integration_status,
        to_keep: options,
      };

      const resp: IDataSku = (
        await Api.post(url, payload)
      ).data.sku as IDataSku;

      resp['integration_situation'] =
        FixedSelectOptions.Sku.integration_situation[resp.integration_situation as string];
      resp['integration_status'] =
        FixedSelectOptions.Sku.integration_status[resp.integration_status as string];
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Skus', handleErros);
      throw handleErros;
    }
  }


  static async update(
    data: IDataSku,
    relation?: IDataRelation<SkuRelations>,
    config?: { params?: Record<string, string> } // Novo parâmetro opcional para query strings
  ): Promise<IDataSku> {
    try {
      const quality_status = data?.quality_status?.value || null;
      const status_umami = data?.status_umami?.value || null;
      const integration_situation = data?.integration_situation?.value || null;
      const integration_status = data?.integration_status?.value || null;

      const integrated_at_date = Date.parse(data.integrated_at + '');
      const integrated_at_date_to_date = toDate(integrated_at_date);
      const integrated_at =
        data.integrated_at && Boolean(Date.parse(data.integrated_at + ''))
          ? format(integrated_at_date_to_date, 'yyyy-MM-dd HH:mm:ss')
          : null;

      let url: string;
      if (relation) {
        url = `${Endpoints[relation.relation].main}/${relation.id}/${Endpoints.Sku.main}`;
      } else {
        url = `${Endpoints.Sku.main}/${data.id}`;
      }

      // Adicionando parâmetros de query string à URL
      if (config?.params) {
        const queryString = new URLSearchParams(config.params).toString();
        url += `?${queryString}`;
      }

      const payload = {
        ...data,
        quality_status,
        status_umami,
        integrated_at,
        integration_situation,
        integration_status,
      };

      const resp: IDataSku = (
        relation
          ? await Api.post(url, payload)
          : await Api.put(url, payload)
      ).data.sku;

      resp['integration_situation'] =
        FixedSelectOptions.Sku.integration_situation[resp.integration_situation as string];
      resp['integration_status'] =
        FixedSelectOptions.Sku.integration_status[resp.integration_status as string];
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Skus', handleErros);
      throw handleErros;
    }
  }


  static async delete(data: IDataSku, relation?: IDataRelation<SkuRelations>): Promise<IDataSku> {
    try {
      var resp: IDataSku;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/sku/${data.id}`)
        ).data.sku;
      else resp = (await Api.delete(`${Endpoints.Sku.main}/${data.id}`)).data.sku;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Skus', handleErros);
      throw handleErros;
    }
  }

  static async deleteMany(
    ids: string[] | number[],
    relation?: IDataRelation<SkuRelations>
  ): Promise<IDataSku> {
    try {
      var resp: IDataSku;

      if (relation)
        resp = (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/sku/delete-multiple`,
            { ids }
          )
        ).data.sku;
      else resp = (await Api.post(`${Endpoints.Sku.main}/delete-multiple`, { ids })).data.sku;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Skus', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    sku?: IDataSku
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          sku ? `${Endpoints.Sku.main}/${sku.id}/media` : `/media/${Endpoints.Sku.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Skus', handleErros);
      throw handleErros;
    }
  }

  static async orderAttachment(
    media: IDataAttachment,
    sku: IDataSku,
    origin: number,
    destination: number
  ): Promise<IDataSku> {
    try {
      return (
        await Api.put(`${Endpoints.Sku.main}/${sku.id}/media/${media.id}/order`, {
          origin,
          destination,
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: orderAttachment Skus', handleErros);
      throw handleErros;
    }
  }

  static async attach(sku: IDataSku, files: File[], links?: String[], as_copy?: number) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      links?.forEach((link, index) => {
        formData.append(`link[${index}]`, link + '');
      });

      return (
        await Api.post(`${Endpoints.Sku.main}/${sku.id}/attach`, formData, {
          params: { as_copy },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Skus', handleErros);
      throw handleErros;
    }
  }

  static async importSkus(file: File, projeto_id?: number | null) {
    try {
      const formData = new FormData();
      formData.append(`skus_importacao`, file);

      return (
        await Api.post(`${Endpoints.Sku.main}/importar`, formData, {
          params: { projeto_id: projeto_id || null },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import Skus', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Sku.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Skus', handleErros);
      throw handleErros;
    }
  }

  static async batch(data, params?) {
    try {
      return (await Api.put(`${Endpoints.Sku.main}/curador/batch`, data, { params: params })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      throw handleErros;
    }
  }

  static async export(params?: ISkuFilter, relation?: IDataRelation<SkuRelations>) {
    try {
      var resp;
      var nameFile = 'skus_agnostico';
      if (relation) {
        resp = await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/sku/exportar`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          responseType: 'blob',
        });
        nameFile = `skus_${relation.relation}_${relation.id}`;
      } else {
        resp = await Api.get(`${Endpoints.Sku.main}/exportar`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          responseType: 'blob',
        });
      }
      // Cria um URL temporário para o arquivo blob
      const downloadUrl = URL.createObjectURL(resp.data);

      // Cria um link e simula um clique para iniciar o download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = nameFile; // Nome do arquivo a ser baixado
      link.click();
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async exportMedia(params?: ISkuFilter, relation?: IDataRelation<SkuRelations>) {
    try {
      var resp;
      const date = new Date();
      var nameFile =
        'skus_medias_' +
        date.getDate() +
        '_' +
        date.getMonth() +
        '_' +
        date.getFullYear() +
        '_' +
        date.getHours() +
        '_' +
        date.getMinutes() +
        '_' +
        date.getSeconds();
      if (relation) {
        resp = await Api.get(
          `${Endpoints[relation.relation].main}/${relation.id}/sku/media/export`,
          {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
            responseType: 'blob',
          }
        );
        nameFile = `skus_${relation.relation}_${relation.id}`;
      } else {
        resp = await Api.get(`${Endpoints.Sku.main}/media/export`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          responseType: 'blob',
        });
      }

      const contentDisposition = resp.headers['content-disposition'];
      const match = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      const fileNameFromHeader = match && match[1] ? match[1] : null;

      // Usar o nome do arquivo do cabeçalho se estiver disponível, caso contrário, use o nome padrão
      const fileName = fileNameFromHeader || nameFile;
      console.log(resp);
      // Cria um URL temporário para o arquivo blob
      const downloadUrl = URL.createObjectURL(resp.data);

      // Cria um link e simula um clique para iniciar o download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Nome do arquivo a ser baixado
      link.click();
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async import(file: File, relation?: IDataRelation<SkuRelations>) {
    try {
      const formData = new FormData();
      formData.append(`skus_importacao`, file);


      if (relation)
        return (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/sku/importar`,
            formData
          )
        ).data;
      else {
        return (await Api.post(`${Endpoints.Sku.main}/importar`, formData)).data;
      }
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import SKUs', handleErros);
      throw handleErros;
    }
  }

  static async listWithNoAgnostic(
    params?: ISkuFilter,
    relation?: IDataRelation<SkuRelations>
  ): Promise<ISkuPagination | undefined> {
    try {
      if (!relation) return;
      const resp = (
        await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/sku/nao_base`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
      ).data;
      return resp as ISkuPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static getIds(relation: IDataRelation<any>): any {

    const id = relation.relation == 'Store' ? "loja_id"
      : relation.relation == 'Partner' ? "cliente_id"
        : relation.relation == 'Shopping' ? 'shopping_id'
          : relation.relation == 'Project' ? "projeto_id" : "uuid";

    let obj = {
      [id]: relation.id
    }
    if (relation.relationFather) {
      let relag = this.getIds(relation.relationFather);

      obj = { ...obj, ...relag }

    }

    return obj;

  }
  static async copyToBase(
    skus: number[] | string[],
    relationsToCopy: Record<string, string[]>,
    loja_id: string | number,
    relations?: IDataRelation<any>
  ) {
    try {
      if (!loja_id) return;
      let directions = relations ? this.getIds(relations) : {}

      const resp = (
        await Api.post(`loja/${loja_id}/sku/base`, {
          skus,
          relationsToClone: relationsToCopy,
          directions
        })
      ).data;
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async curate(store: IDataIDCurate): Promise<IDataIDCurate> {
    try {
      return await Api.post(`${Endpoints.Store.main}/${store.loja_id}/sku/curar`, { id: store.id });
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Store', handleErros);
      throw handleErros;
    }
  }
}
