import React from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { ISelectOption } from 'app/types/ISelectOption';

/* export interface ISelectSimple<D = any> {
 options: ISelectOption<D>[]
} */

export type ISelectSimple<D, T> = SelectProps<T> & {
  options: ISelectOption<D>[];
};

export default function SelectSimple<D, T>(props: ISelectSimple<D, T>) {
  return (
    <Select {...props}>
      {props.options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
