import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  SegmentsFields,
  IDataSegments,
  IDataSegmentsErrors,
  IHookFormSegments,
  SegmentsRelations,
  ISegmentsPagination,
} from 'app/types/data/IDataSegments';
import { IParamsQs } from 'app/types/IParams';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class Segments_Api extends Model_Api {
  static self = new Segments_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSegmentsErrors {
    const resp: IDataSegmentsErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSegments[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SegmentsFields;
      const item: IHookFormSegments = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IParamsQs,
    relation?: IDataRelation<SegmentsRelations>
  ): Promise<ISegmentsPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/segmento`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentsPagination;
      else
        return (
          await Api.get(Endpoints.Segments.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentsPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Segments', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSegments): Promise<IDataSegments> {
    try {
      return (await Api.get(`${Endpoints.Segments.main}/${data.id}`)).data as IDataSegments;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Segments', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSegments,
    relation?: IDataRelation<SegmentsRelations>
  ): Promise<IDataSegments> {
    try {
      const resp = (await Api.post(Endpoints.Segments.main, data)).data.segmento as IDataSegments;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/segmento/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segments', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataSegments,
    relation?: IDataRelation<SegmentsRelations>
  ): Promise<IDataSegments> {
    try {
      const resp = (await Api.put(`${Endpoints.Segments.main}/${data.id}`, data)).data.segmento;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/segmento/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Segments', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataSegments,
    relation?: IDataRelation<SegmentsRelations>
  ): Promise<IDataSegments> {
    try {
      var resp: IDataSegments;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/segmento/${data.id}`
          )
        ).data.segmento;
      else resp = (await Api.delete(`${Endpoints.Segments.main}/${data.id}`)).data.segmento;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Segments', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataSegments
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Segments.main}/${model.id}/attachments`
            : `/media${Endpoints.Segments.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(segmento: IDataSegments, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Segments.main}/${segmento.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segments', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Segments.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segments', handleErros);
      throw handleErros;
    }
  }
}
