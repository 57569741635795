const hiddenColumns = {
  projeto_id: false,
  parent_id: false,
  classificacao: false,
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
};

export default hiddenColumns;
