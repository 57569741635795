import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { LoadingButton } from '@mui/lab';
import { TranslateMessages } from '../@Messages';
var humanFormat = require('human-format');

const Dropzone = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
}));

const DropzoneArea = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 200,
  border: `2px dashed ${theme.palette.primary.light}`,
  borderRadius: 16,
  backgroundColor: theme.palette.grey[200],
  cursor: 'pointer',
}));

const Aside = styled('aside')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ListArea = styled('div')(({ theme }) => ({
  width: '100%',
  '& ul': {
    overflowY: 'auto',
  },
}));

const ListItemFile = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ListItemFileError = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.error.contrastText,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ImagesPreview = styled('div')(({ theme }) => ({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px',
}));

const formatTypes = {
  attachments: {
    accept: {
      'image/*': [],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/pdf': [],
      'text/html': ['.html', '.htm'],
    },
    imageInfo: 'Imagens aceitas: .png, .jpeg, .jpg, .gif, .svg.',
    docInfo: 'Documentos aceitos: Word, Excel, Power Point, PDF, HTML.',
  },
  image: {
    accept: {
      'image/*': [],
    },
    imageInfo: 'Imagens aceitas: .png, .jpeg, .jpg, .gif, .svg, .tiff',
    docInfo: undefined,
  },
  media: {
    accept: {
      'image/*': [],
      'video/mp4': ['.mp4'],
    },
    imageInfo: 'Mídias aceitas: .png, .jpeg, .jpg, .gif, .svg, .tiff, .mp4.',
    docInfo: undefined,
  },
};

declare interface IFormAttachments {
  maxSize?: number;
  loading?: boolean;
  clearFiles?: boolean;
  format?: 'attachments' | 'image' | 'media';
  onSubmit?: (files: File[], links?: string[], as_copy?: number) => void;
  onCancel?: () => void;
  errors?: object;
  multiple?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TextArea {
  textArea: string;
  links: string[];
}

const initialTextArea = {
  textArea: '',
  links: [],
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: value === 1 ? 0 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const FormAttachments = (props: IFormAttachments) => {
  const MAX_SIZE = props.maxSize || 50000000;
  const [SelectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [errorFiles, setErrorFiles] = React.useState<FileRejection[]>([]);
  const [inputLinks, setInputLinks] = React.useState<TextArea>(initialTextArea);
  const [hasInvalidLinks, setHasInvalidLinks] = React.useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [linkErrors, setLinkErrors] = React.useState<String[] | undefined>(undefined);
  const [asCopy, setAsCopy] = React.useState<boolean>(false);

  useEffect(() => {
    const err = props.errors && Object.values(props.errors).flat();
    setLinkErrors(err);
  }, [props.errors]);

  const format = props.format || 'attachments';

  React.useEffect(() => {
    if (props.clearFiles) setSelectedFiles([]);
  }, [props.clearFiles]);

  function fileValidator(file: File) {
    if (file.size > MAX_SIZE) {
      return {
        code: 'size-too-large',
        message: `Tamanho do arquivo excede o máximo de ${humanFormat(MAX_SIZE)}.`,
      };
    }

    return null;
  }

  const onDrop = React.useCallback(
    (acceptedFiles: File[], fileRejections) => {
      if (props?.multiple === false) {
        setSelectedFiles([...acceptedFiles]);
        setErrorFiles(fileRejections);
      } else {
        setSelectedFiles([...SelectedFiles, ...acceptedFiles]);
        setErrorFiles(fileRejections);
      }
    },
    [SelectedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: formatTypes[format].accept,
    maxFiles: 10,
    multiple: props?.multiple !== undefined && props?.multiple === false ? false : true,
    validator: fileValidator,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const files = SelectedFiles.map((file: File) => (
    <ListItemFile
      key={file.name}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file)}>
          <Icon>delete</Icon>
        </IconButton>
      }
    >
      <ListItemText primary={file.name} secondary={humanFormat(file.size)} />
    </ListItemFile>
  ));

  const errors = errorFiles.map((error: FileRejection) => (
    <ListItemFileError key={error.file.name}>
      <ListItemText
        primary={error.file.name}
        secondary={<ErrorMessage>{TranslateMessages(error.errors[0].code)}</ErrorMessage>}
      />
    </ListItemFileError>
  ));

  function handleDelete(file: File) {
    const newFiles = [...SelectedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setSelectedFiles(newFiles);
  }

  function handleSubmit() {
    if (props.onSubmit) {
      if (props.format === 'media' || props?.format === 'image') {
        props.onSubmit(SelectedFiles, inputLinks.links, asCopy ? 1 : 0);
      } else {
        props.onSubmit(SelectedFiles);
      }
    }
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    const links = value.replace(/\s\s+/g, '\n').trim().split('\n');
    links.forEach((item, index) => {
      if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(item)) {
        links[index] = '';
      }
    });
    if (links.includes('')) {
      setHasInvalidLinks(true);
      setLinkErrors(['Por favor, adicione apenas links válidos']);
    } else {
      setHasInvalidLinks(false);
      setLinkErrors([]);
    }
    setInputLinks({
      textArea: value,
      links: value !== '' ? links : [],
    });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Anexos" {...a11yProps(0)} />
          <Tab
            label="Links"
            {...a11yProps(1)}
            disabled={props.format !== 'media' && props.format !== 'image'}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Dropzone>
          <DropzoneArea {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Stack>
              <Typography variant="button">
                Solte seus arquivos aqui ou clique para escolher
              </Typography>
              {formatTypes[format].imageInfo && (
                <Typography variant="caption">{formatTypes[format].imageInfo} </Typography>
              )}
              {formatTypes[format].docInfo && (
                <Typography variant="caption">{formatTypes[format].docInfo} </Typography>
              )}
              <Typography variant="caption">
                Tamanho Máximo por arquivo: {humanFormat(MAX_SIZE)}.{' '}
              </Typography>
            </Stack>
          </DropzoneArea>
          {files.length > 0 && (
            <Aside>
              <Typography variant="h6" component="h4">
                Arquivos
              </Typography>
              <ListArea sx={{ '& ul': { maxHeight: errors.length ? 120 : 250 } }}>
                <List dense>{files}</List>
              </ListArea>
            </Aside>
          )}
          {errors.length > 0 && (
            <Aside>
              <Typography variant="h6" component="h4">
                Arquivos Inválidos
              </Typography>
              <ListArea sx={{ '& ul': { maxHeight: errors.length ? 120 : 250 } }}>
                <List dense>{errors}</List>
              </ListArea>
            </Aside>
          )}
        </Dropzone>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={asCopy} onChange={(event) => setAsCopy(event.target.checked)} />
            }
            label="Salvar como cópia"
          />
        </FormGroup>
        {(props.format === 'media' || props.format === 'image') && (
          <TextField
            id="form-links"
            label="Links"
            type="text"
            multiline={props?.multiple !== undefined ? props?.multiple : true}
            value={inputLinks.textArea}
            autoComplete={'off'}
            fullWidth
            variant="outlined"
            size="small"
            helperText={
              <Typography color="red" variant="caption" component="span">
                {linkErrors && linkErrors.map((item) => <div>{item}</div>)}
              </Typography>
            }
            onChange={handleInputChange}
          />
        )}
        <ImagesPreview>
          {!!inputLinks.links.length &&
            inputLinks.links.map((item) => (
              <div style={{ flexBasis: '80px' }}>
                <img
                  src={item}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/assets/images/file.png';
                    setHasInvalidLinks(true);
                  }}
                  alt=""
                  width="80px"
                  height="80px"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            ))}
        </ImagesPreview>
      </TabPanel>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Cancelar
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          disabled={
            (!Boolean(SelectedFiles.length) && !Boolean(inputLinks.links.length)) ||
            props.loading ||
            hasInvalidLinks ||
            Boolean(linkErrors?.length)
          }
          type="submit"
          color="primary"
          loading={props.loading}
          variant="contained"
        >
          Enviar
        </LoadingButton>
      </Grid>
    </>
  );
};

export default FormAttachments;
