import { useBreadcrumb } from 'app/contexts/BreadcrumbContext';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface UseTrailHistoryProps {
  selected: any;
  selectedName: string | undefined;
  displayName: string;
  toggleView: (value: boolean) => void;
}

const useTrailHistory = ({
  selected,
  selectedName,
  displayName,
  toggleView,
}: UseTrailHistoryProps) => {
  const { setBreadCrumb } = useBreadcrumb();
  const uuidRef = useRef(uuidv4());
  const uuid = uuidRef.current;

  useEffect(() => {
    if (!uuidRef.current) {
      uuidRef.current = uuidv4();
    }

    setBreadCrumb((prev) => {
      const index = prev.findIndex((item) => item.uuid === uuid);

      if (index !== -1) {
        prev[index].selected_name = selectedName;
        prev[index].selected = selected;
        return prev;
      } else {
        return [
          ...prev,
          {
            name: displayName,
            selected_name: selectedName,
            selected,
            uuid,
            toggleView: () => toggleView(true),
          },
        ];
      }
    });

    return () => {
      setBreadCrumb((prev) => prev.filter((item) => item.uuid !== uuid));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreadCrumb, selected, uuid, displayName]);

  return { uuid };
};

export default useTrailHistory;
