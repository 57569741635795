import FormContainer from 'app/components/Containers/FormContainer';
import FormReasons from 'app/components/Forms/Reasons';
import { IDataRelation } from 'app/types/data/IData';
import { IDataReasons, ReasonsRelations } from 'app/types/data/IDataReasons';
import React from 'react';

declare interface IReasonsFormContainer {
  data?: IDataReasons;
  relation?: IDataRelation<ReasonsRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataReasons) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  typeForm?: string | undefined
}

const ReasonsFormContainer = (props: IReasonsFormContainer) => {
  const [data, setData] = React.useState<IDataReasons | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormReasons
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            typeForm={props.typeForm}
          />
        }
      /* tabs={
    data && [
      {
        title: 'Anexos',
        component: (
          <GridAttachments
            relationData={data}
            apiModel={Reasons_Api}
            onClickAttach={props.onClickAttach}
          />
        ),
      },
    ]
  } */
      />
    </>
  );
};

export default ReasonsFormContainer;
