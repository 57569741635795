import Sku_Api from 'app/api/Sku_Api';
import AttachmentModal, { IAttachmentModal } from 'app/components/Modal/AttachmentModal';
import Toast from 'app/components/Toast';
import { IDataErrors } from 'app/types/data/IData';
import { IDataSku, IDataSkuErrors } from 'app/types/data/IDataSku';
import { getMessage } from 'app/utils/messages';
import React from 'react';

declare interface ISkuCreateAttachment extends IAttachmentModal {
  data: IDataSku;
  maxSize?: number;
  format?: 'attachments' | 'image' | 'media';
  onUpload?: (orientation: IDataSku) => void;
}

const CREATE_MSG = getMessage('Anexo', 'create');

const SkuCreateAttachment = (props: ISkuCreateAttachment) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clean, setClean] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IDataErrors | undefined>(undefined);

  React.useEffect(() => setOpen(props.open), [props.open]);

  function handleClose() {
    setOpenToast(false);
  }

  async function sendAttachments(files: File[], links?: String[], as_copy?: number) {
    setErrors(undefined);
    setLoading(true);
    try {
      const newSku = await Sku_Api.attach(props.data, files, links, as_copy);
      setClean(true);
      setOpenToast(true);
      handleCancel();
      if (props.onUpload) props.onUpload(newSku);
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        if ((err?.errors?.status && err?.errors?.message) || err.errors) setErrors(err.errors);
      }
    } finally {
      setLoading(false);
      setClean(false);
    }
  }
  function handleCancel() {
    setErrors(undefined);
    setOpen(false);
    if (props.onCancel) props.onCancel();
    if (props.onClose) props.onClose();
  }

  return (
    <>
      <AttachmentModal
        errors={errors}
        loading={loading}
        clearFiles={clean}
        open={open}
        format={props.format}
        maxSize={props.maxSize}
        onClose={() => {
          setErrors(undefined);
          props.onClose && props.onClose();
        }}
        onCancel={handleCancel}
        onSubmit={sendAttachments}
      />
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default SkuCreateAttachment;
