import Endpoints from 'app/config/Endpoints';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import { IDataParametro, IDataParametroErrors, IHookFormParametro, IParametroFilter, IParametroPagination, ParametroFields } from 'app/types/data/IDataParametro';

export default class Parametro_Api extends Model_Api {
    static self = new Parametro_Api();

    protected handleErrors(error: AxiosError | Error | string | any): IDataParametroErrors {
        const resp: IDataParametroErrors = this.formatErrors(error);
        const { errors } = resp;
        const hookForm: IHookFormParametro[] = [];

        Object.keys(errors).forEach((key) => {
            const name = key as ParametroFields;
            const item: IHookFormParametro = {
                type: 'manual',
                name,
                message: Array.isArray(errors) ? errors[key][0] : errors[key],
            };
            hookForm.push(item);
        });

        return { errors, hookForm };
    }

    static async list(
        params?: IParametroFilter
    ): Promise<IParametroPagination> {
        try {

            return (await Api.get(Endpoints.Parametro.main, { params })).data as IParametroPagination;
        } catch (error) {
            const handleErros = this.self.handleErrors(error);
            console.log('error: List Parametros', handleErros);
            throw handleErros;
        }
    }

    static async show(id: number) {
        try {
            return (await Api.get(`${Endpoints.Parametro.main}/get/${id}`)).data;
        } catch (error) {
            const handleErros = this.self.handleErrors(error);
            console.log('error: Show Parametros', handleErros);
            throw handleErros;
        }
    }
    static async showByName(name: string) {
        try {
            return (await Api.get(`${Endpoints.Parametro.main}/name?name=${name}`)).data;
        } catch (error) {
            const handleErros = this.self.handleErrors(error);
            console.log('error: Show Parametros', handleErros);
            throw handleErros;
        }
    }


    static async update(
        data: IDataParametro
    ): Promise<IDataParametro> {
        try {
            const resp = (await Api.put(`${Endpoints.Parametro.main}/${data.id}`, data)).data;

            return resp;
        } catch (error) {
            const handleErros = this.self.handleErrors(error);
            console.log('error: Update Parametros', handleErros);
            throw handleErros;
        }
    }

    static async getById(
        data: IDataParametro
    ): Promise<IDataParametro> {
        try {
            const resp = (await Api.get(`${Endpoints.Parametro.main}/${data.id}`)).data;

            return resp;
        } catch (error) {
            const handleErros = this.self.handleErrors(error);
            console.log('error: Update Parametros', handleErros);
            throw handleErros;
        }
    }
}
