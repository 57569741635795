import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Typography } from '@mui/material';
import { IDataRelation } from 'app/types/data/IData';
import { CommentRelations } from 'app/types/data/IDataComment';
import ActivityFormContainer from 'app/views/partners/activity/components/ActivityFormContainer';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';

interface SidebarFormProps {
  selected?: IDataActivity | null;
  open: boolean;
  onClose: () => void;
  relation?: IDataRelation<ActivityRelations>;
  commentsRelation: IDataRelation<CommentRelations>;
  onSave: (filters?) => void;
  key?: React.Key;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

export default function SidebarForm(props, {
  selected,
  open,
  onClose,
  relation,
  onSave,
  campos,
  sequency
}: SidebarFormProps) {
  const [drawerState, setDrawerState] = React.useState(open);

  React.useEffect(() => {
    setDrawerState(open);
  }, [open]);

  const handleClose = () => {
    setDrawerState(false);
    onClose();
  };

  const handleOnSave = () => {
    onSave();
    handleClose();
  };

  return (
    <React.Fragment key={'right'}>
      <Drawer anchor={'right'} open={drawerState} onClose={() => handleClose()}>
        <Box p="16px 3rem">
          <Typography variant="h6" color="GrayText" mb={2}>
            {selected?.id ? 'Atualizar atividade' : 'Criar Atividade'}
          </Typography>
          <ActivityFormContainer
            data={selected || undefined}
            relation={relation}
            onFormSave={handleOnSave}
            cancel={handleClose}
            campos={campos}
            sequency={sequency}
            typeForm={props.typeForm}
          />
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
