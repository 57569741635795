import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  PartnerAttributeFields,
  PartnerAttributeRelations,
  IPartnerAttributePagination,
  IDataPartnerAttribute,
  IDataPartnerAttributeErrors,
  IHookFormPartnerAttribute,
  IDataFilterPartnerAttribute,
} from 'app/types/data/IDataPartnerAttribute';
import IParams from 'app/types/IParams';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';

export default class PartnerCategories_Api extends Model_Api {
  static self = new PartnerCategories_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataPartnerAttributeErrors {
    const resp: IDataPartnerAttributeErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormPartnerAttribute[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as PartnerAttributeFields;
      const item: IHookFormPartnerAttribute = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    parameters?: IParams<PartnerAttributeFields, IDataFilterPartnerAttribute>,
    relation?: IDataRelation<PartnerAttributeRelations>
  ): Promise<IPartnerAttributePagination> {
    try {
      const extra = parameters?.filter;
      var params = parameters;
      delete parameters?.filter;
      params = { filter: extra, ...parameters };

      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/atributo`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as IPartnerAttributePagination;
      else
        return (
          await Api.get(Endpoints.PartnerAttribute.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as IPartnerAttributePagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Categories', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataPartnerAttribute,
    relation?: IDataRelation<PartnerAttributeRelations>
  ): Promise<IDataPartnerAttribute> {
    //const atributoId = data?.atributos_id?.value;
    try {
      const resp = (await Api.post(Endpoints.PartnerAttribute.main, data)).data
        .atributo as IDataPartnerAttribute;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atributo/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Categories', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataPartnerAttribute,
    relation?: IDataRelation<PartnerAttributeRelations>
  ): Promise<IDataPartnerAttribute> {
    try {
      const resp = (await Api.put(`${Endpoints.PartnerAttribute.main}/${data.id}`, data)).data
        .atributo;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atributo/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataPartnerAttribute,
    relation?: IDataRelation<PartnerAttributeRelations>
  ): Promise<IDataPartnerAttribute> {
    try {
      var resp: IDataPartnerAttribute;
      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/atributo/${data.id}`
          )
        ).data.atributo;
      else resp = (await Api.delete(`${Endpoints.PartnerAttribute.main}/${data.id}`)).data.atributo;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Categories', handleErros);
      throw handleErros;
    }
  }

  static async combo(search: string = '') {
    try {
      return (await Api.get(Endpoints.PartnerAttribute.combo, { params: { q: search } })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Combo Categories', handleErros);
      throw handleErros;
    }
  }
}
