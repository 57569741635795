// Lista de relations (checkboxes) disponíveis para seleçao
export const availableRelations: Record<string, string[]> = {
  skus: ['atributos', 'unidades', 'midia', 'orientacoes'],
  produtos: ['sku', 'atributos', 'categorias', 'orientacoes'],
};

// Traduzindo as relations para um nome amigável
export const translatedKeys = {
  skus: 'SKUs',
  sku: 'Sku',
  produtos: 'Produtos',
  unidades: 'Unidades',
  midia: 'Mídia',
  orientacoes: 'Orientações',
  atributos: 'Atributos',
  categorias: 'Categorias',
};
