import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  UnitFields,
  IDataUnit,
  IDataUnitErrors,
  IHookFormUnit,
  UnitRelations,
  IUnitPagination,
  IUnitFilter,
} from 'app/types/data/IDataUnit';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class Units_Api extends Model_Api {
  static self = new Units_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataUnitErrors {
    const resp: IDataUnitErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormUnit[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as UnitFields;
      const item: IHookFormUnit = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IUnitFilter,
    relation?: IDataRelation<UnitRelations>
  ): Promise<IUnitPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/unidade`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IUnitPagination;
      else
        return (
          await Api.get(Endpoints.Unit.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IUnitPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Units', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataUnit,
    relation?: IDataRelation<UnitRelations>
  ): Promise<IDataUnit> {
    console.log(relation);
    try {
      var resp;
      if (relation) {
        resp = await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/unidade`, data);
      } else {
        resp = await Api.post(Endpoints.Unit.main, data);
      }
      return resp.data.unidade as IDataUnit;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Units', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataUnit,
    relation?: IDataRelation<UnitRelations>
  ): Promise<IDataUnit> {
    try {
      var resp;
      if (relation) {
        resp = await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/unidade`, data);
      } else {
        resp = await Api.put(`${Endpoints.Unit.main}/${data.id}`, data);
      }
      return resp?.data?.unidade as IDataUnit;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Units', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataUnit,
    relation?: IDataRelation<UnitRelations>
  ): Promise<IDataUnit> {
    try {
      var resp: IDataUnit;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/unidade/${data.id}`)
        ).data.unidade;
      else resp = (await Api.delete(`${Endpoints.Unit.main}/${data.id}`)).data.unidade;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Units', handleErros);
      throw handleErros;
    }
  }
}
