import React from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import ReasonType_Api from 'app/api/ReasonType_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataReasonType, IDataReasonTypeErrors } from 'app/types/data/IDataReasonType';
import Option from '../Option/Option';
import TimedTextField from '../../TimedTextField/TimedTextField';

export interface ISelectReasonType {
  id?: string;
  value?: IDataReasonType | null;
  disabled?: boolean;
  error?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: (ReasonType?: IDataReasonType) => void;
}

const SelectReasonType = (props: ISelectReasonType) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataReasonType>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataReasonType> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataReasonType> | undefined = props.value
      ? {
          label: props.value?.name,
          value: props.value?.id as number,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await ReasonType_Api.list({ q: search });

      const combo: ISelectOption<IDataReasonType>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataReasonTypeErrors | unknown) {
      if (error) {
        const err = error as IDataReasonTypeErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Autocomplete
      id={props.id}
      noOptionsText="Nenhum tipo de motivo encontrado"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption || null}
      loading={loading}
      onClose={() => setSearch('')}
      disabled={props.disabled}
      //isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      renderOption={(props, option) => <Option key={option.value} props={props} option={option} />}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label="Tipos de motivo"
          placeholder="Pesquisar um tipo de motivo"
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          variant={props.variant || 'outlined'}
          helperText={
            <Typography color="red" variant="caption" component="span">
              {props.error || errors}
            </Typography>
          }
        />
      )}
    />
  );
};

export default SelectReasonType;
