import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Activity_Api from 'app/api/Activity_Api';
import AlertMessage from 'app/components/Alert';
import SelectWorkflowPhases from 'app/components/Inputs/Selects/SelectPhase/SelectPhase';
import SelectUser from 'app/components/Inputs/Selects/SelectUser/SelectUsers';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import WorkflowActivityContext from 'app/contexts/WorkflowActivityContext';
import { useToast } from 'app/hooks/useToast';
import { IDataActivity, IDataActivityErrors } from 'app/types/data/IDataActivity';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { formatDbDate, removeExtraSpaces } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActivitySchema } from '../@Schemas/ActivitySchema';
import { IFormActivity } from '../@Types/IFormActivity';
import {
  activityPriorityStatusOptions,
  activityQualityStatusOptions,
  activityStatusOptions,
} from './InputsOptions';
import DurationInput from 'app/components/Inputs/DurationInput/DurationInput';
import SelectPredecessorActivity from 'app/components/Inputs/Selects/SelectPredecessorActivity/SelectPredecessorActivity';
import { calcularDiferencaEmSegundos } from 'app/utils/dateOperation';
import { WorkflowPhasesRelations } from 'app/types/data/IDataWorkflowPhases';
import { IDataRelation } from 'app/types/data/IData';
import WorkflowContext from 'app/contexts/WorkflowContext';
import WorkflowPhasesContext from 'app/contexts/WorkflowPhasesContext';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataActivity = {
  id: '',
  summary: '',
  description: '',
  nivel: '',
  stage_id: null,
  start_date: null,
  pr_activity_id: null,
  end_date: null,
  duration: 0,
  delivery_date: null,
  assign_id: null,
  performer_id: null,
  status: null,
  quality_status: null,
  priority: null,
  value: 0,
  progress: 0,
  position: null,
  tags: [],
};

const UPDATE_MSG = getMessage('Atividade', 'update', 'a');
const CREATE_MSG = getMessage('Atividade', 'create', 'a');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const ActivityForm = (props: IFormActivity) => {
  const { resetFormList } = useUtilsContext();
  const workflowCtx = React.useContext(WorkflowContext);
  const workflowPhaseCtx = React.useContext(WorkflowPhasesContext);
  const activityCtx = React.useContext(WorkflowActivityContext);
  const activityParent = props.isSub ? activityCtx?.data : undefined;
  const { showToast } = useToast();
  const { getConfigCampo } = useAuth();


  const newInitial = {
    ...initialValues,
    pr_activity_id: props.defaultPredecessora?.id,
    predecessor: props.defaultPredecessora,
  };

  const [data, setData] = React.useState<IDataActivity>(props.data || newInitial);
  const [, /* comboData */ setComboData] = React.useState<ISelectOption<IDataActivity>[]>([]);

  const [, /* comboLoading */ setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search /* setSearch */] = React.useState<string>('');

  const autoUpdateDates = useRef<boolean>(true);

  const updateEndDate = useCallback(() => {
    if (data.start_date && autoUpdateDates.current) {
      const novaDataFim = new Date(data.start_date);
      novaDataFim.setUTCSeconds((data.duration as number) || 0);
      setData((prevData) => ({ ...prevData, end_date: novaDataFim }));
      setValue('end_date', novaDataFim);
    }
    autoUpdateDates.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.start_date, data.duration]);

  React.useEffect(() => {
    setData((props.data as IDataActivity) || newInitial);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.ActivityForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  React.useEffect(() => {
    if (activityParent && activityParent.stage) {
      setValue('stage_id', activityParent.stage.id as number);
      setValue('stage', activityParent.stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityParent]);

  // Atualiza startDate quando o predecessor muda
  React.useEffect(() => {
    var dataFim = formatDbDate(new Date(), true);
    if (data.predecessor?.end_date) {
      const dataFimPredecessor = new Date(data.predecessor?.end_date);
      dataFimPredecessor.setUTCMinutes(dataFimPredecessor.getUTCMinutes() + 1);

      dataFim = formatDbDate(dataFimPredecessor, true);
      setData((prevData) => ({ ...prevData, start_date: dataFim }));
      setValue('start_date', dataFim);
      //setData({ ...data, start_date: dataFim });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.predecessor]);

  // Atualiza endDate quando startDate mudar
  React.useEffect(() => {
    autoUpdateDates.current = true;
    updateEndDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.start_date]);

  // Atualiza endDate quando duration mudar
  React.useEffect(() => {
    updateEndDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.duration]);

  // Atualiza duration quando startDate ou endDate mudar
  React.useEffect(() => {
    if (data.start_date && data.end_date) {
      const newDuration = calcularDiferencaEmSegundos(data.end_date, data.start_date);
      autoUpdateDates.current = false;
      setData((prevData) => ({ ...prevData, duration: newDuration }));
      setValue('duration', newDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.end_date]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataActivity>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(ActivitySchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function load() {
    setComboLoading(true);
    try {
      const items = await Activity_Api.list({ q: search });

      const combo: ISelectOption<IDataActivity>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.summary,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        const err = error as IDataActivityErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  async function onSubmit(dataForm: IDataActivity) {
    const campos = [{
      nome: 'Nome',
      campo: 'summary'
    },
    {
      nome: 'Descrição',
      campo: 'description'
    },
    {
      nome: 'Predecessor',
      campo: 'pr_activity_id'
    },
    {
      nome: 'Etapa',
      campo: 'stage'
    },
    {
      nome: 'Responsável',
      campo: 'assign_id'
    },
    {
      nome: 'Executante',
      campo: 'performer_id'
    },
    {
      nome: 'Status',
      campo: 'status'
    },
    {
      nome: 'Prioridade',
      campo: 'priority'
    },
    {
      nome: 'Valor',
      campo: 'value'
    },
    {
      nome: 'Qualidade status',
      campo: 'quality_status'
    },
    {
      nome: 'Progresso',
      campo: 'progress'
    },
    {
      nome: 'Marcadores',
      campo: 'tags'
    },
    {
      nome: 'Início',
      campo: 'start_date'
    },
    {
      nome: 'Duração',
      campo: 'duration'
    },
    {
      nome: 'Fim',
      campo: 'end_date'
    },
    {
      nome: 'Data de entrega',
      campo: 'delivery_date'
    },
    ]

    campos.forEach(x => {
      if (!dataForm[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required)
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    const shallowCopy = { ...dataForm };
    setLoading(true);
    try {
      var newData: IDataActivity;
      if (dataForm.id) {
        newData = await Activity_Api.update(
          { ...dataForm, stage_id: activityParent ? activityParent.stage_id : dataForm.stage_id },
          props.relation
        );
      } else {
        newData = await Activity_Api.create(
          { ...dataForm, stage_id: activityParent ? activityParent.stage_id : dataForm.stage_id },
          props.relation
        );
      }

      setData(newData);
      setValue('id', newData.id);
      showToast({
        open: true,
        message: props.type === 'update' && shallowCopy?.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
      if (activityCtx?.onFormSave) activityCtx?.onFormSave();
      if (props.onSubmit) props.onSubmit(newData);
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        //if (activityCtx?.onFormSave) activityCtx?.onFormSave();
        const err = error as IDataActivityErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  const relationToPhases: IDataRelation<WorkflowPhasesRelations> | undefined =
    props.defaultPredecessora
      ? {
        id: props.defaultPredecessora.workflow_id as number,
        relation: 'Workflow',
      }
      : {
        id: workflowCtx?.id as number,
        relation: 'Workflow',
      };

  const defaultPhase: number | string | null = workflowPhaseCtx
    ? (workflowPhaseCtx.id as number)
    : activityParent
      ? activityParent.stage_id
      : data.stage_id;

  const stageValue =
    data.stage ||
    workflowPhaseCtx ||
    (activityParent && !props.defaultPredecessora ? activityParent.stage : null);

  return (
    <>
      <FormBox id="formik-Activity" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {/* ID */}
          {props.type === 'update' && (
            <>
              <Grid item xs={12} md={2}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={data.id}
                  render={({ field: { onChange } }) => (
                    <TextField
                      id="id"
                      label="ID"
                      type="text"
                      disabled={true}
                      value={data.id}
                      autoComplete={'off'}
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: Boolean(data.id) }}
                      onChange={(e) => {
                        setData({ ...data, id: parseInt(e.target.value) });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          {/* SUMMARY */}
          {getConfigCampo && !getConfigCampo('Nome', props.campos ?? []).hidden &&
            <Grid item xs={12} md={10}>
              <Controller
                name="summary"
                control={control}
                defaultValue={data.summary}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-summary"
                    label="Nome*"
                    type="text"
                    value={data.summary || ''}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.summary?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      const newData = { ...data, summary: e.target.value };
                      setData(newData);
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* DESCRIPTION */}
          {getConfigCampo && !getConfigCampo('Descrição', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={data.description}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-description"
                    label="Descrição"
                    type="text"
                    multiline
                    rows={3}
                    value={data.description}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.description?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* PREDECESSOR ID */}
          {getConfigCampo && !getConfigCampo('Predecessor', props.campos ?? []).hidden &&
            <Grid item xs={12} md={12}>
              <Controller
                name="pr_activity_id"
                control={control}
                defaultValue={data.pr_activity_id}
                render={({ field: { onChange } }) => (
                  <SelectPredecessorActivity
                    error={errors?.pr_activity_id?.message}
                    id="pr_activity_id"
                    value={data.predecessor}
                    relation={props.relation}
                    label="Predecessor"
                    activityActual={data}
                    onChange={(predecessor) => {
                      setValue('pr_activity_id', predecessor ? (predecessor.id as number) : null);
                      setValue('predecessor', predecessor || null);
                      setData({
                        ...data,
                        pr_activity_id: predecessor ? (predecessor.id as number) : null,
                        predecessor: predecessor || null,
                      });
                      onChange(predecessor ? (predecessor.id as number) : null);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* STAGE_ID */}
          {getConfigCampo && !getConfigCampo('Etapa', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="stage_id"
                control={control}
                defaultValue={defaultPhase}
                render={({ field: { onChange } }) => (
                  <SelectWorkflowPhases
                    error={errors?.stage_id?.message}
                    id="stage_id"
                    relation={relationToPhases}
                    value={stageValue}
                    disabled={Boolean(activityParent || workflowPhaseCtx)}
                    getCurrentSelected={(comboData) => {
                      if (comboData) {
                        setValue('stage_id', comboData?.id as number | null);
                        setValue('stage', comboData);
                        setData({
                          ...data,
                          stage_id: (comboData?.id as number) || null,
                          stage: comboData,
                        });
                      }
                    }}
                    onChange={(stage) => {
                      setValue('stage_id', stage ? (stage.id as number) : null);
                      setValue('stage', stage);
                      setData({
                        ...data,
                        stage_id: stage ? (stage.id as number) : null,
                        stage: stage,
                      });
                      onChange(stage?.id as string);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* ASSIGN_ID */}
          {getConfigCampo && !getConfigCampo('Responsável', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="assign_id"
                control={control}
                defaultValue={data.assign_id}
                render={({ field: { onChange } }) => (
                  <SelectUser
                    error={errors?.assign_id?.message}
                    id="assign_id"
                    value={data.assign}
                    label="Responsável"
                    onChange={(assign) => {
                      setValue('assign_id', assign ? (assign.id as number) : null);
                      setValue('assign', assign);
                      setData({
                        ...data,
                        assign_id: assign ? (assign.id as number) : null,
                        assign: assign,
                      });
                      onChange(assign?.id as number);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* PERFORMOER_ID */}
          {getConfigCampo && !getConfigCampo('Executante', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="performer_id"
                control={control}
                defaultValue={data.performer_id}
                render={({ field: { onChange } }) => (
                  <SelectUser
                    error={errors?.performer_id?.message}
                    id="performer_id"
                    value={data.performer}
                    label="Executante"
                    onChange={(performer) => {
                      setValue('performer_id', performer ? (performer.id as number) : null);
                      setValue('performer', performer);
                      setData({
                        ...data,
                        performer_id: performer ? (performer.id as number) : null,
                        performer: performer,
                      });
                      onChange(performer?.id as number);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* STATUS */}
          {getConfigCampo && !getConfigCampo('Status', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="status"
                control={control}
                defaultValue={data.status || activityStatusOptions[0]}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={activityStatusOptions}
                    //getOptionLabel={(option: string) => option ?? ''}
                    //isOptionEqualToValue={(option, value) => option === value}
                    value={(data?.status as string) || activityStatusOptions[0]}
                    onChange={(event, option: any, reason) => {
                      setData({ ...data, status: option ? option : null });
                      onChange(option);
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="status"
                        label="Status"
                        type="text"
                        size="small"
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {!errors.internal_message && errors.status?.message}{' '}
                            {/* CÓDIGOS GERAIS DE ERRO TAMBÉM RETORNAM UM CAMPO STATUS, EXIBINDO O CÓDIGO DO ERRO AQUI E NÃO A MENSAGEM CORRETA */}
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          }

          {/* PRIORITY */}
          {getConfigCampo && !getConfigCampo('Prioridade', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="priority"
                control={control}
                defaultValue={data.priority || activityPriorityStatusOptions[2]}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={activityPriorityStatusOptions}
                    getOptionLabel={(option: ISelectOption) => option.label ?? ''}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    value={data?.priority || activityPriorityStatusOptions[2]}
                    onChange={(event, option: any, reason) => {
                      setData({ ...data, priority: option ? option : null });
                      onChange(option);
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="priority"
                        label="Prioridade"
                        type="text"
                        size="small"
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.priority?.message}
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          }

          {/* VALUE */}
          {getConfigCampo && !getConfigCampo('Valor', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="value"
                control={control}
                defaultValue={data.value}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-value"
                    label="Valor*"
                    type="number"
                    value={data.value}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                      max: 9999,
                    }}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.value?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      const val = typeof e.target.value === 'number' ? e.target.value : null;
                      setData({
                        ...data,
                        value: val,
                      });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* QUALITY STATUS */}
          {getConfigCampo && !getConfigCampo('Qualidade status', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="quality_status"
                control={control}
                defaultValue={data.quality_status}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    options={activityQualityStatusOptions}
                    //getOptionLabel={(option: ISelectOption) => option ?? ''}
                    //isOptionEqualToValue={(option, value) => option.value === value}
                    value={data?.quality_status || undefined}
                    onChange={(event, option: any, reason) => {
                      setData({ ...data, quality_status: option ? option : null });
                      onChange(option);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="quality_status"
                        label="Qualidade status"
                        type="text"
                        size="small"
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.quality_status?.message}
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          }

          {/* PROGRESS */}
          {getConfigCampo && !getConfigCampo('Progresso', props.campos ?? []).hidden &&
            <Grid item xs={12} md={6}>
              <Controller
                name="progress"
                control={control}
                defaultValue={data.progress}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-progress"
                    label="Progresso*"
                    type="number"
                    value={data.progress}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.progress?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        progress: typeof e.target.value === 'number' ? e.target.value : null,
                      });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* TAGS */}
          {getConfigCampo && !getConfigCampo('Marcadores', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="tags"
                control={control}
                defaultValue={data.tags}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    size="small"
                    multiple
                    id="tags-filled"
                    options={[]}
                    value={data.tags || []}
                    freeSolo
                    renderTags={(value, getTagProps) => {
                      const filteredValue = removeExtraSpaces(value);
                      return filteredValue.map((option, index) => (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Marcadores"
                        fullWidth
                        size="small"
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.tags?.message}
                          </Typography>
                        }
                      />
                    )}
                    onChange={(e, newValue: string[] | unknown) => {
                      if (newValue) {
                        const filteredValues = removeExtraSpaces(newValue as string[]);
                        setData({
                          ...data,
                          tags: filteredValues,
                        });
                        onChange(filteredValues);
                      }
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* START DATE */}
          {getConfigCampo && !getConfigCampo('Início', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="start_date"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      label="Início"
                      value={data.start_date}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, start_date: newDate });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Início"
                          id="mui-pickers-date"
                          fullWidth
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.start_date?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }

          {/* DURATION */}
          {getConfigCampo && !getConfigCampo('Duração', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="duration"
                control={control}
                defaultValue={data.duration}
                render={({ field: { onChange } }) => (
                  <DurationInput
                    id="form-summary"
                    label="Duração*"
                    type="text"
                    value={data.duration || ''}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    //InputLabelProps={{ shrink: Boolean(data.id) }}
                    onChange={(newValue: number) => {
                      autoUpdateDates.current = true;
                      setData({ ...data, duration: newValue });
                      onChange(newValue);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {/* END DATE */}
          {getConfigCampo && !getConfigCampo('Fim', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="end_date"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      label="Fim"
                      value={data.end_date}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, end_date: newDate });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Fim"
                          id="mui-pickers-date"
                          size="small"
                          fullWidth
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.end_date?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }

          {/* DELIVERY DATE */}
          {getConfigCampo && !getConfigCampo('Data de entrega', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="delivery_date"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Data de entrega"
                      value={data.delivery_date}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, delivery_date: newDate });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Data de entrega"
                          id="mui-pickers-date"
                          size="small"
                          fullWidth
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.delivery_date?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
            {props.typeForm && props.typeForm === 'create' && props.sequency ? (<>Salvar e continuar</>) : <>Salvar</>}
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default ActivityForm;
