import React from "react";
import { styled, Typography } from "@mui/material";
import { ISelectOption } from "app/types/ISelectOption";
import { IData } from "app/types/data/IData";

/* const ListItemID = styled('div')(({ theme }) => ({
 textAlign: 'right',
 paddingRight: theme.spacing(1),
 minWidth: '2rem'
})); */

const ListItem = styled('li')(({ theme }) => ({
 borderBottom: '1px solid ' + theme.palette.grey[200],
}));

const Secondary = styled('span')(({ theme }) => ({
 color: theme.palette.text.secondary,
 marginLeft: theme.spacing(1)
}));

const Image = styled('img')(({ theme }) => ({
 width: 50,
 height: 50,
 borderRadius: 4,
 objectFit: 'cover',
 marginRight: 8,
}));

export interface IOption<T> {
 props: React.HTMLAttributes<HTMLLIElement>
 option: ISelectOption<T>
 secondary?: string
 image?: string
}

export default function OptionAttributes<T extends IData>(properties: IOption<T>) {
 const { option, image, secondary, props } = properties;
 const ident = (option.nivel?.toString().match(/\./g) || []).length;
 return (
  <ListItem {...props} >
   <Typography paddingLeft={4 * ident} noWrap variant={ident > 0 ? "subtitle2" : "button"} display="flex" alignItems='center'>
    {image && <Image src={image} alt="Imagem Option" />}
    {option.label} {secondary ? <Secondary>({secondary})</Secondary> : ''}
   </Typography>
  </ListItem>
 )
}