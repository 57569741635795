import React from 'react';
import { Grid, Button } from '@mui/material';
import SelectCurator from 'app/components/Inputs/Selects/SelectCurator/SelectCurator';

interface IMassCurator {
  closePopover: () => {};
  saveMassCurator: (curatorId) => {};
  isMassCuratorLoading: boolean;
}

const MassCurator = ({ saveMassCurator, closePopover }: IMassCurator) => {
  const [curatorId, setCuratorId] = React.useState<number | null>(null);
  return (
    <div style={{ minWidth: '220px', padding: '8px' }}>
      <SelectCurator onChange={(data) => setCuratorId((data?.id as number) || null)} />
      <Grid container xs={12} justifyContent="flex-end">
        <Grid item>
          <Button onClick={() => closePopover()}>Cancelar</Button>
        </Grid>
        <Grid item>
          <Button onClick={() => saveMassCurator(curatorId)} disabled={!curatorId}>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MassCurator;
