import React from "react";
import styles from "./task-list-header.module.css";

const TaskListHeader: React.FC<{
 headerHeight: number;
 rowWidth: string;
 fontFamily: string;
 fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
 return (
  <div
   className={styles.ganttTable}
   style={{
    fontFamily: fontFamily,
    fontSize: fontSize,
   }}
  >
   <div
    className={styles.ganttTable_Header}
    style={{
     height: headerHeight - 2,
    }}
   >
    <div
     className={styles.ganttTable_HeaderItem}
     style={{
      minWidth: rowWidth,
     }}
    >
     &nbsp;Etapa/Atividade
    </div>
   </div>
  </div>
 );
};

export default TaskListHeader;