import { Box, styled, Typography } from '@mui/material';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 600,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
  width: '100%',
  marginBottom: '32px',
  maxWidth: 300,
}));

const FeedbackPagesContainer = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 64px) !important',
}));

export declare interface IFeedbackPages {
  code: number | string;
  message: string;
}

const FeedbackPages = ({ code, message }: IFeedbackPages) => {
  return (
    <FeedbackPagesContainer>
      <JustifyBox>
        <IMG src="/assets/images/access-denied.png" alt="Acesso Negado" />
        <Typography variant="h4" component="h4" textAlign="center">
          {`${message} (cód. ${code})`}
        </Typography>
      </JustifyBox>
    </FeedbackPagesContainer>
  );
};

export default FeedbackPages;
