import { format } from 'date-fns';

export function formatDateBR(date: string | Date, displayTime: boolean = true) {
  const _date = new Date(date);
  return format(_date, displayTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy');
}

export function formatDbDate(date: string | Date, displayTime: boolean = true) {
  const _date = typeof date === 'string' ? new Date(date) : date;
  return format(_date, displayTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd');
}

export function removeExtraSpaces(arr: string[]) {
  const mappedValues = arr.map((item) => {
    if (typeof item === 'string') {
      return item.replace(/\s+/g, ' ').trim();
    }
    return '';
  });
  const filteredValues = mappedValues.filter((str) => str.replace(/\s+/g, ' ').trim() !== '');
  return filteredValues;
}

export function moneyBR(value: number) {
  // Converte o valor para uma string com duas casas decimais
  var valorString = Number(value).toFixed(2).toString();

  // Substitui o ponto decimal pela vírgula
  valorString = valorString.replace('.', ',');

  // Adiciona o separador de milhares
  var partes = valorString.split(',');
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Retorna a string formatada
  return partes.join(',');
}
