import { createContext, useContext, useMemo, useState } from 'react';

export type BreadCrumb = {
  name: string;
  selected_name: string | null;
  selected: { id: string | null } | null;
  uuid: string;
  toggleView: () => void;
};

interface IBreadcrumbContext {
  breadCrumb: BreadCrumb[];
  setBreadCrumb: (value) => void;
}

const initialValues = { breadCrumb: [], setBreadCrumb: () => {} };

const BreadcrumbContext = createContext<IBreadcrumbContext>(initialValues);

export const BreadcrumbProvider = ({ children }) => {
  const [breadCrumb, setBreadCrumb] = useState<BreadCrumb[]>([]);

  const contextValue = useMemo(() => ({ breadCrumb, setBreadCrumb }), [breadCrumb, setBreadCrumb]);

  return <BreadcrumbContext.Provider value={contextValue}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export default BreadcrumbContext;
