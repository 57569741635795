import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { Button, ButtonGroup, styled } from "@mui/material";

const ViewContainer = styled('div')(({ theme }) => ({
 display: 'flex',
 justifyContent: 'flex-end',
 marginBottom: theme.spacing(1),
}));

type ViewSwitcherProps = {
 activeView?: ViewMode,
 onViewModeChange: (viewMode: ViewMode) => void;
};

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
 onViewModeChange,
 activeView
}) => {
 return (
  <ViewContainer>
   <ButtonGroup size="small" variant="outlined" aria-label="outlined primary button group">
    <Button variant={(activeView === ViewMode.Day || activeView === undefined) ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Day)}>Dias</Button>
    <Button variant={activeView === ViewMode.Week ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Week)}>Semanas</Button>
    <Button variant={activeView === ViewMode.Month ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Month)}>Meses</Button>
    <Button variant={activeView === ViewMode.Year ? "contained" : undefined} onClick={() => onViewModeChange(ViewMode.Year)}>Anos</Button>
   </ButtonGroup>
  </ViewContainer>
 );
};