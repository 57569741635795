import FormContainer from 'app/components/Containers/FormContainer';
import FormStoreStatus from 'app/components/Forms/StoreStatus';
import { IDataRelation } from 'app/types/data/IData';
import { IDataStoreStatus, StoreStatusRelations } from 'app/types/data/IDataStoreStatus';
import React from 'react';

declare interface IStoreStatusFormContainer {
  data?: IDataStoreStatus;
  relation?: IDataRelation<StoreStatusRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataStoreStatus) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
}

const StoreStatusFormContainer = (props: IStoreStatusFormContainer) => {
  const [data, setData] = React.useState<IDataStoreStatus | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormStoreStatus
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
          />
        }
      /* tabs={
      data && [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={StoreStatus_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
    } */
      />
    </>
  );
};

export default StoreStatusFormContainer;
