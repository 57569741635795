import Endpoints from 'app/config/Endpoints';
import {
  MenuItemFields,
  IDataMenuItem,
  IDataMenuItemErrors,
  IHookFormMenuItem,
  IMenuItemPagination,
  IMenuItemFilter,
} from 'app/types/data/IDataMenuItem';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';

export default class Menu_Api extends Model_Api {
  static self = new Menu_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataMenuItemErrors {
    const resp: IDataMenuItemErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormMenuItem[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as MenuItemFields;
      const item: IHookFormMenuItem = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async listCombo(params: IMenuItemFilter): Promise<IDataMenuItem[]> {
    try {
      return (
        await (await Api.get(`${Endpoints.MenuItem.main}/combo/${params.menu_id}`, { params })).data as IDataMenuItem[]
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  static async list(params: IMenuItemFilter): Promise<IMenuItemPagination> {
    try {
      return (
        await (await Api.get(`${Endpoints.MenuItem.main}/all/${params.menu_id}`, { params })).data as IMenuItemPagination
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataMenuItem,
  ): Promise<IDataMenuItem> {
    try {
      const resp = (await Api.post(`${Endpoints.MenuItem.main}/${data.menu_id}`, data)).data as any;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Menu', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataMenuItem,
  ): Promise<IDataMenuItem> {
    try {
      const resp = (await Api.put(`${Endpoints.MenuItem.main}/${data.id}`, data)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Menu', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataMenuItem,
  ): Promise<IDataMenuItem> {
    try {
      var resp: any;
      resp = (await Api.delete(`${Endpoints.MenuItem.main}/${data.id}`)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Menu', handleErros);
      throw handleErros;
    }
  }
}
