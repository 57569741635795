import Segmentation_Api from 'app/api/Segmentation_Api';
import AttachmentModal, { IAttachmentModal } from 'app/components/Modal/AttachmentModal';
import Toast from 'app/components/Toast';
import { IDataErrors } from 'app/types/data/IData';
import { IDataSegmentation, IDataSegmentationErrors } from 'app/types/data/IDataSegmentation';
import { getMessage } from 'app/utils/messages';
import React from 'react';

declare interface ICreateAttachment extends IAttachmentModal {
  data: IDataSegmentation;
  onUpload?: (Segmentation: IDataSegmentation) => void;
  format?: 'attachments' | 'image' | 'media';
  multiple?: boolean;
  refreshGrid?: () => void;
  updateForm?: (data) => void;
}

const CREATE_MSG = getMessage('Anexo', 'create');

const CreateAttachment = (props: ICreateAttachment) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clean, setClean] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IDataErrors | undefined>(undefined);

  React.useEffect(() => setOpen(props.open), [props.open]);

  function handleClose() {
    setOpenToast(false);
  }

  async function sendAttachments(files: File[], links?: String[], as_copy?: number) {
    setErrors(undefined);
    setLoading(true);
    try {
      const newSegmentation = await Segmentation_Api.attach(props.data, files, links, as_copy);
      if (props?.updateForm) {
        const newData = await Segmentation_Api.show(props?.data);
        props?.updateForm(newData);
      }
      if (props?.refreshGrid) props.refreshGrid();
      setClean(true);
      setOpenToast(true);
      handleCancel();
      if (props.onUpload) props.onUpload(newSegmentation);
    } catch (error: IDataSegmentationErrors | unknown) {
      console.log('error', error);
      if (error) {
        const err = error as IDataSegmentationErrors;
        if (err.errors) setErrors(err.errors);
      }
    } finally {
      setLoading(false);
      setClean(false);
    }
  }

  function handleCancel() {
    setErrors(undefined);
    setOpen(false);
    if (props.onCancel) props.onCancel();
    if (props.onClose) props.onClose();
  }

  return (
    <>
      <AttachmentModal
        errors={errors}
        loading={loading}
        clearFiles={clean}
        open={open}
        onClose={() => {
          setErrors(undefined);
          props.onClose && props.onClose();
        }}
        onCancel={handleCancel}
        onSubmit={sendAttachments}
        format={props?.format}
        multiple={props?.multiple}
      />
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default CreateAttachment;
