import React from 'react';
import { styled, Tooltip, Typography } from '@mui/material';
import { ISelectOption } from 'app/types/ISelectOption';
import { IData } from 'app/types/data/IData';

const ListItemID = styled('div')(({ theme }) => ({
  textAlign: 'right',
  paddingRight: theme.spacing(1),
  minWidth: '2rem',
}));

const ListItem = styled('li')(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[200],
}));

const Secondary = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(1),
}));

const Image = styled('img')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: 4,
  objectFit: 'cover',
  marginRight: 8,
}));

export interface IOption<T> {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ISelectOption<T>;
  secondary?: string;
  image?: string;
}

export default function Option<T extends IData>(props: IOption<T>) {
  return (
    <ListItem {...props.props}>
      <Tooltip
        title={
          <>
            <strong>
              {props.option.value} | {props.option.label}{' '}
            </strong>{' '}
            {props.secondary ? `(${props.secondary})` : ''}
          </>
        }
      >
        <Typography noWrap variant="button" display="flex" alignItems="center">
          {props.image && <Image src={props.image} alt="Imagem Option" />}
          <ListItemID>{props.option.value}</ListItemID> | {props.option.label}{' '}
          {props.secondary ? <Secondary>({props.secondary})</Secondary> : ''}
        </Typography>
      </Tooltip>
    </ListItem>
  );
}
