export default function filterObjectColumns(data: any[][], customKeyName = 'name'): any[][] {
  const result = data.map((row) => [...row]);

  result.forEach((row) => {
    row.forEach((col, j) => {
      if (shouldTransform(col)) {
        row[j] = transform(col);
      }
    });
  });

  return result;

  function shouldTransform(col: any): boolean {
    return (
      (typeof col === 'object' && col?.label) ||
      col?.hasOwnProperty('name') ||
      col?.hasOwnProperty(customKeyName)
    );
  }

  function transform(col: any): string {
    if (col?.name) {
      return col.name;
    } else if (col?.label) {
      return col.label;
    } else if (col?.create_user) {
      return col?.create_user?.name;
    } else if (col?.update_user) {
      return col?.update_user?.name;
    } else {
      return col[customKeyName];
    }
  }
}
