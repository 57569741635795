import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  WorkflowPhasesFields,
  IDataWorkflowPhases,
  IDataWorkflowPhasesErrors,
  IHookFormWorkflowPhases,
  WorkflowPhasesRelations,
  IWorkflowPhasesPagination,
  IWorkflowPhasesFilter,
} from 'app/types/data/IDataWorkflowPhases';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';
import { format, toDate } from 'date-fns';

export default class WorkflowPhases_Api extends Model_Api {
  static self = new WorkflowPhases_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataWorkflowPhasesErrors {
    const resp: IDataWorkflowPhasesErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormWorkflowPhases[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as WorkflowPhasesFields;
      const item: IHookFormWorkflowPhases = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IWorkflowPhasesFilter,
    relation?: IDataRelation<WorkflowPhasesRelations>
  ): Promise<IWorkflowPhasesPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/etapa`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IWorkflowPhasesPagination;
      else
        return (
          await Api.get(Endpoints.WorkflowPhases.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IWorkflowPhasesPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataWorkflowPhases): Promise<IDataWorkflowPhases> {
    try {
      return (await Api.get(`${Endpoints.WorkflowPhases.main}/${data.id}`))
        .data as IDataWorkflowPhases;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataWorkflowPhases,
    relation?: IDataRelation<WorkflowPhasesRelations>
  ): Promise<IDataWorkflowPhases> {
    try {
      var started_in = data.started_in ? format(data.started_in as Date, 'yyyy-MM-dd') : null;
      var solve_until = data.solve_until
        ? format(data.solve_until as Date, 'yyyy-MM-dd HH:mm:ss')
        : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/etapa`, {
            ...data,
            workflow_id: relation.id,
            started_in,
            solve_until,
          })
        ).data.data as IDataWorkflowPhases;
      } else {
        resp = (await Api.post(Endpoints.WorkflowPhases.main, { ...data, started_in, solve_until }))
          .data.data as IDataWorkflowPhases;
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataWorkflowPhases,
    relation?: IDataRelation<WorkflowPhasesRelations>
  ): Promise<IDataWorkflowPhases> {
    try {
      const started_in =
        data.started_in && Boolean(Date.parse(data.started_in + ''))
          ? format(toDate(Date.parse(data.started_in + '')), 'yyyy-MM-dd')
          : null;
      const solve_until =
        data.solve_until && Boolean(Date.parse(data.solve_until + ''))
          ? format(toDate(Date.parse(data.solve_until + '')), 'yyyy-MM-dd')
          : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/etapa`, {
            ...data,
            workflow_id: relation.id,
            started_in,
            solve_until,
          })
        ).data.data as IDataWorkflowPhases;
      } else {
        resp = (
          await Api.post(`${Endpoints.WorkflowPhases.main}/${data.id}`, {
            ...data,
            started_in,
            solve_until,
          })
        ).data.data as IDataWorkflowPhases;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataWorkflowPhases,
    relation?: IDataRelation<WorkflowPhasesRelations>
  ): Promise<IDataWorkflowPhases> {
    try {
      var resp: IDataWorkflowPhases;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/etapa/${data.id}`)
        ).data.etapa;
      else resp = (await Api.delete(`${Endpoints.WorkflowPhases.main}/${data.id}`)).data.etapa;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataWorkflowPhases
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.WorkflowPhases.main}/${model.id}/attachments`
            : `/media${Endpoints.WorkflowPhases.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List WorkflowPhases', handleErros);
      throw handleErros;
    }
  }

  static async attach(etapa: IDataWorkflowPhases, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.WorkflowPhases.main}/${etapa.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.WorkflowPhases.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create WorkflowPhasess', handleErros);
      throw handleErros;
    }
  }
}
