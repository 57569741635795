import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import styles from '../CustomDragPreview.module.css';
import { IDataCategory } from 'app/types/data/IDataCategory';

type Props = {
  monitorProps: DragLayerMonitorProps<IDataCategory>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      {/* <div className={styles.icon}>{item?.data?.id}</div> */}
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
