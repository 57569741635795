import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const SkuSchema = yup.object().shape({
  sku: yup
    .string()
    //.min(4, FormMessages('SKU', 4).validations.min)
    .max(24, FormMessages('SKU', undefined, 24).validations.max)
    .nullable(true),
  name: yup.string().required(FormMessages('Nome').validations.required),
  short_name: yup.string().max(255, FormMessages('Nome reduzido', undefined, 255).validations.max),
  produto_id: yup.number().when('$productContext', {
    is: false,
    then: yup.number().required(FormMessages('Produto').validations.required),
    otherwise: yup.number().nullable(),
  }),
  nr_ean: yup
    .string()
    .max(13, FormMessages('Código de barras', undefined, 13).validations.max)
    .nullable(true)
    //.typeError(FormMessages('Código de barras').validations.invalid)
    /*.test('len', FormMessages('Código de barras', 13, 13).validations.exactly, (val) =>
      val ? val.length === 13 : true
    )*/,
  vl_precode: yup.number().nullable().typeError(FormMessages('Preço de').validations.invalid),
  vl_precopor: yup.number().nullable().typeError(FormMessages('Preço por').validations.invalid),
  smart_description: yup
    .string()
    .nullable()
    .max(255, FormMessages('SKU', undefined, 255).validations.max),
});
