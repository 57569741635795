import { IDataActivity } from 'app/types/data/IDataActivity';
import React from 'react';

interface IWorkflowActivityContext {
  onFormSave?: () => void;
  data?: IDataActivity;
}

const WorkflowActivityContext = React.createContext<IWorkflowActivityContext | null>(null);

export default WorkflowActivityContext;
