import { FormControlLabel, FormGroup, FormLabel, styled, Switch } from '@mui/material';
import GroupUser_Api from 'app/api/GroupUser_Api';
import Permissions_Api from 'app/api/Permissions_Api';
import Toast from 'app/components/Toast';
import { IDataGroupUser } from 'app/types/data/IDataGroupUser';
import { IDataPermission, IDataPermissionErrors } from 'app/types/data/IDataPermission';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { orderBy } from 'lodash';
import React from 'react';
import { IFormPermission } from '../@Types/IFormPermission';

const FormBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
}));

const FormGroupsContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginRight: theme.spacing(0.5),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  width: '24%',
}));

const FormGroupItem = styled(FormGroup)(({ theme }) => ({
  maxHeight: 200,
}));

const UPDATE_MSG = getMessage('Permissão', 'update');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const FormPermissions = (props: IFormPermission) => {
  const [data, setData] = React.useState<IDataPermission[]>([]);
  const [filteredData, setFilteredData] = React.useState<IDataPermission[]>([]);
  const [role, setRole] = React.useState<IDataGroupUser>(props.role);
  const [loading, setLoading] = React.useState<number[]>([]);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.role]);

  async function load() {
    try {
      const permissions = await Permissions_Api.list();
      const refreshRole = await GroupUser_Api.show(props.role);

      let perms = orderBy(permissions, ['model', 'id']);

      if (role.name === 'Membros de Projetos') {
        perms = perms.filter(
          (perm) => perm.model === 'Projeto' && perm.name !== 'list projeto all'
        );
      }

      setData(perms);
      setRole(refreshRole);
      showPermissions(perms)
    } catch (error) {
      console.error('error', error);
    }
  }

  const handleChange = (permission) => async (event) => {
    setLoading([...loading, permission]);
    try {
      if (event.target.checked) await Permissions_Api.give(props.role.id as number, permission);
      else await Permissions_Api.revoke(props.role.id as number, permission);
      await load();
      setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
    } catch (error: IDataPermissionErrors | unknown) {
      console.error(error);
      const err = error as IDataPermissionErrors;
      if (err.errors.message)
        setToast({ open: true, message: err.errors.message, severity: 'error' });
    } finally {
      const index = loading.indexOf(permission);
      const newLoading = loading;
      setLoading(newLoading.splice(index, 1));
    }
  };

  const checkPermission = (permission_id: number): boolean => {
    if (role.permissions) {
      return role.permissions.some((permission) => permission.id === permission_id);
    }
    return false;
  };
  var model: string | null = '';

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function showPermissions(items: IDataPermission[]) {
    const list = props.customMenuTags && props.customMenuTags.length > 0 ? items.filter(x => props.customMenuTags?.some(me => x.name.includes(me))) : items
    setFilteredData(list)
    if (props.setFilteredPermissions)
      props.setFilteredPermissions(list)
  }

  React.useEffect(() => {
    showPermissions(data)
  }, [props.customMenuTags])
  return (
    <FormBox id="formik-form-permission">
      {/*Fields*/}
      {data && (
        <>
          {filteredData.map((item, key) => {
            if (item.model !== model) {
              model = item.model;
              const formGroup = filteredData.filter((permission) => permission.model === model);
              return (
                <FormGroupsContainer>
                  <FormLabel>{model}</FormLabel>
                  <FormGroupItem>
                    {formGroup.map((item, key) => {
                      if (item.model === model) {
                        return (
                          <FormControlLabel
                            key={key}
                            label={item.label}
                            checked={checkPermission(item.id as number)}
                            onChange={handleChange(item.id)}
                            disabled={loading.some((load) => load === item.id)}
                            control={<Switch value={item.id} />}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </FormGroupItem>
                </FormGroupsContainer>
              );
            } else {
              return null;
            }
          })}
        </>
      )}
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default FormPermissions;
