// MyContext.tsx

import { IKanbanData, KanbanStep } from 'app/types/IKanban';
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ICurrentStep extends KanbanStep {
  step: number;
  data: KanbanStep;
}

interface IKanbanList {
  stage_id: number;
  kanban: IKanbanData[][];
}

interface MyContextData {
  currentStep: ICurrentStep | null;
  fetchingStepDataStatus: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<ICurrentStep | null>>;
  setFetchingStepDataStatus: React.Dispatch<React.SetStateAction<string>>;
  cachedData: IKanbanList[];
  setCachedData: React.Dispatch<React.SetStateAction<IKanbanList[]>>;
}

const KanbanContext = createContext<MyContextData>({} as MyContextData);

interface KanbanProviderProps {
  children: ReactNode;
}

const KanbanProvider = ({ children }: KanbanProviderProps) => {
  const [currentStep, setCurrentStep] = useState<ICurrentStep | null>(null);
  const [fetchingStepDataStatus, setFetchingStepDataStatus] = useState('idle');
  const [cachedData, setCachedData] = useState<IKanbanList[]>([]);

  const contextValue: MyContextData = {
    currentStep,
    setCurrentStep,
    fetchingStepDataStatus,
    setFetchingStepDataStatus,
    cachedData,
    setCachedData,
  };

  return <KanbanContext.Provider value={contextValue}>{children}</KanbanContext.Provider>;
};

const useKanban = () => useContext(KanbanContext);

export { KanbanProvider, useKanban };
