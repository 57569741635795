import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, styled, TextField, Typography } from '@mui/material';
import SellerChannels_Api from 'app/api/SellerChannels_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataSellerChannels, IDataSellerChannelsErrors } from 'app/types/data/IDataSellerChannels';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SellerChannelsSchema } from '../@Schemas/SellerChannelsSchema';
import { IFormSellerChannels } from '../@Types/IFormSellerChannels';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataSellerChannels = {
  id: '',
  name: '',
  description: '',
};

const UPDATE_MSG = getMessage('Canal de vendas', 'update');
const CREATE_MSG = getMessage('Canal de vendas', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SellerChannelsForm = (props: IFormSellerChannels) => {
  const { resetFormList } = useUtilsContext();
  const { getConfigCampo } = useAuth();

  const [data, setData] = React.useState<IDataSellerChannels>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataSellerChannels>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataSellerChannels> | null>(
    props.data
      ? { value: props.data?.id as number, label: props.data?.name, data: props.data }
      : null
  );
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setData((props.data as IDataSellerChannels) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.SellerChannelsForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataSellerChannels>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SellerChannelsSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function load() {
    setComboLoading(true);
    try {
      const items = await SellerChannels_Api.list({ q: search });

      const combo: ISelectOption<IDataSellerChannels>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataSellerChannelsErrors | unknown) {
      if (error) {
        const err = error as IDataSellerChannelsErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  async function onSubmit(data: IDataSellerChannels) {
    const campos = [{
      nome: 'Nome',
      campo: 'name'
    },
    {
      nome: 'Descrição',
      campo: 'description'
    },
    ]

    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required)
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    setLoading(true);
    try {
      var newData: IDataSellerChannels;
      if (data.id) {
        newData = await SellerChannels_Api.update(data, props.relation);
      } else {
        newData = await SellerChannels_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit(newData);
      }
      if (props.typeForm && props.typeForm === 'create' && props.sequency && props.onSubmit) {
        props.onSubmit()
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataSellerChannelsErrors | unknown) {
      if (error) {
        const err = error as IDataSellerChannelsErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FormBox id="formik-SellerChannels" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {props.relation && (
            <>
              {getConfigCampo && !getConfigCampo('Canais de Vendas', props.campos ?? []).hidden &&
                <Grid item xs={12} marginBottom={2}>
                  <Autocomplete
                    noOptionsText="Nenhum Canal de Vendas"
                    options={comboData}
                    defaultValue={comboOption}
                    value={comboOption}
                    loading={comboLoading}
                    onClose={() => setSearch('')}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event, option) => {
                      if (option && option.data) {
                        setData({ ...option.data, id: null, origin_id: option.data.id });
                        setComboOption(option);
                        setDataValue(
                          { ...option.data, id: null, origin_id: option.data.id },
                          setValue
                        );
                        if (props.onChangeRelation)
                          props.onChangeRelation({
                            ...option.data,
                            id: null,
                            origin_id: option.data.id,
                          });
                      } else {
                        setData(initialValues);
                        setComboOption(null);
                        setDataValue(initialValues, setValue);
                        if (props.onChangeRelation) props.onChangeRelation(undefined);
                      }
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Typography noWrap>
                          [{option.value}] {option.label}
                        </Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TimedTextField
                        {...params}
                        label="Canais de Vendas"
                        placeholder="Pesquisar uma canal de vendas"
                        type="text"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                        helperText={
                          <Typography color="red" variant="caption" component="span">
                            {errors.id?.message}
                          </Typography>
                        }
                      />
                    )}
                  />
                </Grid>
              }
            </>
          )}
          {props.type === 'update' && (
            <>
              {getConfigCampo && !getConfigCampo('id', props.campos ?? []).hidden &&
                <Grid item xs={12} md={2}>
                  <Controller
                    name="id"
                    control={control}
                    defaultValue={data.id}
                    render={({ field: { onChange } }) => (
                      <TextField
                        id="id"
                        label="ID"
                        type="text"
                        disabled={true}
                        value={data.id}
                        autoComplete={'off'}
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: Boolean(data.id) }}
                        onChange={(e) => {
                          setData({ ...data, id: parseInt(e.target.value) });
                          onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>
              }
              <Grid item xs={12} md={10}></Grid>
            </>
          )}

          {getConfigCampo && !getConfigCampo('Nome', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={data.name}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-name"
                    label="Nome*"
                    type="text"
                    value={data.name}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.name?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Descrição', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={data.description}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="form-description"
                    label="Descrição"
                    type="text"
                    multiline
                    rows={10}
                    value={data.description}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.description?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
            {props.typeForm && props.typeForm === 'create' && props.sequency ? (<>Salvar e continuar</>) : (<>Salvar</>)}
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default SellerChannelsForm;
