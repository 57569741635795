import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import Activity_Api from 'app/api/Activity_Api';
import SidebarForm from 'app/components/Forms/DrawerForms/SidebarForm';
import { useKanban } from 'app/contexts/Kanban/KanbanStepsContext';
import { IKanbanData } from 'app/types/IKanban';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import KanbanCard from './KanbanCard';

// Fixed columns of the Kanban
const getColumnNameByIndex = {
  1: 'A iniciar',
  2: 'Em andamento',
  3: 'Aguardando retorno',
  4: 'Reagendado',
  5: 'Concluída',
  6: 'Pausada',
  7: 'Cancelada',
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#e8ffe9' : '#f4f5f7',
  padding: grid,
  width: 320,
});

interface KanbanBoardProps {
  kanbanData: IKanbanData[][];
  relation?: IDataRelation<ActivityRelations>;
  fetchKanbanData: (filters?) => void;
  key: React.Key;
}

export default function KanbanBoard({ kanbanData, relation, fetchKanbanData }: KanbanBoardProps) {
  const [data, setData] = useState<IKanbanData[][]>(kanbanData);
  const { fetchingStepDataStatus } = useKanban();
  const [savingKanbanStatus, setSavingKanbanStatus] = useState('idle');
  const [selected, setSelected] = useState<IDataActivity | null>(null);
  const [isFormOpened, setIsFormOpened] = React.useState<boolean>(false);

  async function saveKanbanAndRefresh(content: IDataActivity, oldDATA: IKanbanData[][]) {
    try {
      setSavingKanbanStatus('pending');
      await Activity_Api.update(content, relation);
      fetchKanbanData();
      setSavingKanbanStatus('resolved');
    } catch (error) {
      setSavingKanbanStatus('rejected');
      setData(oldDATA);
    }
  }

  async function onDragEnd(result) {
    const dataCopy = [...data];
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (dataCopy[sInd][source.index]?.content) {
      dataCopy[sInd][source.index].content.position = destination.index;
    }

    if (sInd === dInd) {
      const items = reorder(dataCopy[sInd], source.index, destination.index);
      const newState = [...dataCopy];
      newState[sInd] = items as IKanbanData[];
      setData(newState);
      saveKanbanAndRefresh(dataCopy[sInd][source.index].content, dataCopy);
    } else {
      const sourceItem = data[sInd][source.index];
      const updatedItem = {
        ...sourceItem,
        status: getColumnNameByIndex[dInd + 1], // Set the new status based on the destination droppableId/index
        content: { ...sourceItem.content, status: getColumnNameByIndex[dInd + 1] },
      };

      const updatedSource = [...data[sInd]];
      updatedSource.splice(source.index, 1); // Remove the item from the source list

      const updatedDestination = [...data[dInd]];
      updatedDestination.splice(destination.index, 0, updatedItem); // Insert the item into the destination list

      const newState = [...data];
      newState[sInd] = updatedSource;
      newState[dInd] = updatedDestination;

      setData(newState);
      saveKanbanAndRefresh(updatedItem.content, dataCopy);
    }
  }

  return (
    <Box
      p={0}
      sx={
        savingKanbanStatus === 'pending' || fetchingStepDataStatus === 'pending'
          ? { pointerEvents: 'none', opacity: '0.4' }
          : null
      }
    >
      {savingKanbanStatus === 'pending' && (
        <Box mt={1}>
          <LinearProgress />
        </Box>
      )}
      <Box display="flex" overflow="auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Box display="flex" gap={2} minHeight={500}>
            {data?.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      color="GrayText"
                      mb={2}
                      padding={1}
                    >
                      <Typography variant="button">{getColumnNameByIndex[ind + 1]}</Typography>
                      <Tooltip title="Criar atividade">
                        <IconButton
                          aria-label="add-task"
                          size="small"
                          onClick={() => {
                            setSelected(null);
                            setIsFormOpened(true);
                          }}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    {el.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id + ''} index={index}>
                        {(provided, snapshot) => (
                          <KanbanCard
                            relation={relation}
                            refreshKanban={fetchKanbanData}
                            onClick={() => {
                              setIsFormOpened(true);
                              setSelected(item?.content);
                            }}
                            content={item?.content}
                            provided={provided}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            ))}
          </Box>
        </DragDropContext>
      </Box>
      <SidebarForm
        key={JSON.stringify(selected)}
        onSave={fetchKanbanData}
        selected={selected}
        commentsRelation={{ id: selected?.id as number, relation: 'Activity' }}
        open={isFormOpened}
        onClose={() => {
          setSelected(null);
          setIsFormOpened(false);
        }}
        relation={relation}
      />
    </Box>
  );
}
