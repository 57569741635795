import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  SegmentationModelFields,
  IDataSegmentationModel,
  IDataSegmentationModelErrors,
  IHookFormSegmentationModel,
  SegmentationModelRelations,
  ISegmentationModelPagination,
} from 'app/types/data/IDataSegmentationModel';
import { IParamsQs } from 'app/types/IParams';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class SegmentationModel_Api extends Model_Api {
  static self = new SegmentationModel_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSegmentationModelErrors {
    const resp: IDataSegmentationModelErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSegmentationModel[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SegmentationModelFields;
      const item: IHookFormSegmentationModel = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IParamsQs,
    relation?: IDataRelation<SegmentationModelRelations>
  ): Promise<ISegmentationModelPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/modelo_segmentacao`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentationModelPagination;
      else
        return (
          await Api.get(Endpoints.SegmentationModel.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentationModelPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSegmentationModel): Promise<IDataSegmentationModel> {
    try {
      return (await Api.get(`${Endpoints.SegmentationModel.main}/${data.id}`))
        .data as IDataSegmentationModel;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSegmentationModel,
    relation?: IDataRelation<SegmentationModelRelations>
  ): Promise<IDataSegmentationModel> {
    try {
      const resp = (await Api.post(Endpoints.SegmentationModel.main, data)).data
        .modelo_segmentacao as IDataSegmentationModel;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/modelo_segmentacao/${resp.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataSegmentationModel,
    relation?: IDataRelation<SegmentationModelRelations>
  ): Promise<IDataSegmentationModel> {
    try {
      const resp = (await Api.put(`${Endpoints.SegmentationModel.main}/${data.id}`, data)).data
        .modelo_segmentacao;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/modelo_segmentacao/${data.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataSegmentationModel,
    relation?: IDataRelation<SegmentationModelRelations>
  ): Promise<IDataSegmentationModel> {
    try {
      var resp: IDataSegmentationModel;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/modelo_segmentacao/${data.id}`
          )
        ).data.modelo_segmentacao;
      else
        resp = (await Api.delete(`${Endpoints.SegmentationModel.main}/${data.id}`)).data
          .modelo_segmentacao;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataSegmentationModel
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.SegmentationModel.main}/${model.id}/attachments`
            : `/media${Endpoints.SegmentationModel.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(modelo_segmentacao: IDataSegmentationModel, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (
        await Api.post(
          `${Endpoints.SegmentationModel.main}/${modelo_segmentacao.id}/attach`,
          formData
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationModels', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.SegmentationModel.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationModels', handleErros);
      throw handleErros;
    }
  }
}
