export const activityStatusOptions = [
  'A iniciar',
  'Em andamento',
  'Aguardando retorno',
  'Reagendado',
  'Concluída',
  'Pausada',
  'Cancelada',
];
export const activityQualityStatusOptions = [
  'Pré-cadastro Iniciado',
  'Pré-cadastro Finalizado',
  'Ajuste necessário (Umami.IA)',
  'Revisão Qualidade',
  'Ajuste necessário (Qualidade)',
  'Finalizado Qualidade',
];

export const activityPriorityStatusOptions = [
  { value: 1, label: 'Muito Alta' },
  { value: 2, label: 'Alta' },
  { value: 3, label: 'Média' },
  { value: 4, label: 'Baixa' },
  { value: 5, label: 'Muito Baixa' },
];
