import Endpoints from "app/config/Endpoints";
import { IDataRelation } from "app/types/data/IData";
import { GroupUserFields, GroupUserRelations, IDataGroupUser, IDataGroupUserErrors, IGroupUserPagination, IHookFormGroupUsers } from "app/types/data/IDataGroupUser";
import IParams from "app/types/IParams";
import { AxiosError } from "axios";
import Api from "./api";
import Model_Api from "./Model_Api";

export default class GroupUser_Api extends Model_Api {

  static self = new GroupUser_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataGroupUserErrors {
    const resp: IDataGroupUserErrors = this.formatErrors(error);
    const { errors } = resp
    const hookForm: IHookFormGroupUsers[] = []

    Object.keys(errors).forEach((key) => {
      const name = key as GroupUserFields
      const item: IHookFormGroupUsers = {
        type: "manual",
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item)
    });

    return { errors, hookForm }
  }

  static async list(params?: IParams<GroupUserFields>, relation?: IDataRelation<GroupUserRelations>): Promise<IGroupUserPagination> {
    try {
      if (relation)
        return (await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/role`, { params }))
          .data as IGroupUserPagination;
      else
        return (await Api.get(Endpoints.GroupUser.main, { params }))
          .data as IGroupUserPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Group User', handleErros);
      throw handleErros
    }
  }

  static async show(data: IDataGroupUser): Promise<IDataGroupUser> {
    try {
      return (await Api.get(`${Endpoints.GroupUser.main}/${data.id}`)).data
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Group User', handleErros);
      throw handleErros
    }
  }

  static async create(data: IDataGroupUser, relation?: IDataRelation<GroupUserRelations>): Promise<IDataGroupUser> {
    try {
      const resp = (await Api.post(Endpoints.GroupUser.main, data)).data.role as IDataGroupUser;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/role/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Group User', handleErros);
      throw handleErros
    }
  }

  static async update(data: IDataGroupUser, relation?: IDataRelation<GroupUserRelations>): Promise<IDataGroupUser> {
    try {
      const resp = (await Api.put(`${Endpoints.GroupUser.main}/${data.id}`, data)).data.role as IDataGroupUser;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/role/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Group User', handleErros);
      throw handleErros
    }
  }

  static async delete(data: IDataGroupUser, relation?: IDataRelation<GroupUserRelations>): Promise<IDataGroupUser> {
    try {
      var resp: IDataGroupUser;

      if (relation)
        resp = (await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/role/${data.id}`)).data.role;
      else
        resp = (await Api.delete(`${Endpoints.GroupUser.main}/${data.id}`)).data.role;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Group User', handleErros);
      throw handleErros
    }
  }
}