import React from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Icon,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { IDataActivity } from 'app/types/data/IDataActivity';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Activity_Api from 'app/api/Activity_Api';
import { LoadingButton } from '@mui/lab';
import { KanbanCardProps } from 'app/types/IKanban';
import { useToast } from 'app/hooks/useToast';
import getDateBackgroundColor from 'app/utils/Kanban/getDateBackgroundColor';
import stringAvatar from 'app/utils/Kanban/stringAvatar';

const getPriorityColor = {
  1: { icon: 'keyboard_double_arrow_up', hex: '#ff5832' },
  2: { icon: 'keyboard_arrow_up', hex: '#ff5832' },
  3: { icon: 'drag_handle', hex: '#ffab01' },
  4: { icon: 'keyboard_arrow_down', hex: '#2684ff' },
  5: { icon: 'keyboard_double_arrow_down', hex: '#2684ff' },
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
};

const AvatarBox = ({
  avatar,
  name,
  tooltipTitle,
}: {
  avatar?: string;
  name: string;
  tooltipTitle: string;
  borderColor?: string;
}) => {
  const style = { width: 24, height: 24 };
  return (
    <Tooltip title={tooltipTitle}>
      {Boolean(avatar?.length) ? (
        <Avatar alt={name} src={avatar} sx={style} />
      ) : (
        <Avatar alt={name} {...stringAvatar(name)} />
      )}
    </Tooltip>
  );
};

const KanbanCard = ({ provided, onClick, content, refreshKanban, relation }: KanbanCardProps) => {
  const [deleteButton, setDeleteButton] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<IDataActivity | null>(null);
  const [deleteStatus, setDeleteStatus] = React.useState('idle');
  const { showToast } = useToast();
  const dateBackgroundColor = getDateBackgroundColor(content?.end_date as string);

  const handleDelete = async (data: IDataActivity) => {
    try {
      setDeleteStatus('pending');
      await Activity_Api.delete(data, relation);
      showToast({
        open: true,
        message: 'Atividade removida com sucesso!',
        severity: 'success',
      });
      setOpen(null);
      refreshKanban();
      setDeleteStatus('resolved');
    } catch (error) {
      setDeleteStatus('rejected');
      refreshKanban();
      setOpen(null);
      showToast({
        open: true,
        message: 'Não foi possível remover a atividade',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Paper
        onClick={onClick}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          padding: '8px',
          mb: 1,
          borderLeft: `3px solid ${getPriorityColor[content?.priority?.value as number]?.['hex']}`,
          color: 'MenuText',
        }}
      //style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          onMouseEnter={() => setDeleteButton(content?.id as number)}
          onMouseLeave={() => setDeleteButton(null)}
          sx={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            msHyphens: 'auto',
            MozHyphens: 'auto',
            WebkitHyphens: 'auto',
            hyphens: 'auto',
          }}
        >
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="caption" color="GrayText">
                ID: {content?.id}
              </Typography>
              <Tooltip title="Remover">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(content);
                  }}
                  aria-label="delete"
                  size="small"
                  sx={{ visibility: deleteButton === content?.id ? 'visible' : 'hidden' }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="subtitle1" fontSize="1rem">
              {content?.summary}
            </Typography>
          </Box>
          <Box display="flex" gap={1} flexWrap="wrap">
            {content?.tags?.map((tag) => (
              <Chip label={tag} color="success" variant="outlined" size="small" />
            ))}
          </Box>
          {content?.end_date && (
            <Box
              display="flex"
              width="max-content"
              color="GrayText"
              sx={{
                ...dateBackgroundColor,
                padding: dateBackgroundColor?.background ? '0 5px' : 0,
                borderRadius: '5px',
              }}
            >
              {dateBackgroundColor?.background && <AccessTimeIcon fontSize="small" />}
              <Typography ml="3px" fontWeight="bold">
                {format(new Date(content?.end_date as string), 'dd/MM/yyyy')}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <Tooltip title={`Prioridade: ${content?.priority?.label}`}>
              <Icon
                sx={{
                  color: getPriorityColor[content?.priority?.value as number]?.['hex'],
                }}
              >
                {getPriorityColor[content?.priority?.value as number]?.['icon']}
              </Icon>
            </Tooltip>
            <AvatarGroup max={2} sx={{ cursor: 'default' }}>
              {content?.assign && (
                <AvatarBox
                  tooltipTitle={`Responsável: ${content?.assign?.name}`}
                  avatar={content?.assign?.avatar}
                  name={content?.assign?.name || ''}
                />
              )}
              {content?.performer && (
                <AvatarBox
                  tooltipTitle={`Executante: ${content?.performer?.name}`}
                  avatar={content?.performer?.avatar}
                  name={content?.performer?.name || ''}
                />
              )}
            </AvatarGroup>
          </Box>
        </Box>
      </Paper>
      <Modal
        open={Boolean(open)}
        onClose={() => {
          if (deleteStatus !== 'pending') setOpen(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Typography variant="subtitle1" fontSize="1.2rem" fontWeight={500}>
                  Tem certeza que deseja remover a atividade:
                </Typography>
                <Typography variant="subtitle1">{open?.summary}</Typography>
              </Stack>
              <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
                <Button
                  disabled={deleteStatus === 'pending'}
                  onClick={() => setOpen(null)}
                  variant="text"
                  color="primary"
                >
                  Cancelar
                </Button>
                <LoadingButton
                  loading={deleteStatus === 'pending'}
                  onClick={() => handleDelete(content)}
                  variant="contained"
                  color="error"
                >
                  Remover
                </LoadingButton>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default KanbanCard;
