import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const UnitSchema = yup.object().shape({
  sigla_unidade: yup
    .string()
    .max(4, 'O campo Sigla não pode ser superior a 4 caracteres.')
    .required(FormMessages('Sigla').validations.required),
  ds_unidade: yup.string().required(FormMessages('Descrição').validations.required),
});
