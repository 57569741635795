import React from 'react';
import { Autocomplete, Typography } from '@mui/material';
import User_Api from 'app/api/User_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataUser, IDataUserErrors } from 'app/types/data/IDataUser';
import Option from '../Option/Option';
import TimedTextField from '../../TimedTextField/TimedTextField';

export interface ISelectUser {
  id?: string;
  value?: IDataUser | null;
  disabled?: boolean;
  error?: string;
  onChange?: (User?: IDataUser) => void;
}

const SelectCurator = (props: ISelectUser) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataUser>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataUser> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataUser> | undefined = props.value
      ? {
        label: props.value?.name,
        value: props.value?.id as number,
        data: props.value,
      }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await User_Api.list({ q: search, is_curator: 1 });

      const combo: ISelectOption<IDataUser>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataUserErrors | unknown) {
      if (error) {
        const err = error as IDataUserErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Autocomplete
      id={props.id}
      noOptionsText="Nenhum curador encontrado"
      options={comboData}
      defaultValue={comboOption}
      value={comboOption || null}
      loading={loading}
      onClose={() => setSearch('')}
      disabled={props.disabled}
      //isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => {
        if (option && option.data) {
          setComboOption(option);
          if (props.onChange) props.onChange(option.data);
        } else {
          setComboOption(undefined);
          if (props.onChange) props.onChange(undefined);
        }
      }}
      renderOption={(props, option) => <Option key={option.value} props={props} option={option} />}
      renderInput={(params) => (
        <TimedTextField
          {...params}
          label="Curador"
          placeholder="Pesquisar um curador"
          type="text"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          helperText={
            <Typography color="red" variant="caption" component="span">
              {errors}
            </Typography>
          }
        />
      )}
    />
  );
};

export default SelectCurator;
