declare interface IValidationsMessages {
  required: string;
  min: string;
  max: string;
  exactly: string;
  email: string;
  invalid: string;
}

declare interface IFormMessages {
  validations: IValidationsMessages;
}

export const FormMessages = (field: string, min?: number, max?: number): IFormMessages => ({
  validations: {
    required: `${field.toUpperCase()} é um campo obrigatório.`,
    min: `${field.toUpperCase()} deve ter no mínimo ${min} caracteres.`,
    max: `${field.toUpperCase()} deve ter no máximo ${max} caracteres.`,
    exactly: `${field.toUpperCase()} deve exatos ${max || min} caracteres.`,
    email: `${field.toUpperCase()} deve ser um e-mail válido.`,
    invalid: `${field.toUpperCase()} é inválido.`,
  },
});

export const TranslateMessages = (code_message: string): string => {
  switch (code_message) {
    case 'file-invalid-type':
      return 'Formato de arquivo inválido.';
    case 'size-too-large':
      return 'Tamanho do arquivo excede o tamanho máximo.';
    default:
      return 'Arquivo inválido';
  }
};
