/* eslint-disable eqeqeq */
import { alpha, useTheme } from '@mui/material';
import Activity_Api from 'app/api/Activity_Api';
import WorkflowPhases_Api from 'app/api/WorkflowPhases_Api';
import SidebarForm from 'app/components/Forms/DrawerForms/SidebarForm';
import Toast from 'app/components/Toast';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  ActivityRelations,
  IDataActivity,
  IDataActivityErrors,
} from 'app/types/data/IDataActivity';
import { convertStringToDate } from 'app/utils/convertsData';
import { DisplayOption, Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
//import html2pdf from 'html2pdf.js';
import React from 'react';
import { ViewSwitcher } from './Switcher/ViewSwitcher';
import TaskListHeader from './TaskList/TaskListHeader';
import TaskListTable from './TaskList/TaskListTable';
import { TooltipGantt } from './Tooltip/TooltipGantt';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';

declare interface IDisplayActivity {
  relation?: IDataRelation<ActivityRelations>;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
  onSubmit?: () => void;
}

const Options: DisplayOption = {
  locale: 'pt-br',
};

const initialToast: IToast = {
  open: false,
  message: '',
  severity: 'success',
};

/* const exportToPDF = () => {
  const element = document.getElementById('cronograma-gantt');

  var opt = {
    filename: 'cronograma.pdf',
    html2canvas: {
      scale: 2,
      logging: true,
      letterRendering: true,
      useCORS: true,
      width: 8000,
      height: 1080,
    },
    jsPDF: { unit: 'px', format: 'letter', orientation: 'landscape' },
  };

  html2pdf().set(opt).from(element).save('cronograma-gantt.pdf');
}; */

const GanttWorkflow = (props: IDisplayActivity) => {
  const theme = useTheme();
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [activitys, setActivitys] = React.useState<IDataActivity[]>([]);
  const [selected, setSelected] = React.useState<IDataActivity | null>(null);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function load() {
    setLoading(true);
    try {
      const phases = await WorkflowPhases_Api.list(
        { sort: 'started_in', order: 'asc' },
        props.relation
      );

      const items = await Activity_Api.list({ sort: 'start_date', order: 'asc' }, props.relation);
      const itemsFilter = items.data.filter((item) => item.start_date && item.end_date);

      var allTasks: Task[] = [];
      const phasesFilter = phases.data.filter((item) => item.started_in && item.solve_until);
      phasesFilter.forEach((phase, indexPhase) => {
        const project: Task = {
          start: convertStringToDate(phase.started_in as unknown as string),
          end: convertStringToDate(phase.solve_until as unknown as string),
          name: phase.name,
          id: `stage_${phase.id}`,
          type: 'project',
          progress: 100,
          styles: {
            progressColor: theme.palette.secondary.dark,
            progressSelectedColor: alpha(theme.palette.success.dark, 0.7),
            backgroundColor: theme.palette.secondary.main,
          },
          hideChildren: false,
        };

        allTasks.push(project);

        const activitysPhase = itemsFilter.filter((actv) => actv.stage_id === phase.id);

        const activitysMap = activitysPhase.map<Task>((item) => {
          return {
            start: convertStringToDate(item.start_date as string),
            end: convertStringToDate(item.end_date as string),
            name: item.summary,
            id: `activity_${item.id}`,
            type: 'task',
            progress: item.progress || 0,
            styles: {
              progressColor: alpha(theme.palette.success.main, 0.75),
              progressSelectedColor: alpha(theme.palette.success.main, 0.5),
              backgroundColor: alpha(theme.palette.success.light, 0.5),
            },
            project: `stage_${item.stage_id}`,
          };
        });

        allTasks = [...allTasks, ...activitysMap];
      });

      setActivitys(itemsFilter);

      //const allTasks = [...phasesMap, ...itemsMap];
      allTasks = allTasks.map<Task>((task, index) => ({ ...task, displayOrder: index + 1 }));
      setTasks(allTasks);
    } catch (error: IDataActivityErrors | unknown) {
      if (error) {
        const err = error as IDataActivityErrors;
        if (err?.errors?.status && err?.errors?.message)
          setToast({
            open: true,
            message: `${err.errors.status} - ${err.errors.message}`,
            severity: 'error',
          });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  const handleTaskChange = async (task: Task) => {
    if (task.type === 'task') {
      const activity_id = task.id.replace('activity_', '');
      const actvSelected = activitys.filter((actv) => actv.id == activity_id);
      if (actvSelected.length > 0) {
        actvSelected[0].start_date = task.start;
        actvSelected[0].end_date = task.end;

        await Activity_Api.update(actvSelected[0], props.relation);
        load();
      }
    }
  };

  const handleClick = async (task: Task) => {
    if (task.type === 'task') {
      const activity_id = task.id.replace('activity_', '');
      // eslint-disable-next-line eqeqeq
      const actvSelected = activitys.filter((actv) => actv.id == activity_id);
      if (actvSelected.length > 0) {
        setSelected(actvSelected[0]);
      }
    }
  };

  const handleProgressChange = async (task: Task) => {
    if (task.type === 'task') {
      const activity_id = task.id.replace('activity_', '');
      const actvSelected = activitys.filter((actv) => actv.id == activity_id);
      if (actvSelected.length > 0) {
        actvSelected[0].progress = task.progress;

        await Activity_Api.update(actvSelected[0], props.relation);
        load();
      }
    }
  };

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  if (tasks.length === 0) {
    return <p>Carregando dados...</p>;
  } else {
    return (
      <div className="Wrapper">
        {/* <button onClick={exportToPDF}>Exportar para PDF</button> */}
        <ViewSwitcher onViewModeChange={(viewMode) => setView(viewMode)} activeView={view} />
        <div style={{ opacity: loading ? 0.5 : 1 }} id="cronograma-gantt">
          <Gantt
            tasks={tasks}
            viewMode={view}
            locale={Options.locale}
            onDateChange={handleTaskChange}
            onProgressChange={handleProgressChange}
            TaskListHeader={TaskListHeader}
            TaskListTable={(props) => <TaskListTable {...props} rowWidth={250} />}
            TooltipContent={TooltipGantt}
            onDoubleClick={handleClick}
            onExpanderClick={handleExpanderClick}
            columnWidth={columnWidth}
          />
        </div>
        {selected && (
          <SidebarForm
            key={JSON.stringify(selected)}
            onSave={() => load()}
            selected={selected}
            commentsRelation={{ id: selected?.id as number, relation: 'Activity' }}
            open={Boolean(selected)}
            onClose={() => {
              setSelected(null);
            }}
            relation={props.relation}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        )}

        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </div>
    );
  }
};

export default GanttWorkflow;
