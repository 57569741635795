import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import { IAttachmentFilter, IAttachmentPagination, IDataAttachment } from 'app/types/data/IDataAttachment';
import {
  CommentFields,
  CommentRelations,
  ICommentPagination,
  IDataComment,
  IDataCommentErrors,
  IHookFormComment,
  ICommentFilter,
} from 'app/types/data/IDataComment';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';
import QueryString from 'qs';

export default class Comment_Api extends Model_Api {
  static self = new Comment_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataCommentErrors {
    const resp: IDataCommentErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormComment[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as CommentFields;
      const item: IHookFormComment = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: ICommentFilter,
    relation?: IDataRelation<CommentRelations>
  ): Promise<ICommentPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/${Endpoints.Comment.main}`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as ICommentPagination;
      else return (await Api.get(Endpoints.Comment.main, { params })).data as ICommentPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Comments', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataComment,
    relation?: IDataRelation<CommentRelations>
  ): Promise<IDataComment> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/${Endpoints.Comment.main}`, {
            ...data
          })
        ).data.comentario as IDataComment;
      } else {
        resp = (
          await Api.post(Endpoints.Comment.main, {
            ...data,
          })
        ).data.comentario as IDataComment;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Comments', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataComment,
    relation?: IDataRelation<CommentRelations>
  ): Promise<IDataComment> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/Comment`, {
            ...data,
          })
        ).data.comentario as IDataComment;
      } else {
        resp = (
          await Api.put(`${Endpoints.Comment.main}/${data.id}`, {
            ...data,
          })
        ).data.comentario;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Comments', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataComment,
    relation?: IDataRelation<CommentRelations>
  ): Promise<IDataComment> {
    try {
      var resp: IDataComment;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/Comment/${data.id}`)
        ).data.comentario;
      else resp = (await Api.delete(`${Endpoints.Comment.main}/${data.id}`)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Comments', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    Comment?: IDataComment,
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          Comment
            ? `${Endpoints.Comment.main}/${Comment.id}/attachments`
            : `/media${Endpoints.Comment.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Comment', handleErros);
      throw handleErros;
    }
  }


  static async attach(Comment: IDataComment, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Comment.main}/${Comment.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Comment', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Comment.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Comment', handleErros);
      throw handleErros;
    }
  }
}
