import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  ActivityFields,
  ActivityRelations,
  IActivityFilter,
  IActivityPagination,
  IDataActivity,
  IDataActivityErrors,
  IDataIDClone,
  IHookFormActivity,
} from 'app/types/data/IDataActivity';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import FixedSelectOptions from 'app/utils/FixedSelectOptions';
import { AxiosError } from 'axios';
import { format, toDate } from 'date-fns';
import QueryString from 'qs';
import Model_Api from './Model_Api';
import Api from './api';

export default class Activity_Api extends Model_Api {
  static self = new Activity_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataActivityErrors {
    const resp: IDataActivityErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormActivity[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as ActivityFields;
      const item: IHookFormActivity = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IActivityFilter,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IActivityPagination> {
    try {
      var resp;
      if (relation)
        resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/atividade`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      else
        resp = (
          await Api.get(Endpoints.Activity.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      resp.data.forEach((item: IDataActivity) => {
        //item['status'] = FixedSelectOptions.Activity.status[item.status as string];
        item['priority'] = FixedSelectOptions.Activity.priority[item.priority as number];
        //item['quality_status'] = FixedSelectOptions.Activity.quality_status[item.quality_status as string];
        if (item?.notification_conf?.sending_frequency) {
          item.notification_conf.sending_frequency =
            FixedSelectOptions.Activity.notification_conf.sending_frequency[
              item.notification_conf.sending_frequency as string
            ];
        }
      });
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Activitys', handleErros);
      throw handleErros;
    }
  }

  static async listPredecessores(
    params?: IActivityFilter,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IActivityPagination> {
    try {
      var resp;
      if (relation)
        resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/predecessores`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      else
        resp = (
          await Api.get(`${Endpoints.Activity.main}/predecessores`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      resp.data.forEach((item: IDataActivity) => {
        //item['status'] = FixedSelectOptions.Activity.status[item.status as string];
        item['priority'] = FixedSelectOptions.Activity.priority[item.priority as number];
        //item['quality_status'] = FixedSelectOptions.Activity.quality_status[item.quality_status as string];
        if (item?.notification_conf?.sending_frequency) {
          item.notification_conf.sending_frequency =
            FixedSelectOptions.Activity.notification_conf.sending_frequency[
              item.notification_conf.sending_frequency as string
            ];
        }
      });
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Activitys', handleErros);
      throw handleErros;
    }
  }

  static async sucessoras(
    params?: IActivityFilter,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IActivityPagination> {
    try {
      var resp;
      if (relation)
        resp = (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/sucessoras`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      else
        resp = (
          await Api.get(`${Endpoints.Activity.main}/sucessoras`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IActivityPagination;
      resp.data.forEach((item: IDataActivity) => {
        //item['status'] = FixedSelectOptions.Activity.status[item.status as string];
        item['priority'] = FixedSelectOptions.Activity.priority[item.priority as number];
        //item['quality_status'] = FixedSelectOptions.Activity.quality_status[item.quality_status as string];
        if (item?.notification_conf?.sending_frequency) {
          item.notification_conf.sending_frequency =
            FixedSelectOptions.Activity.notification_conf.sending_frequency[
              item.notification_conf.sending_frequency as string
            ];
        }
      });
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Activitys', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataActivity): Promise<IDataActivity> {
    try {
      return (await Api.get(`${Endpoints.Activity.main}/${data.id}`)).data as IDataActivity;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Activitys', handleErros);
      throw handleErros;
    }
  }

  static async clone(data: IDataIDClone): Promise<IDataIDClone> {
    try {
      return await Api.post(`${Endpoints.Workflow.main}/${data.workflow_id}/atividade/clone`, {
        id: data.id,
        etapa_id: data.stage_id,
        to_keep: data.to_keep,
      });
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Clone Activity', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataActivity,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IDataActivity> {
    try {
      var start_date = data.start_date
        ? format(data.start_date as Date, 'yyyy-MM-dd HH:mm:ss')
        : null;
      var end_date = data.end_date ? format(data.end_date as Date, 'yyyy-MM-dd HH:mm:ss') : null;
      var delivery_date = data.delivery_date
        ? format(data.delivery_date, 'yyyy-MM-dd HH:mm:ss')
        : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atividade`, {
            ...data,
            start_date,
            end_date,
            delivery_date,
            status: data?.status,
            quality_status: data?.quality_status,
            priority: data?.priority?.value,
          })
        ).data;
      } else {
        resp = (
          await Api.post(Endpoints.Activity.main, {
            ...data,
            start_date,
            end_date,
            delivery_date,
            status: data?.status,
            quality_status: data?.quality_status,
            priority: data?.priority?.value,
          })
        ).data;
      }
      resp = resp?.workflow_atividade ? resp.workflow_atividade : resp.data;
      //resp['status'] = FixedSelectOptions.Activity.status[resp.status];
      resp['priority'] = FixedSelectOptions.Activity.priority[resp.priority];
      //resp['quality_status'] = FixedSelectOptions.Activity.quality_status[resp.quality_status];
      if (resp?.notification_conf?.sending_frequency) {
        resp.notification_conf.sending_frequency =
          FixedSelectOptions.Activity.notification_conf.sending_frequency[
            resp.notification_conf.sending_frequency as string
          ];
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Activitys', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataActivity,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IDataActivity> {
    try {
      const start_date =
        data.start_date && Boolean(Date.parse(data.start_date + ''))
          ? format(toDate(Date.parse(data.start_date + '')), 'yyyy-MM-dd HH:mm:ss')
          : null;
      const end_date =
        data.end_date && Boolean(Date.parse(data.end_date + ''))
          ? format(toDate(Date.parse(data.end_date + '')), 'yyyy-MM-dd HH:mm:ss')
          : null;
      const delivery_date =
        data.delivery_date && Boolean(Date.parse(data.delivery_date + ''))
          ? format(toDate(Date.parse(data.delivery_date + '')), 'yyyy-MM-dd')
          : null;
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/atividade`, {
            ...data,
            start_date,
            end_date,
            delivery_date,
            status: data?.status,
            quality_status: data?.quality_status,
            priority: data?.priority?.value,
          })
        ).data;
      } else {
        resp = (
          await Api.put(`${Endpoints.Activity.main}/${data.id}`, {
            ...data,
            start_date,
            end_date,
            delivery_date,
            status: data?.status,
            quality_status: data?.quality_status,
            priority: data?.priority?.value,
          })
        ).data;
      }
      resp = resp?.workflow_atividade ? resp.workflow_atividade : resp.data;
      //resp['status'] = FixedSelectOptions.Activity.status[resp.status];
      resp['priority'] = FixedSelectOptions.Activity.priority[resp.priority];
      //resp['quality_status'] = FixedSelectOptions.Activity.quality_status[resp.quality_status];
      if (resp?.notification_conf?.sending_frequency) {
        resp.notification_conf.sending_frequency =
          FixedSelectOptions.Activity.notification_conf.sending_frequency[
            resp.notification_conf.sending_frequency as string
          ];
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Activitys', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataActivity,
    relation?: IDataRelation<ActivityRelations>
  ): Promise<IDataActivity> {
    try {
      var resp: IDataActivity;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/atividade/${data.id}`
          )
        ).data.atividade;
      else resp = (await Api.delete(`${Endpoints.Activity.main}/${data.id}`)).data.atividade;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Activitys', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataActivity
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Activity.main}/${model.id}/attachments`
            : `/media${Endpoints.Activity.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Activity', handleErros);
      throw handleErros;
    }
  }

  static async attach(atividade: IDataActivity, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Activity.main}/${atividade.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Activitys', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Activity.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Activitys', handleErros);
      throw handleErros;
    }
  }
}
