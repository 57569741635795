import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import { IAttachmentFilter, IAttachmentPagination, IDataAttachment } from 'app/types/data/IDataAttachment';
import {
  ServiceTypeFields,
  IDataServiceType,
  IDataServiceTypeErrors,
  IHookFormServiceType,
  ServiceTypeRelations,
  IServiceTypePagination,
  IServiceTypeFilter,
} from 'app/types/data/IDataServiceType';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class ServiceType_Api extends Model_Api {
  static self = new ServiceType_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataServiceTypeErrors {
    const resp: IDataServiceTypeErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormServiceType[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as ServiceTypeFields;
      const item: IHookFormServiceType = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IServiceTypeFilter,
    relation?: IDataRelation<ServiceTypeRelations>
  ): Promise<IServiceTypePagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/tipo_servico`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IServiceTypePagination;
      else
        return (await Api.get(Endpoints.ServiceType.main,
          {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }))
          .data as IServiceTypePagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataServiceType): Promise<IDataServiceType> {
    try {
      return (await Api.get(`${Endpoints.ServiceType.main}/${data.id}`)).data as IDataServiceType;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataServiceType,
    relation?: IDataRelation<ServiceTypeRelations>
  ): Promise<IDataServiceType> {
    try {
      const resp = (await Api.post(Endpoints.ServiceType.main, data)).data
        .tipo_servico as IDataServiceType;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/tipo_servico/${resp.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataServiceType,
    relation?: IDataRelation<ServiceTypeRelations>
  ): Promise<IDataServiceType> {
    try {
      const resp = (await Api.put(`${Endpoints.ServiceType.main}/${data.id}`, data)).data
        .tipo_servico;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/tipo_servico/${data.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataServiceType,
    relation?: IDataRelation<ServiceTypeRelations>
  ): Promise<IDataServiceType> {
    try {
      var resp: IDataServiceType;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/tipo_servico/${data.id}`
          )
        ).data.tipo_servico;
      else resp = (await Api.delete(`${Endpoints.ServiceType.main}/${data.id}`)).data.tipo_servico;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataServiceType,
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.ServiceType.main}/${model.id}/attachments`
            : `/media${Endpoints.ServiceType.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List ServiceType', handleErros);
      throw handleErros;
    }
  }

  static async attach(tipo_servico: IDataServiceType, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.ServiceType.main}/${tipo_servico.id}/attach`, formData))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ServiceTypes', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.ServiceType.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ServiceTypes', handleErros);
      throw handleErros;
    }
  }
}
