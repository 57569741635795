import FormContainer from 'app/components/Containers/FormContainer';
import FormSellerChannels from 'app/components/Forms/SellerChannels';
import { IDataRelation } from 'app/types/data/IData';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { IDataSellerChannels, SellerChannelsRelations } from 'app/types/data/IDataSellerChannels';
import React from 'react';

declare interface ISellerChannelsFormContainer {
  data?: IDataSellerChannels;
  relation?: IDataRelation<SellerChannelsRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataSellerChannels) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const SellerChannelsFormContainer = (props: ISellerChannelsFormContainer) => {
  const [data, setData] = React.useState<IDataSellerChannels | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormSellerChannels
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
      /* tabs={
      data && [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={SellerChannels_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
    } */
      />
    </>
  );
};

export default SellerChannelsFormContainer;
