import React from 'react';
import FormContainer from 'app/components/Containers/FormContainer';
import CategoryForm from 'app/components/Forms/Categories';
import { IDataCategory, CategoryRelations } from 'app/types/data/IDataCategory';
import { IDataRelation } from 'app/types/data/IData';

declare interface ICategoryFormContainer {
  data?: IDataCategory;
  relation?: IDataRelation<CategoryRelations>;
  onClickAttach?: () => void;
  onSubmit: (item: any, data?) => void;
  cancel: () => void;
  setSelected: (data) => void;
}

const CategoryFormContainer = (props: ICategoryFormContainer) => {
  const [data, setData] = React.useState<IDataCategory | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        mainForm={
          <CategoryForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
          />
        }
      />
    </>
  );
};

export default CategoryFormContainer;
