import { Box, IconButton } from '@mui/material';

const StepperNavigationButton = ({ iconComponent, onClick, hideArrow, alignRight = false }) => {
  const positionStyle = alignRight ? { right: 0 } : { left: 0 };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      zIndex={2}
      height="100%"
      bgcolor="#fff"
      position="absolute"
      border="1px solid #e7e7e7"
      top="50%"
      onClick={onClick}
      sx={{
        visibility: hideArrow ? 'hidden' : 'visible',
        transform: 'translate(0, -50%)',
        ...positionStyle,
      }}
    >
      <IconButton>{iconComponent}</IconButton>
    </Box>
  );
};

export default StepperNavigationButton;
