import FormContainer from 'app/components/Containers/FormContainer';
import FormSegmentationTypes from 'app/components/Forms/SegmentationTypes';
import { IDataRelation } from 'app/types/data/IData';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import {
  IDataSegmentationTypes,
  SegmentationTypesRelations,
} from 'app/types/data/IDataSegmentationTypes';
import React from 'react';

declare interface ISegmentationTypesFormContainer {
  data?: IDataSegmentationTypes;
  relation?: IDataRelation<SegmentationTypesRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataSegmentationTypes) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const SegmentationTypesFormContainer = (props: ISegmentationTypesFormContainer) => {
  const [data, setData] = React.useState<IDataSegmentationTypes | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        mainForm={
          <FormSegmentationTypes
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
      /* tabs={
      data && [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={SegmentationTypes_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
    } */
      />
    </>
  );
};

export default SegmentationTypesFormContainer;
