import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryExtraOptions,
  CategoryFields,
  CategoryRelations,
  ICategoryFilter,
  ICategoryPagination,
  IDataCategory,
  IDataCategoryErrors,
  IHookFormCategory,
} from 'app/types/data/IDataCategory';
import { AxiosError } from 'axios';
import qs from 'qs';
import Model_Api from './Model_Api';
import Api from './api';

export default class Categories_Api extends Model_Api {
  static self = new Categories_Api();
  static relationNames = ['Segmentation'];

  protected handleErrors(error: AxiosError | Error | string | any): IDataCategoryErrors {
    const resp: IDataCategoryErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormCategory[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as CategoryFields;
      const item: IHookFormCategory = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: ICategoryFilter,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<ICategoryPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/categoria`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as ICategoryPagination;
      return (
        await Api.get(Endpoints.Category.main, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
      ).data as ICategoryPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Categories', handleErros);
      throw handleErros;
    }
  }

  static async export(params?: ICategoryFilter, relation?: IDataRelation<CategoryRelations>) {
    try {
      var resp;
      var nameFile = 'categorias_agnostico';
      if (relation) {
        resp = await Api.get(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria/exportar`,
          {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
            responseType: 'blob',
          }
        );
        nameFile = `categorias_${relation.relation}_${relation.id}`;
      } else {
        resp = await Api.get(`${Endpoints.Category.main}/exportar`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          responseType: 'blob',
        });
      }
      // Cria um URL temporário para o arquivo blob
      const downloadUrl = URL.createObjectURL(resp.data);

      // Cria um link e simula um clique para iniciar o download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = nameFile; // Nome do arquivo a ser baixado
      link.click();
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Products', handleErros);
      throw handleErros;
    }
  }

  static async import(file: File, relation?: IDataRelation<CategoryRelations>) {
    try {
      const formData = new FormData();
      formData.append(`categorias_importacao`, file);

      if (relation)
        return (
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/categoria/importar`,
            formData
          )
        ).data;
      else return (await Api.post(`${Endpoints.Category.main}/importar`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import Categorias', handleErros);
      throw handleErros;
    }
  }

  static async importProducts(file: File, relation?: IDataRelation<CategoryRelations>) {
    try {
      var resp;
      const formData = new FormData();
      formData.append(`categorias_importacao`, file);
      if (relation) {
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria/importar`,
          formData
        );
      } else {
        resp = await Api.post(`${Endpoints.Category.main}/importar`, formData);
      }
      return resp?.data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Import Products', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>,
    options?: CategoryExtraOptions[]
  ): Promise<IDataCategory> {
    try {
      var resp;

      if (relation)
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria`,
          data
        );
      else resp = await Api.post(`${Endpoints.Category.main}`, data);

      /*  if (this.relationNames.includes(relation?.relation as string)) {
         resp = await Api.post(
           `${Endpoints[relation?.relation].main}/${relation?.id}/categoria`,
           {
             ...data,
             options
           }
         );
       } else {
         resp = await Api.post(Endpoints.Category.main, data);
         if (relation)
           await Api.post(
             `${Endpoints[relation.relation].main}/${relation.id}/categoria/${resp.id}`
           );
       } */

      return resp.data.categoria as IDataCategory;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Categories', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      var resp;

      if (relation)
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria`,
          data
        );
      else resp = await Api.put(`${Endpoints.Category.main}/${data.id}`, data);

      /* if (this.relationNames.includes(relation?.relation as string)) {
        resp = await Api.post(
          `${Endpoints[relation?.relation].main}/${relation?.id}/categoria`,
          data
        );
      } else {
        resp = await Api.put(`${Endpoints.Category.main}/${data.id}`, data);

        if (relation)
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`
          );
      } */

      return resp.data.categoria as IDataCategory;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      var resp: IDataCategory;
      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`
          )
        ).data.categoria;
      else resp = (await Api.delete(`${Endpoints.Category.main}/${data.id}`)).data.categoria;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Categories', handleErros);
      throw handleErros;
    }
  }

  static async combo(search: string = '') {
    try {
      return (await Api.get(Endpoints.Category.combo, { params: { q: search } })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Combo Categories', handleErros);
      throw handleErros;
    }
  }

  static async duplicate(
    category_id: number,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      var resp;
      if (relation) {
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria/${category_id}/copy`
        );
      } else {
        resp = (await Api.post(`${Endpoints.Category.main}/${category_id}/copy`)).data.categoria;
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }
}
