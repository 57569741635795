import Endpoints from 'app/config/Endpoints';
import {
  MenuFields,
  IDataMenu,
  IDataMenuErrors,
  IHookFormMenu,
  IMenuPagination,
  IMenuFilter,
} from 'app/types/data/IDataMenu';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';

export default class Menu_Api extends Model_Api {
  static self = new Menu_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataMenuErrors {
    const resp: IDataMenuErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormMenu[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as MenuFields;
      const item: IHookFormMenu = {
        type: 'manual',
        nome: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(params?: IMenuFilter): Promise<IMenuPagination> {
    try {
      return (
        await (await Api.get(`${Endpoints.Menu.listAll}`, {
          params,
        })).data as IMenuPagination
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  static async listCombo(): Promise<IDataMenu[]> {
    try {
      return (
        await (await Api.get(`${Endpoints.Menu.main}/combo`)).data as IDataMenu[]
      )
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List menu', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataMenu,
  ): Promise<IDataMenu> {
    try {
      const resp = (await Api.post(Endpoints.Menu.main, data)).data as IDataMenu;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Menu', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataMenu,
  ): Promise<IDataMenu> {
    try {
      const resp = (await Api.put(`${Endpoints.Menu.main}/${data.id}`, data)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Menu', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataMenu,
  ): Promise<IDataMenu> {
    try {
      var resp: any;
      resp = (await Api.delete(`${Endpoints.Menu.main}/${data.id}`)).data;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Menu', handleErros);
      throw handleErros;
    }
  }
}
