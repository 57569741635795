import { MultiBackend, NodeModel, Tree, getBackendOptions } from '@minoru/react-dnd-treeview';
import { Box, CircularProgress, CssBaseline, Paper, ThemeProvider, styled } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Attributes_Api from 'app/api/Attributes_Api';
import { AttributeRelations, IDataAttribute } from 'app/types/data/IDataAttribute';
import { IDataPartnerAttribute } from 'app/types/data/IDataPartnerAttribute';
import React from 'react';
import { DndProvider } from 'react-dnd';
import styles from './App.module.css';
import { CustomDragPreview } from './components/CustomDragPreview';
import { CustomNode } from './components/CustomNode';
import { Placeholder } from './components/Placeholder';
import { theme } from './components/theme';
import { IDataRelation } from 'app/types/data/IData';
import { useToast } from 'app/hooks/useToast';

const ContentBox = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 1,
  zIndex: 999,
}));

interface ITreeViewAttributes {
  data: NodeModel<IDataAttribute | IDataPartnerAttribute>[];
  nodeSelected?: NodeModel<IDataAttribute | IDataPartnerAttribute>;
  loading?: boolean;
  clearSelected?: boolean;
  relation?: IDataRelation<AttributeRelations>;
  totalPages?: number;
  page?: number;
  total?: number;
  onDrop?: () => void;
  onUpdate?: (node: NodeModel<IDataAttribute | IDataPartnerAttribute>, newValue: string) => void;
  onClickNode: (node: NodeModel<IDataAttribute | IDataPartnerAttribute>) => void;
  changePage?: (value: number) => void;
}

export default function TreeViewAttributes(props: ITreeViewAttributes) {
  const [treeData, setTreeData] = React.useState<
    NodeModel<IDataAttribute | IDataPartnerAttribute>[]
  >(props.data);
  const [selectedNode, setSelectedNode] = React.useState<NodeModel<
    IDataAttribute | IDataPartnerAttribute
  > | null>(null);
  const [page, setPage] = React.useState<number>(1);

  const { showToast } = useToast();

  const handleDrop = async (newTree: NodeModel<IDataAttribute>[], options) => {
    const { dragSourceId, dropTargetId, dragSource } = options;
    try {
      const subTree = newTree.filter((node) => node.parent === dropTargetId);
      const newIndex = subTree.findIndex((node) => node.id === dragSourceId);
      await Attributes_Api.update(
        {
          ...dragSource.data,
          order: newIndex + 1,
          classificacao: newIndex + 1,
          parent_id: dropTargetId || null,
        },
        props.relation
      );
      setTreeData(newTree);
      if (props.onDrop) props.onDrop();
    } catch (error: any) {
      if (error.errors.message) {
        showToast({ message: error.errors.message, severity: 'error', open: true });
      } else {
        showToast({
          message: 'Não foi possível atualizar o atributo.',
          severity: 'error',
          open: true,
        });
      }
    }
  };

  React.useEffect(() => {
    if (props.nodeSelected) setSelectedNode(props.nodeSelected);
  }, [props.nodeSelected]);

  React.useEffect(() => {
    if (props.clearSelected) setSelectedNode(null);
  }, [props.clearSelected]);

  React.useEffect(() => {
    setTreeData(props.data);
  }, [props.data]);
  React.useEffect(() => {
    const itemPage = props.page || 0
    setPage(itemPage + 1)
  }, [props.page]);

  function handleSelect(node: NodeModel<IDataAttribute>) {
    setSelectedNode(node);
    props.onClickNode(node);
  }

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.app}>
            {props.loading && (
              <ContentBox>
                <CircularProgress color="primary" />
              </ContentBox>
            )}
            <Tree
              tree={treeData}
              rootId={0}
              render={(
                node: NodeModel<IDataAttribute | IDataPartnerAttribute>,
                { depth, isOpen, onToggle }
              ) => (
                <CustomNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={onToggle}
                  onSelect={handleSelect}
                  isSelected={node.id === selectedNode?.id}
                  onUpdate={(value) => (props.onUpdate ? props.onUpdate(node, value) : undefined)}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps} />
              )}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                placeholder: styles.placeholderContainer,
              }}
              sort={false}
              insertDroppableFirst={false}
              canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                if (dragSource?.parent === dropTargetId) {
                  return true;
                }
              }}
              dropTargetOffset={10}
              placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
            />
          </div>

          <Box display="flex" justifyContent="end">
            <Stack spacing={2}>
              <Pagination showFirstButton showLastButton count={props.totalPages} page={page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                if (props.changePage) {
                  props.changePage(value - 1)
                }
              }} />
            </Stack>
          </Box>
        </DndProvider>
      </ThemeProvider>
    </Paper>
  );
}
