import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import CategoryAttribute from '../curated/category_attribute';
import ProductImages from '../curated/product/product_images';
import ProductTree from '../curated/productTree';
import Segmentation from '../curated/segmentation';
import SegmentationModel from '../curated/segmentation_model';
import SegmentationTypes from '../curated/segmentation_types';
import Segments from '../curated/segments';
import SellerChannels from '../curated/seller_channels';
import ServiceType from '../curated/service_type';
import StoreStatus from '../curated/store_status';
import VirtualShowcase from '../portal/virtual_showcase';
import ReasonType from '../curated/reason_type';
import Workflow from '../partners/workflow';
import Reasons from '../curated/reasons';
import Sku from '../curated/sku';

const UnderConstructionPage = Loadable(
  lazy(() => import('app/components/FeedbackPages/UnderConstructionPage'))
);

const MenuBuilder = Loadable(lazy(() => import('./menu-builder')));
const FlowMenu = Loadable(lazy(() => import('./flowMenu')));
const User = Loadable(lazy(() => import('./user')));
const Settings = Loadable(lazy(() => import('./settings')));
const UserGroup = Loadable(lazy(() => import('./user-group')));
const QuickShortcut = Loadable(lazy(() => import('./quickAccess')));
const Orientation = Loadable(lazy(() => import('../curated/orientation')));
const Unit = Loadable(lazy(() => import('../curated/unit')));
const Project = Loadable(lazy(() => import('../partners/project')));
const Integrators = Loadable(lazy(() => import('../partners/integrators')));
const Systems = Loadable(lazy(() => import('../partners/systems')));
const Marketplace = Loadable(lazy(() => import('../partners/marketplace')));
const Brand = Loadable(lazy(() => import('../curated/brand')));
const Categories = Loadable(lazy(() => import('../curated/category')));
const Attributes = Loadable(lazy(() => import('../curated/attribute')));
const Products = Loadable(lazy(() => import('../curated/product')));
const Stores = Loadable(lazy(() => import('../partners/stores')));
const Sellers = Loadable(lazy(() => import('../partners/sellers')));
const Shoppings = Loadable(lazy(() => import('../partners/shopping')));
const Partners = Loadable(lazy(() => import('../partners/partners')));

const AcessoRapido = Loadable(lazy(() => import('./acesso-rapido')));

const managementRoutes = [
  { path: '/gestao/fluxo-menu/:id', element: <FlowMenu />, auth: authRoles.admin },
  { path: '/gestao/construtor-de-menu', element: <MenuBuilder />, auth: authRoles.admin },
  { path: '/gestao/usuarios', element: <User />, auth: authRoles.admin },
  { path: '/gestao/parametros', element: <Settings />, auth: authRoles.admin },
  { path: '/gestao/acesso-rapido', element: <QuickShortcut />, auth: authRoles.admin },
  { path: '/gestao/grupo-usuarios', element: <UserGroup />, auth: authRoles.admin },
  {
    path: '/curadoria/cadastros-especiais/orientacoes',
    element: <Orientation />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/categorias',
    element: <Categories />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/atributos',
    element: <Attributes />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/marcas',
    element: <Brand />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/unidades',
    element: <Unit />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/canais-de-vendas',
    element: <SellerChannels />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/biblioteca-arvore',
    element: <Segmentation />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/modelo-segmentacao',
    element: <SegmentationModel />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/segmentos',
    element: <Segments />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/tipos-segmentacao',
    element: <SegmentationTypes />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/tipo-servico',
    element: <ServiceType />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/tipos-motivo',
    element: <ReasonType />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/motivos',
    element: <Reasons />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/cadastros-especiais/status-loja',
    element: <StoreStatus />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/catalogo-produtos',
    element: <Products />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/skus',
    element: <Sku />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/arvore-de-categorias',
    element: <ProductTree />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/produtos-imagens',
    element: <ProductImages />,
    auth: authRoles.admin,
  },
  {
    path: '/curadoria/categorias',
    element: <CategoryAttribute />,
    auth: authRoles.admin,
  },
  /* {
    path: '/curadoria/produtos-parceiros',
    element: <ProductPartners />,
    auth: authRoles.admin,
  }, */
  {
    path: '/parceiros/projetos',
    element: <Project />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/integradores',
    element: <Integrators />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/sistemas',
    element: <Systems />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/marketplace',
    element: <Marketplace />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/lojas',
    element: <Stores />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/sellers-center',
    element: <Sellers />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/shoppings',
    element: <Shoppings />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/clientes',
    element: <Partners />,
    auth: authRoles.admin,
  },
  {
    path: '/parceiros/workflow',
    element: <Workflow />,
    auth: authRoles.admin,
  },
  {
    path: '/portal/vitrine-virtual',
    element: <VirtualShowcase />,
    auth: authRoles.admin,
  },
  {
    path: '/pagina-em-construcao',
    element: <UnderConstructionPage />,
    auth: authRoles.admin,
  },
  {
    path: '/acesso-rapido-interno/:id',
    element: <AcessoRapido />,
    auth: authRoles.admin,
  }
];

export default managementRoutes;
