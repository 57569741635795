import React from 'react';
import { Card, CardActions, IconButton, styled, useTheme } from '@mui/material';
import {
  SortEnd,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { IDataAttachment } from 'app/types/data/IDataAttachment';
import { Delete, DragIndicator } from '@mui/icons-material';

const CardMedia = styled(Card)(({ theme }) => ({
  position: 'relative',
  cursor: 'default',
  border: '1px solid #EEE',
  padding: theme.spacing(1),
  width: 160,
  height: 160,
  '>img': {
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },
  ':hover': {
    img: {
      opacity: 0.5,
    },
    '.card_action': {
      display: 'flex',
    },
  },
  '.card_action': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'none',
    justifyContent: 'space-between',
  },
}));

const GrabIcon = SortableHandle(styled(IconButton)(({ theme }) => ({})));

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  gap: '8px',
}));

const DroppableArea = styled('div')(({ theme }) => ({
  width: '100%',
  flexWrap: 'wrap',
  overflow: 'auto',
  '>div': {
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'auto',
    maxHeight: 616,
  },
}));

const SortableItem: React.ComponentClass<
  SortableElementProps & { item: IDataAttachment; onDelete: (image: IDataAttachment) => void },
  any
> = SortableElement(
  ({ item, onDelete }: { item: IDataAttachment; onDelete: (image: IDataAttachment) => void }) => (
    <div style={{ margin: '8px' }}>
      <CardMedia>
        <img
          src={item.original_url}
          alt={`Imagem ${item.file_name}`}
          style={{ maxWidth: 200, height: 'auto' }}
        />
        <CardActions className="card_action" disableSpacing>
          <GrabIcon>
            <DragIndicator fontSize="inherit" />
          </GrabIcon>
          <IconButton aria-label="delete" onClick={() => onDelete(item)}>
            <Delete fontSize="inherit" color="error" />
          </IconButton>
        </CardActions>
      </CardMedia>
    </div>
  )
);

const SortableList: React.ComponentClass<
  SortableContainerProps & { items: IDataAttachment[]; onDelete: (image: IDataAttachment) => void },
  any
> = SortableContainer(
  ({
    items,
    onDelete,
  }: {
    items: IDataAttachment[];
    onDelete: (image: IDataAttachment) => void;
  }) => {
    return (
      <Container>
        {items.map((item, index) => (
          <SortableItem key={item.uuid} index={index} item={item} onDelete={onDelete} />
        ))}
      </Container>
    );
  }
);

export interface IDisplayMediaCards {
  medias: IDataAttachment[];
  loading?: boolean;
  onDragEnd?: (image: IDataAttachment, origin: number, destination: number) => void;
  onDelete?: (image: IDataAttachment) => void;
}

const DisplayMediaCards = (props: IDisplayMediaCards) => {
  const theme = useTheme();
  const [medias, setMedias] = React.useState<IDataAttachment[]>(props.medias);

  React.useEffect(() => setMedias(props.medias), [props.medias]);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const updatedImages = arrayMove([...medias], oldIndex, newIndex);
    setMedias(updatedImages);

    if (props.onDragEnd) {
      const imageDrag = medias[oldIndex];
      props.onDragEnd(imageDrag, oldIndex, newIndex);
    }
  };

  const handleDelete = (image: IDataAttachment) => {
    if (props.onDelete) props.onDelete(image);
  };

  return (
    <DroppableArea
      sx={props.loading ? { background: theme.palette.background.paper, opacity: 0.5 } : undefined}
    >
      <SortableList
        items={medias}
        onDelete={handleDelete}
        onSortEnd={onSortEnd}
        axis="xy"
        useDragHandle
        //lockAxis="x"
      />
    </DroppableArea>
  );
};

export default DisplayMediaCards;
