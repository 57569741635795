const hiddenColumns = (displayProduct = true) => ({
  produto: displayProduct,
  projeto_id: false,
  short_name: false,
  nr_ean: false,
  ds_produto: false,
  ficha_tecnica: false,
  vl_precode: false,
  vl_precopor: true,
  nr_estoque: true,
  nr_estoque_minimo: true,
  nr_largura: false,
  nr_altura: false,
  nr_profundidade: false,
  nr_peso: false,
  nr_altura_logistica: false,
  nr_largura_logistica: false,
  nr_profundidade_logistica: false,
  nr_peso_logistica: false,
  vitrine_virtual: false,
  tags: false,
  status_umami: false,
  integration_status: false,
  integration_situation: false,
  quality_status: false,
  integrated_at: false,
  curador_id: false,
  integrador_id: false,
  create_user_id: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
  create_user: false,
  update_user: false,
  integrador: false,
  integrador_code: false,
  created_at: false,
});

export default hiddenColumns;
