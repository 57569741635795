import SelectEditInputCell from 'app/components/Inputs/EditInputCell/EditInputCell';
import { IRenderSelectEditInputCell } from 'app/types/data/IColumns';

/**
 * A função `renderSelectEditInputCell` permite renderizar um input de seleção personalizado dentro de uma célula editável.
 * Ela renderiza um componente de input de seleção dinâmico. Se um componente assíncrono (asyncSelect) for fornecido,
 * ele é renderizado; caso contrário, renderiza um input de seleção usando as opções fornecidas.
 *
 * @typedef {Object} FieldsToBeChanged - Descreve um campo a ser alterado em relação a outro campo.
 * @property {string} field - O campo a ser alterado.
 * @property {string} relativeTo - O campo em relação ao qual 'field' é relativo.
 *
 * @typedef {Object} IRenderSelectEditInputCell - Propriedades para a função renderSelectEditInputCell.
 * @property {ISelectOption[]} options - Um array de opções de seleção.
 * @property {ComponentType<any>} asyncSelect - Um componente de seleção assíncrona.
 * @property {IDataRelation<unknown>} relation - Uma relação de dados.
 * @property {FieldsToBeChanged[]} fieldsToBeChanged - Campos a serem alterados na mudança de input assíncrono.
 * @property {...GridRenderEditCellParams} rest - Outros parâmetros de GridRenderEditCellParams.
 *
 * @param {IRenderSelectEditInputCell} props - Propriedades do componente.
 * @returns {JSX.Element} - O elemento de input de seleção renderizado.
 *
 * @example
 *
 * Exemplo de uso nas colunas de uma grid (Material-UI):
 * renderEditCell: (params) =>
 *   renderSelectEditInputCell({
 *     ...params, // ** Parametros de GridRenderEditCellParams
 *     options: [{label: 'Label', value: 1}], // ** opções para o select padrão
 *     asyncSelect: SelectUser, // ** Select Assíncrono
 *     fieldsToBeChanged: [{ field: 'assign_id', relativeTo: 'id' }], // ** assign_id irá receber o valor de id do select assíncrono
 *   })
 *
 * OBS:
 * //** Em fieldsToBeChanged, caso quiser que "field" receba o objeto em si vindo do select assíncrono, basta passar "itself" para o relativeTo. Com isso,
 * //** em vez de por exemplo assign_id ser igual a id, ele ira receber o objeto inteiro.
 * //** EXEMPLO: {...row, assign_id : {id: 1, name: 'Teste', ...outrosValores} }
 */

const renderSelectEditInputCell = ({
  options,
  asyncSelect,
  relation,
  fieldsToBeChanged,
  ...rest
}: IRenderSelectEditInputCell) => {
  return (
    <SelectEditInputCell
      options={options}
      asyncSelect={asyncSelect}
      relation={relation}
      fieldsToBeChanged={fieldsToBeChanged}
      {...rest}
    />
  );
};

export default renderSelectEditInputCell;
