import React from 'react';
import { Dialog, DialogTitle, DialogContent, styled, Breakpoint } from '@mui/material';

const DialogRoot = styled('div')(({ theme }) => ({
  '& form': {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    width: 'fit-content',
  },
  '& .formControl': {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  '& .formControlLabel': {
    marginTop: theme.spacing(1),
  },
}));

export declare interface IModal {
  children?: React.ReactNode;
  title?: string
  open: boolean
  //toggleModal?: boolean
  loading?: boolean
  maxWidth?: Breakpoint
  onClose?: () => void,
}

const Modal = ({ children, title, open, loading, maxWidth = "md", onClose }: IModal) => {
  return (
    <DialogRoot>
      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      //{...props}
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>

        <DialogContent style={{ paddingTop: 8, paddingBottom: 16 }}>{children}</DialogContent>

        {/* <DialogActions>
          <Button onClick={onClose} color="primary">
            Fechar
          </Button>
          <LoadingButton
            id="formik-form"
            onClick={handleSubmit}
            type="submit"
            color="primary"
            loading={loading}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </DialogActions> */}
      </Dialog>
    </DialogRoot>
  );
};

export default Modal;
