const UmamiLogo = ({ className }) => {
  return (
    <img
      width="48px"
      height="48px"
      className={className}
      src="/assets/images/logo.png"
      alt="Umami"
    />
  );
};

export default UmamiLogo;
