import React from 'react';
import { Box, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { IRenderSelectEditInputCell } from 'app/types/data/IColumns';

export default function SelectEditInputCell(props: IRenderSelectEditInputCell) {
  const { id, value, field, options } = props;
  const label = value?.label;
  const apiRef = useGridApiContext();
  const [selectedOption, setSelectedOption] = React.useState(label ?? value);
  const asyncSelect = props.asyncSelect;
  const fieldsToBeChanged = props.fieldsToBeChanged;

  React.useEffect(() => {
    if (typeof selectedOption === 'string')
      handleChange({ target: { value: selectedOption } }, null);
    else handleChange(null, selectedOption);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChange(event, data) {
    if (event) {
      setSelectedOption(event.target.value);
    }
    if (asyncSelect && data && fieldsToBeChanged) {
      const updatedData = fieldsToBeChanged.reduce((acc, change) => {
        const { field: fieldToChange, relativeTo } = change;
        if (relativeTo === 'itself') {
          acc[fieldToChange] = data;
        } else {
          acc[fieldToChange] = data[relativeTo];
        }
        return acc;
      }, {});

      for (const change of fieldsToBeChanged) {
        const { field } = change;
        const value = updatedData[field];
        await apiRef.current.setEditCellValue({ id, field, value });
      }
    } else if (event && options) {
      setSelectedOption(event.target.value);
      const selectedOptionObj = options.find((option) => option.label === event.target.value);
      await apiRef.current.setEditCellValue({ id, field, value: selectedOptionObj });
    } else if ((!event || !data) && fieldsToBeChanged) {
      for (const change of fieldsToBeChanged) {
        const { field } = change;
        await apiRef.current.setEditCellValue({ id, field, value: null });
      }
    }
  }

  const AsyncSelect = asyncSelect;

  return (
    <>
      {AsyncSelect ? (
        <Box width="100%" key={field}>
          <AsyncSelect
            value={props.value}
            relation={props.relation}
            onChange={(data: unknown) => handleChange(null, data)}
          />
        </Box>
      ) : (
        <Select
          key={field}
          value={selectedOption}
          onChange={handleChange}
          size="small"
          sx={{ height: 1, outline: 'none' }}
          native
          fullWidth
        >
          {options.map((option) => (
            <option key={option.value} value={option.label}>
              {option.label}
            </option>
          ))}
        </Select>
      )}
    </>
  );
}
