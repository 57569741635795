import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const WorkflowSchema = yup.object().shape({
  name: yup.string().required(FormMessages('Nome').validations.required),
  started_in: yup.date().typeError('O Valor deve ser uma data válida').nullable(),
  ends_in: yup.date().typeError('O Valor deve ser uma data válida').nullable()
    .test('maiorData', 'A data de término deve ser maior ou igual do que a data de início', function (value) {
      const { started_in } = this.parent;
      if (value)
        return value >= started_in;
      else return true;
    }),
});
