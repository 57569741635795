import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import accountRoutes from './views/account/AccountRoutes';
import managementRoutes from './views/management/ManagementRoutes';
import ViewPublicShowcaseProduct from './views/portal/virtual_showcase/components/DisplayVirtualShowcase/ViewPublicShowcaseProduct';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...managementRoutes,
      ...accountRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '/portal/vitrine-virtual/:produto', element: <ViewPublicShowcaseProduct /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
