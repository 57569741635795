import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import UserAuth_Api from '../api/UserAuth_api';
import { IDataUser } from 'app/types/data/IDataUser';
import jwtDecode from 'jwt-decode';
import { IToken } from 'app/types/IToken';
import { IDataGroupUser } from "app/types/data/IDataGroupUser";
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';

declare interface IState {
  isAuthenticated: boolean;
  isInitialised: boolean;
  isLogingOut: boolean;
  user: IDataUser | null;
  groups: IDataGroupUser[];
  menu: any;
  permissionsTags: string[];
  optionsClientes: any[],
  optionsShopping: any[],
  optionsLoja: any[],
  optionsProjeto: any[]
}

const initialState: IState = {
  isAuthenticated: false,
  isInitialised: false,
  isLogingOut: false,
  user: null,
  groups: [],
  menu: [],
  permissionsTags: [],
  optionsClientes: [],
  optionsShopping: [],
  optionsLoja: [],
  optionsProjeto: []
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const currentTime = Date.now() / 1000;
  return jwtDecode<IToken>(accessToken).exp > currentTime;
};

const setSession = (accessToken: string | null, expires?) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('expires', expires);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('expires');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isLogingOut: false,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLogingOut: false,
        user,
      };
    }
    case 'LOGOUT': {
      localStorage.clear()
      return {
        ...initialState,
        isAuthenticated: false,
        isLogingOut: true,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLogingOut: false,
        user,
      };
    }
    case 'GROUPS': {
      const { groups, permissionsTags } = action.payload;

      return {
        ...state,
        groups,
        permissionsTags
      };
    }
    case 'OPTIONS_CLIENTE': {
      const { options } = action.payload;

      return {
        ...state,
        optionsClientes: options,
      };
    }
    case 'OPTIONS_SHOPPING': {
      const { options } = action.payload;

      return {
        ...state,
        optionsShopping: options,
      };
    }
    case 'OPTIONS_LOJA': {
      const { options } = action.payload;

      return {
        ...state,
        optionsLoja: options,
      };
    }
    case 'OPTIONS_PROJETO': {
      const { options } = action.payload;

      return {
        ...state,
        optionsProjeto: options
      };
    }
    case 'MENU': {
      const { menu } = action.payload;

      return {
        ...state,
        menu,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: (email, password) => Promise.resolve(),
  logout: () => { },
  getGroups: (groups: IDataGroupUser[]) => { },
  setAtalhosMenu: (type: 'cliente' | 'loja' | 'projeto' | 'shopping', options: any[]) => { },
  setMenu: (menu: any[]) => { },
  register: () => Promise.resolve(),
  refresh: () => { },
  showMenuItem: (menuItem: any, listPermissions: string[]) => Boolean,
  showAction: (menuItem: any, listPermissions: string[]) => Boolean,
  getConfigCampo: (nomeCampo: string, itemsCampos: IDataBpmCampo[]): IDataBpmCampo => {
    return {} as IDataBpmCampo;
  }
});

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('authState', serializedState);
  } catch (e) {
    console.error("Could not save state", e);
  }
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('authState');
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.error("Could not load state", e);
    return initialState;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, loadStateFromLocalStorage());

  const login = async (email: string, password: string) => {
    const response = await UserAuth_Api.login({
      email,
      password,
    });
    const accessToken = response.access.access_token;
    const expires = response.access.expires_in;
    const user = response.user;

    setSession(accessToken, expires);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    return user
  };

  const getGroups = (groups: IDataGroupUser[]) => {
    const permissionsTags: string[] = [];
    groups.map((x: IDataGroupUser) => {
      x?.permissions?.map((perm: any) => {
        permissionsTags.push(perm.name);
      });
    });
    dispatch({
      type: 'GROUPS',
      payload: {
        groups,
        permissionsTags
      },
    });
  }
  const setAtalhosMenu = (type: 'cliente' | 'loja' | 'projeto' | 'shopping', options: any[]) => {
    const itemType = `OPTIONS_${type.toUpperCase()}`
    dispatch({
      type: itemType,
      payload: {
        options
      },
    });
  }

  const setMenu = (menu: any[]) => {
    dispatch({
      type: 'MENU',
      payload: {
        menu,
      },
    });
  }

  const showMenuItem = (menuItem, listPermissions) => {
    let show = true;
    const actions = ['view', 'list', 'delete', 'create', 'edit']
    if (menuItem.tag) {
      show = listPermissions?.some((item) => item.split(' ')[1].toLowerCase() === menuItem.tag.toLowerCase() && actions.some(action => item.startsWith(action)));
    }
    return show;
  };

  function getConfigCampo(nomeCampo: string, itemsCampos: IDataBpmCampo[]) {
    const campo = itemsCampos.find(x => x?.funcionalidade_campo?.nome?.toLowerCase() === nomeCampo.toLowerCase())
    return campo ? campo : { required: 0, hidden: 0 }
  }

  const showAction = (menuItem, listPermissions) => {
    let show = true;


    if (menuItem.tag && menuItem.action) {
      const permission = `${menuItem.action} ${menuItem.tag}`
      show = listPermissions?.some((item) => item.toLowerCase().includes(permission.toLowerCase()));
    }
    return show;
  };

  const register = async (email: string, username: string, password: string) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    await UserAuth_Api.logout();
    setSession(null);
    dispatch({
      type: 'LOGOUT',
      payload: {
        isLogingOut: true,
      },
    });
    dispatch({
      type: 'INIT',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const refresh = async () => {
    try {
      const accessToken = window.sessionStorage.getItem('accessToken');
      const expires = window.sessionStorage.getItem('expires');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, expires);
        const response = await UserAuth_Api.isUserValid();
        const { user } = response;

        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INIT',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.sessionStorage.getItem('accessToken');
        const expires = window.sessionStorage.getItem('expires');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, expires);
          const response = await UserAuth_Api.isUserValid();
          const { user } = response;

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user,
            },
          });

          window.addEventListener('beforeunload', (event) => {
            logout();
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  useEffect(() => {
    saveStateToLocalStorage(state);
  }, [state])

  if (!state.isInitialised || state.isLogingOut) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        showMenuItem,
        showAction,
        getConfigCampo,
        getGroups,
        setAtalhosMenu,
        setMenu,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
