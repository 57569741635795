import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import Categories_Api from 'app/api/Categories_Api';
import CatalogProductContext from 'app/contexts/CatalogProduct';
import ProjectContext from 'app/contexts/ProjectContext';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryExtraOptions,
  CategoryRelations,
  IDataCategory,
  IDataCategoryErrors,
} from 'app/types/data/IDataCategory';
import React, { useContext } from 'react';
import TimedTextField from '../../TimedTextField/TimedTextField';
import Option from '../Option/Option';

export interface ISelectCategories {
  id?: string;
  value?: IDataCategory;
  disabled?: boolean;
  error?: string;
  onChange?: (categorie?: number[], data?: IDataCategory[]) => void;
  onChangeExtraOptions?: (options: CategoryExtraOptions[]) => void;
  label?: string;
  segmentacao_id?: number;
  relation?: IDataRelation<CategoryRelations>;
}

const to_keep_options = [
  { name: 'Criar cópia', value: 'copia' },
  { name: 'Copiar Atributos', value: 'atributos' },
];

const SelectCategories = (props: ISelectCategories) => {
  const projectCtx = useContext(ProjectContext);
  const productCtx = useContext(CatalogProductContext);

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataCategory>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataCategory>[]>([]);
  const [errors, setErros] = React.useState(props.error);

  const [toKeep, setToKeep] = React.useState<CategoryExtraOptions[]>(
    to_keep_options.map<CategoryExtraOptions>((obj) => obj.value as CategoryExtraOptions)
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      load();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, props.segmentacao_id]);

  React.useEffect(() => {
    if (props.onChangeExtraOptions) props.onChangeExtraOptions(toKeep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toKeep]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataCategory>[] = props.value
      ? [
        {
          label: props.value?.name,
          value: props.value?.id as number,
          data: props.value,
        },
      ]
      : [];
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await Categories_Api.list(
        {
          q: search,
          page: 1,
          per_page: 10,
          order: 'asc',
          sort: 'id',
          //projeto_id: (projectCtx?.id as number) || undefined,
          is_null: productCtx?.id
            ? 'parent_id'
            : !projectCtx
              ? ['parent_id', 'projeto_id']
              : 'parent_id',
          segmentacao_id: props.segmentacao_id,
        },
        props.relation
      );

      const combo: ISelectOption<IDataCategory>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.nm_categoria,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataCategoryErrors | unknown) {
      if (error) {
        const err = error as IDataCategoryErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != 403) setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Autocomplete
        id={props.id}
        multiple
        noOptionsText="Nenhuma categoria encontrada"
        options={comboData}
        defaultValue={comboOption}
        value={comboOption}
        loading={loading}
        limitTags={4}
        onClose={() => setSearch('')}
        disabled={props.disabled}
        //disableCloseOnSelect
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option) {
            const categories = option?.map((cat) => cat.data);
            const ids = option.map((item) => item.data?.id as number).filter(Boolean);
            setComboOption(option);
            if (props.onChange) props.onChange(ids, categories as IDataCategory[]);
          } else {
            setComboOption([]);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Option
              key={option.value}
              props={props}
              option={option}
              secondary={option.data?.alias || undefined}
            />
          </li>
        )}
        renderInput={(params) => (
          <TimedTextField
            {...params}
            label={
              props.label || `Categorias ${productCtx && projectCtx ? 'do projeto' : 'Agnósticas'}`
            }
            placeholder={`Pesquisar uma Categoria ${productCtx && projectCtx ? 'do projeto' : 'da base agnóstica'
              }`}
            type="text"
            size="small"
            timer={300}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              ...params.InputProps,
              /* startAdornment: (
                <InputAdornment position="start">
                  <Category />
                </InputAdornment>
              ), */
            }}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors}
              </Typography>
            }
          />
        )}
      />
      {comboOption && props.onChangeExtraOptions && (
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={toKeep?.includes(option.value as CategoryExtraOptions)}
                      onChange={() => {
                        if (toKeep?.includes(option.value as CategoryExtraOptions)) {
                          const filteredOptions = toKeep.filter((opt) => opt !== option.value);
                          setToKeep(filteredOptions);
                        } else {
                          setToKeep([
                            ...(toKeep as CategoryExtraOptions[]),
                            option.value as CategoryExtraOptions,
                          ]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SelectCategories;
