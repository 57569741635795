import { styled } from '@mui/material';
import Breadcrumbs from 'app/components/Breadcrumbs';
import DisplayProductImages from './components/DisplayProductImages';

const ContentBox = styled('div')(({ theme }) => ({
 margin: '40px',
 [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const ProductImages = () => {
 return (
  <>
   <div>
    <Breadcrumbs />
   </div>
   <ContentBox>
    <DisplayProductImages />
   </ContentBox>
  </>
 );
};

export default ProductImages;
