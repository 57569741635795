import React from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import Products_Api from 'app/api/Products_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import {
  IDataOriginProdutct,
  IDataProduct,
  IDataProductErrors,
  ProductExtraOptions,
  ProductRelations,
} from 'app/types/data/IDataProduct';
import Option from '../Option/Option';
import { QrCode } from '@mui/icons-material';
import TimedTextField from '../../TimedTextField/TimedTextField';
import { IDataRelation } from 'app/types/data/IData';

export interface ISelectProduct {
  id?: string;
  value?: IDataProduct | IDataOriginProdutct | null;
  label?: string;
  disabled?: boolean;
  error?: string;
  relation?: IDataRelation<ProductRelations>;
  onChange?: (product?: IDataProduct) => void;
  onChangeExtraOptions?: (options: ProductExtraOptions[]) => void;
}

const to_keep_options = [
  { name: 'Skus', value: 'skus' },
  { name: 'Atributos', value: 'atributos' },
  { name: 'Categorias', value: 'categorias' },
  { name: 'Orientações', value: 'orientacoes' },
];

const SelectProduct = (props: ISelectProduct) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataProduct>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataProduct> | undefined>(
    undefined
  );
  const [errors, setErros] = React.useState(props.error);

  const [toKeep, setToKeep] = React.useState<ProductExtraOptions[]>(
    to_keep_options.map<ProductExtraOptions>((obj) => obj.value as ProductExtraOptions)
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!loading)
        load();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (props.onChangeExtraOptions) props.onChangeExtraOptions(toKeep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toKeep]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataProduct> | undefined = props.value
      ? {
        label: props.value?.nm_produto,
        value: props.value?.id as number,
        data: props.value,
      }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      const items = await Products_Api.list({ q: search }, props.relation);

      const combo: ISelectOption<IDataProduct>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.nm_produto,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataProductErrors | unknown) {
      if (error) {
        const err = error as IDataProductErrors;
        setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Autocomplete
        id={props.id}
        key={comboOption?.data?.id}
        noOptionsText="Nenhum produto encontrado"
        options={comboData}
        defaultValue={comboOption ? comboOption : undefined}
        value={comboOption}
        loading={loading}
        onClose={() => setSearch('')}
        disabled={props.disabled}
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option && option.data) {
            setComboOption(option);
            if (props.onChange) props.onChange(option.data);
          } else {
            setComboOption(undefined);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(props, option) => (
          <Option key={option.value} props={props} option={option} />
        )}
        renderInput={(params) => (
          <TimedTextField
            {...params}
            label={props.label || "Produto"}
            placeholder="Pesquisar um produto"
            type="text"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <QrCode />
                </InputAdornment>
              ),
            }}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors || props.error}
              </Typography>
            }
          />
        )}
      />
      {comboOption && props.onChangeExtraOptions && (
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={toKeep?.includes(option.value as ProductExtraOptions)}
                      onChange={() => {
                        if (toKeep?.includes(option.value as ProductExtraOptions)) {
                          const filteredOptions = toKeep.filter((opt) => opt !== option.value);
                          setToKeep(filteredOptions);
                        } else {
                          setToKeep([
                            ...(toKeep as ProductExtraOptions[]),
                            option.value as ProductExtraOptions,
                          ]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SelectProduct;
