import { Alert, AlertColor, AlertPropsVariantOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

declare interface IAlertMessage {
  title?: string;
  severity?: AlertColor;
  variant?: OverridableStringUnion<'standard' | 'filled' | 'outlined', AlertPropsVariantOverrides>;
  message: string;
}

const AlertMessage = (props: IAlertMessage) => (
  <Alert sx={{ mb: 3 }} severity={props.severity || 'error'} variant={props.variant || 'filled'}>
    {props.title && <strong>{props.title} | </strong>}
    {props.message}
  </Alert>
);

export default AlertMessage;
