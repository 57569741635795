import Activity_Api from 'app/api/Activity_Api';
import { useKanban } from 'app/contexts/Kanban/KanbanStepsContext';
import { IKanbanData } from 'app/types/IKanban';
import { IDataRelation } from 'app/types/data/IData';
import {
  ActivityRelations,
  IDataActivity,
  IDataActivityErrors,
} from 'app/types/data/IDataActivity';
import React from 'react';
const _ = require('lodash');

interface Props {
  relation?: IDataRelation<ActivityRelations>;
}
const columns = [
  'A iniciar',
  'Em andamento',
  'Aguardando retorno',
  'Reagendado',
  'Concluída',
  'Pausada',
  'Cancelada',
];

function groupDataByStatus(data): IKanbanData[][] {
  const sortedData: IKanbanData[] = _.orderBy(
    data,
    ['content.position', 'content.start_date', 'content.end_date', 'content.id'],
    ['asc', 'asc', 'asc', 'asc']
  );

  const groupedData = _.groupBy(sortedData, (item) => {
    const status = _.get(item, 'status', '');
    return columns.indexOf(status);
  });

  const result = columns.map((status) => groupedData[columns.indexOf(status)] || []);
  return result;
}

interface WorkflowActivitiesResult {
  data: IDataActivity[];
  status: string;
  errors?: string;
  filteredForKanban: IKanbanData[][];
  fetchWorkflowActivities: () => void;
}

const useWorkflowActivities = ({ relation }: Props): WorkflowActivitiesResult => {
  const { currentStep, setFetchingStepDataStatus, cachedData, setCachedData } = useKanban();
  const [data, setData] = React.useState<IDataActivity[]>([]);
  const [filteredForKanban, setFilteredForKanban] = React.useState<IKanbanData[][]>([]);
  const [status, setStatus] = React.useState('idle');
  const [errors, setErrors] = React.useState<string>();

  const workflowPhaseFilter = {
    stage_id: currentStep?.data?.content?.id as number,
  };

  async function fetchWorkflowActivities() {
    if (Boolean(currentStep)) {
      setStatus('pending');
      setFetchingStepDataStatus('pending');
      try {
        const items = await Activity_Api.list(
          { q: '', per_page: 9999, ...workflowPhaseFilter },
          relation
        );
        const filtered = items?.data?.map((item) => ({
          id: item.id as number,
          status: item.status,
          content: { ...item },
        }));
        const grouped = groupDataByStatus(filtered);
        setFilteredForKanban(grouped);
        setCachedData((prev) => [
          ...prev,
          { stage_id: workflowPhaseFilter.stage_id, kanban: grouped },
        ]);
        setData(items.data);
        setFetchingStepDataStatus('resolved');
        setStatus('rejected');
      } catch (error: IDataActivityErrors | unknown) {
        setStatus('rejected');
        setFetchingStepDataStatus('rejected');
        if (error) {
          const err = error as IDataActivityErrors;
          setErrors(err.errors?.message);
        }
      }
    }
  }

  React.useEffect(() => {
    fetchWorkflowActivities();
  }, [currentStep]);

  return { data, status, errors, filteredForKanban, fetchWorkflowActivities };
};

export default useWorkflowActivities;
