import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  ReasonTypeFields,
  IDataReasonType,
  IDataReasonTypeErrors,
  IHookFormReasonType,
  ReasonTypeRelations,
  IReasonTypePagination,
  IReasonTypeFilter,
} from 'app/types/data/IDataReasonType';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class ReasonType_Api extends Model_Api {
  static self = new ReasonType_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataReasonTypeErrors {
    const resp: IDataReasonTypeErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormReasonType[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as ReasonTypeFields;
      const item: IHookFormReasonType = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IReasonTypeFilter,
    relation?: IDataRelation<ReasonTypeRelations>
  ): Promise<IReasonTypePagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/tipo_motivo`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IReasonTypePagination;
      else
        return (
          await Api.get(Endpoints.ReasonType.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IReasonTypePagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataReasonType): Promise<IDataReasonType> {
    try {
      return (await Api.get(`${Endpoints.ReasonType.main}/${data.id}`)).data as IDataReasonType;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataReasonType,
    relation?: IDataRelation<ReasonTypeRelations>
  ): Promise<IDataReasonType> {
    try {
      const resp = (await Api.post(Endpoints.ReasonType.main, data)).data
        .tipo_motivos as IDataReasonType;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/tipo_motivo/${resp.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataReasonType,
    relation?: IDataRelation<ReasonTypeRelations>
  ): Promise<IDataReasonType> {
    try {
      const resp = (await Api.put(`${Endpoints.ReasonType.main}/${data.id}`, data)).data
        .tipo_motivos;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/tipo_motivo/${data.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataReasonType,
    relation?: IDataRelation<ReasonTypeRelations>
  ): Promise<IDataReasonType> {
    try {
      var resp: IDataReasonType;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/tipo_motivo/${data.id}`
          )
        ).data.tipo_motivos;
      else resp = (await Api.delete(`${Endpoints.ReasonType.main}/${data.id}`)).data.tipo_motivos;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataReasonType
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.ReasonType.main}/${model.id}/attachments`
            : `/media${Endpoints.ReasonType.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List ReasonType', handleErros);
      throw handleErros;
    }
  }

  static async attach(tipo_motivo: IDataReasonType, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.ReasonType.main}/${tipo_motivo.id}/attach`, formData))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ReasonTypes', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.ReasonType.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create ReasonTypes', handleErros);
      throw handleErros;
    }
  }
}
