import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { IDataCategory } from 'app/types/data/IDataCategory';
import { IDataUser } from 'app/types/data/IDataUser';
import { formatDateBR } from 'app/utils/format';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'nivel',
    headerName: 'Nível',
    flex: 0.25,
    editable: false,
    //type: 'number',
    filterable: false,
  },
  {
    field: 'nm_categoria',
    headerName: 'Categoria',
    flex: 0.5,
    editable: false,
  },
  {
    field: 'parent',
    headerName: 'Categoria Pai',
    hideable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataCategory>) =>
      params.value ? params.value.nm_categoria : '',
  },
  {
    field: 'hierarquia',
    headerName: 'Árvore',
    flex: 1,
    editable: false,
    filterable: false,
  },
  /* {
    field: 'projeto_id',
    headerName: 'ID do Projeto',
    hideable: true,
    filterable: false,
  }, */
  {
    field: 'classificacao',
    headerName: 'Classificação',
    hideable: true,
  },
  {
    field: 'parent_id',
    headerName: 'ID do Pai',
    hideable: true,
    filterable: false,
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

export default columns;
