import Reasons_Api from 'app/api/Reasons_Api';
import AttachmentModal, { IAttachmentModal } from 'app/components/Modal/AttachmentModal';
import Toast from 'app/components/Toast';
import { IDataErrors } from 'app/types/data/IData';
import { IDataReasons, IDataReasonsErrors } from 'app/types/data/IDataReasons';
import { getMessage } from 'app/utils/messages';
import React from 'react';

declare interface ICreateAttachment extends IAttachmentModal {
  data: IDataReasons;
  onUpload?: (sellerChannels: IDataReasons) => void;
}

const CREATE_MSG = getMessage('Anexo', 'create');

const CreateAttachment = (props: ICreateAttachment) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clean, setClean] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IDataErrors | undefined>(undefined);

  React.useEffect(() => setOpen(props.open), [props.open]);

  function handleClose() {
    setOpenToast(false);
  }

  async function sendAttachments(files: File[]) {
    setErrors(undefined);
    setLoading(true);
    try {
      const newReasons = await Reasons_Api.attach(props.data, files);
      setClean(true);
      setOpenToast(true);
      handleCancel();
      if (props.onUpload) props.onUpload(newReasons);
    } catch (error: IDataReasonsErrors | unknown) {
      console.log('error', error);
      if (error) {
        const err = error as IDataReasonsErrors;
        if (err.errors) setErrors(err.errors);
      }
    } finally {
      setLoading(false);
      setClean(false);
    }
  }

  function handleCancel() {
    setErrors(undefined);
    setOpen(false);
    if (props.onCancel) props.onCancel();
    if (props.onClose) props.onClose();
  }

  return (
    <>
      <AttachmentModal
        errors={errors}
        loading={loading}
        clearFiles={clean}
        open={open}
        onClose={() => {
          setErrors(undefined);
          props.onClose && props.onClose();
        }}
        onCancel={handleCancel}
        onSubmit={sendAttachments}
      />
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default CreateAttachment;
