import Endpoints from "app/config/Endpoints";
import { IDataUser, IDataUserErrors, IHookFormUsers, UserFields } from "app/types/data/IDataUser";
import Model_Api, { IDataErrors } from "./Model_Api";
import { AxiosError } from "axios";
import Api from "./api";

export default class Profile_Api extends Model_Api {

 static self = new Profile_Api();

 protected handleErrors(error: AxiosError | Error | string | any): IDataUserErrors {
  const resp: IDataErrors<IDataUserErrors> = this.formatErrors(error);
  const errors: IDataUserErrors = resp.errors
  const hookErrors: IHookFormUsers[] = []

  Object.keys(errors).forEach((key) => {
   const name = key as UserFields
   const item: IHookFormUsers = {
    type: "manual",
    name: name,
    message: errors[key][0]
   };
   hookErrors.push(item)
  });

  errors.hookForm = hookErrors

  return errors

 }

 static async avatar(avatar: File): Promise<IDataUser | IDataUserErrors> {
  try {
   const formData = new FormData();
   formData.append('avatar', avatar);

   return (await Api.post(Endpoints.Profile.avatar, formData)).data as IDataUser;
  } catch (error: AxiosError | any) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: Upload Avatar User', handleErros);
   throw handleErros
  }
 }

 static async update(data: IDataUser) {
  try {
   return (await Api.put(`${Endpoints.Profile.main}`, data)).data
  } catch (error: AxiosError | any) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: Update Profile', handleErros)
   throw handleErros
  }
 }

}