const correctedWords = {
  gestao: 'Gestão',
  usuarios: 'Usuários',
  'cadastros-especiais': 'Cadastros Especiais',
  orientacoes: 'Orientações',
  'construtor-de-menu': 'Construtor de Menu',
  'grupo-usuarios': 'Grupo de Usuários',
  'catalogo-produtos': 'Catálogo de Produtos',
  'sellers-center': 'Sellers Center',
  'produtos-parceiros': 'Produtos x Parceiros',
  'produtos-imagens': 'Produtos x Imagens',
  'arvore-de-produtos': 'Árvore de produtos',
  'arvore-de-categorias': 'Árvore de categorias',
  'canais-de-vendas': 'Canais de Vendas',
  'tipo-servico': 'Tipos de Serviço',
  'status-loja': 'Status da Loja',
  'categoria-atributo': 'Categorias X Atributos',
  'modelo-segmentacao': 'Modelo de Segmentação',
  'tipos-segmentacao': 'Tipos de Segmentação',
  'biblioteca-arvore': 'Biblioteca de Árvores',
  'vitrine-virtual': 'Vitrine Virtual',
  'tipos-motivo': 'Tipos de Motivo',
};

export default correctedWords;
