import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import styles from '../CustomDragPreview.module.css';
import { IDataAttribute } from 'app/types/data/IDataAttribute';

type Props = {
  monitorProps: DragLayerMonitorProps<IDataAttribute>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>{item?.data?.nivel}</div>
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
