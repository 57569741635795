import axios, { AxiosError } from "axios";

export interface IDataErrors<T = any> {
 errors: T
}

export default abstract class Model_Api {

 protected abstract handleErrors(error: AxiosError | Error | string | any): any

 protected formatErrors(error: AxiosError | Error): IDataErrors {
  console.error("Model_API : handleErrors >> ", error);
  if (axios.isAxiosError(error)) {
   if (error.response) {
    return error.response.data as IDataErrors;
   } else {
    return { errors: { message: error.message } } as IDataErrors;
   }
  }
  return { errors: { message: "Estamos com alguma instabilidade no sistema. Tente novamente mais tarde." } } as IDataErrors;
 }


}
