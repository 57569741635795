import React from "react";
import styles from "./tooltip.module.css";
import { Task } from "gantt-task-react";
import { formatDateBR } from "app/utils/format";

export const TooltipGantt: React.FC<{
  task: Task;
  fontSize: string;
  fontFamily: string;
}> = ({ task, fontSize, fontFamily }) => {
  const style = {
    fontSize,
    fontFamily,
  };

  return (
    <div className={styles.tooltipDefaultContainer} style={style}>
      <b style={{ fontSize: fontSize + 6 }}>{`${task.name}`}</b>
      <p className={styles.tooltipDefaultContainerParagraph}>
        {`${formatDateBR(task.start, false)} -  ${formatDateBR(task.end, false)}`}
      </p>
      {task.end.getTime() - task.start.getTime() !== 0 && (
        <p className={styles.tooltipDefaultContainerParagraph}>{`Duração: ${~~(
          (task.end.getTime() - task.start.getTime()) /
          (1000 * 60 * 60 * 24)
        )} dia(s)`}</p>
      )}

      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress && `Progresso: ${task.progress}%`}
      </p>
    </div>
  );
};