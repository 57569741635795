import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type TimedTextFieldProps = {
  timer?: number;
} & TextFieldProps;

const TimedTextField = ({ timer = 300, ...rest }: TimedTextFieldProps) => {
  const timerRef = React.useRef<any>(null);

  const handleInputChange = (event) => {
    clearTimeout(timerRef.current); // Clear previous timer

    // Set a new timer to trigger the onChange after a certain time (e.g., 500ms)
    timerRef.current = setTimeout(() => {
      if (rest.onChange) {
        rest.onChange(event);
      }
    }, timer);
  };

  return <TextField {...rest} onChange={handleInputChange} />;
};

export default TimedTextField;
