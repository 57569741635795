import * as yup from 'yup';
import { FormMessages } from '../@Messages';

export const UserSchemaCreate = yup.object().shape({
  name: yup.string().required(FormMessages('nome').validations.required),
  email: yup
    .string()
    .email(FormMessages('e-mail').validations.email)
    .required(FormMessages('e-mail').validations.required),
  password: yup
    .string()
    .required(FormMessages('senha').validations.required)
    .min(6, FormMessages('senha', 6).validations.min),
  c_password: yup
    .string()
    .required(FormMessages('confirmação de senha').validations.required)
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais'),
  registration: yup.string().nullable(),
  is_curator: yup.boolean().required(FormMessages('Curador').validations.required),
  /* c_password: yup.string().when('password', {
  is: (val) => (val && val.length > 0 ? true : false),
  then: yup.string().oneOf([yup.ref('password')], 'As senhas precisam ser iguais'),
 }), */
});

export const UserSchemaUpdate = yup.object().shape({
  name: yup.string().required(FormMessages('nome').validations.required),
  email: yup
    .string()
    .email(FormMessages('e-mail').validations.email)
    .required(FormMessages('e-mail').validations.required),
  password: yup.string().min(6, FormMessages('senha', 6).validations.min),
  c_password: yup
    .string()
    //.required(FormMessages('confirmação de senha').validations.required)
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais'),
  registration: yup.string().nullable(),
  is_curator: yup.boolean().required(FormMessages('Curador').validations.required),
});

export const UserSchemaLogin = yup.object().shape({
  email: yup
    .string()
    .email(FormMessages('e-mail').validations.email)
    .required(FormMessages('e-mail').validations.required),
  password: yup
    .string()
    .required(FormMessages('senha').validations.required)
    .min(6, FormMessages('senha', 6).validations.min),
});
