import React from 'react';
import Modal, { IModal } from 'app/components/Modal';
import { getMessage } from 'app/utils/messages';
import Toast from 'app/components/Toast';
import ImportForm from './ImportForm';
import { IDataErrors } from 'app/types/data/IData';
import AlertMessage from 'app/components/Alert';

export declare interface IAttachmentModal extends IModal {
  maxSize?: number;
  clearFiles?: boolean;
  errors?: IDataErrors;
  loading?: boolean;
  title: string;
  fileModel?: string;
  onSubmit?: (files: File[]) => void;
  onCancel?: () => void;
  data: { log_file: string; imports_fail: number; imports_success: number } | null;
}

const CsvExcelModalImport = (props: IAttachmentModal) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const CREATE_MSG = getMessage('Arquivo', 'import');

  function handleClose() {
    setOpenToast(false);
  }
  return (
    <>
      <Modal open={props.open} maxWidth="sm" onClose={props.onClose} title={props.title}>
        {props.fileModel && (
          <div style={{ marginBottom: '8px' }}>
            Clique{' '}
            <a
              style={{ color: '#007dc7' }}
              target="_blank"
              rel="noopener noreferrer"
              download
              href={props.fileModel}
            >
              aqui
            </a>{' '}
            para baixar o arquivo modelo.
          </div>
        )}

        {props.errors?.message && (
          <AlertMessage title={props.errors.status || '500'} message={props.errors.message} />
        )}
        <ImportForm
          data={props.data}
          maxSize={props.maxSize}
          loading={props.loading}
          clearFiles={props.clearFiles}
          onSubmit={props.onSubmit}
          onCancel={props.onCancel}
          errors={props.errors}
        />
      </Modal>
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default CsvExcelModalImport;
