import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
import { availableRelations, translatedKeys } from './availableRelations';

type NonEmptyArray<T> = [T, ...T[]];

type RelationsAvailableType = 'skus' | 'produtos';

interface SkuRelationsProps {
  onChange: (newRelations: Record<string, string[]>) => void;
  relationsToCopy: NonEmptyArray<RelationsAvailableType>;
}

const RelationCheckboxes = ({ onChange, relationsToCopy }: SkuRelationsProps) => {
  const [relations, setRelations] = React.useState<Record<string, string[]>>({});
  const [checkboxesState, setCheckboxesState] = React.useState<Record<string, string[]>>({});

  // Monta objeto relations baseado na array passada via props
  React.useEffect(() => {
    const resultObject: Record<string, string[]> = {};

    relationsToCopy.forEach((key) => {
      if (availableRelations.hasOwnProperty(key)) {
        resultObject[key] = availableRelations[key];

        // Implementando filtro para que não seja possível clonar skus no contexto de Sku.
        if (resultObject.hasOwnProperty('skus')) {
          resultObject['produtos'] = availableRelations.produtos.filter((item) => {
            return item !== 'sku';
          });
        }
      }
    });

    setRelations(resultObject);
    setCheckboxesState(resultObject);
  }, [relationsToCopy]);

  // Retorna as relations para o componente pai
  React.useEffect(() => {
    onChange && onChange(checkboxesState);
  }, [checkboxesState, onChange]);

  // Alterna os checkboxes
  const handleCheckboxChange = (parent: string, rel: string, checked?: boolean) => {
    setCheckboxesState((prevCheckboxState) => {
      const updatedCheckboxState = { ...prevCheckboxState };

      if (checked) {
        updatedCheckboxState[parent] = [...updatedCheckboxState[parent], rel];
      } else {
        updatedCheckboxState[parent] = updatedCheckboxState[parent].filter((item) => item !== rel);
      }

      return updatedCheckboxState;
    });
  };

  // Renderizando os checkboxes dinamicamente com base na string passada
  const renderCheckboxes = (parent: RelationsAvailableType) => {
    return relations[parent].map((rel: string) => {
      return (
        <FormControlLabel
          key={rel}
          control={
            <Checkbox
              defaultChecked
              size="small"
              checked={checkboxesState[parent].includes(rel)}
              onChange={(event) => handleCheckboxChange(parent, rel, event.target.checked)}
            />
          }
          label={translatedKeys?.[rel] || rel}
        />
      );
    });
  };

  return (
    <FormGroup sx={{ display: 'flex' }}>
      {Object.keys(relations).map((parent) => (
        <Box key={parent} mt={1}>
          <Typography variant="body2" fontWeight="bold" fontSize="1rem">
            {translatedKeys[parent]}
          </Typography>
          <FormGroup sx={{ display: 'flex' }}>
            {renderCheckboxes(parent as RelationsAvailableType)}
          </FormGroup>
        </Box>
      ))}
    </FormGroup>
  );
};

export default RelationCheckboxes;
