import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  SegmentationTypesFields,
  IDataSegmentationTypes,
  IDataSegmentationTypesErrors,
  IHookFormSegmentationTypes,
  SegmentationTypesRelations,
  ISegmentationTypesPagination,
} from 'app/types/data/IDataSegmentationTypes';
import { IParamsQs } from 'app/types/IParams';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class SegmentationTypes_Api extends Model_Api {
  static self = new SegmentationTypes_Api();
  static relationNames = ['Segmentation'];

  protected handleErrors(error: AxiosError | Error | string | any): IDataSegmentationTypesErrors {
    const resp: IDataSegmentationTypesErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSegmentationTypes[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SegmentationTypesFields;
      const item: IHookFormSegmentationTypes = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IParamsQs,
    relation?: IDataRelation<SegmentationTypesRelations>
  ): Promise<ISegmentationTypesPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/tipo_segmentacao`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentationTypesPagination;
      else
        return (
          await Api.get(Endpoints.SegmentationTypes.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as ISegmentationTypesPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSegmentationTypes): Promise<IDataSegmentationTypes> {
    try {
      return (await Api.get(`${Endpoints.SegmentationTypes.main}/${data.id}`))
        .data as IDataSegmentationTypes;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSegmentationTypes,
    relation?: IDataRelation<SegmentationTypesRelations>
  ): Promise<IDataSegmentationTypes> {
    try {
      var resp;
      if (this.relationNames.includes(relation?.relation as string)) {
        resp = (
          await Api.post(
            `${Endpoints[relation?.relation].main}/${relation?.id}/tipo_segmentacao`,
            data
          )
        ).data;
      } else {
        resp = (await Api.post(Endpoints.SegmentationTypes.main, data)).data;

        if (relation)
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/tipo_segmentacao/${resp.id}`
          );
      }
      return resp.tipo_segmentacao as IDataSegmentationTypes;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataSegmentationTypes,
    relation?: IDataRelation<SegmentationTypesRelations>
  ): Promise<IDataSegmentationTypes> {
    try {
      var resp;
      if (this.relationNames.includes(relation?.relation as string)) {
        resp = (
          await Api.post(
            `${Endpoints[relation?.relation].main}/${relation?.id}/tipo_segmentacao`,
            data
          )
        ).data;
      } else {
        resp = (await Api.put(`${Endpoints.SegmentationTypes.main}/${data.id}`, data)).data;

        if (relation)
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/tipo_segmentacao/${data.id}`
          );
      }

      return resp.tipo_segmentacao as IDataSegmentationTypes;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataSegmentationTypes,
    relation?: IDataRelation<SegmentationTypesRelations>
  ): Promise<IDataSegmentationTypes> {
    try {
      var resp: IDataSegmentationTypes;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/tipo_segmentacao/${data.id}`
          )
        ).data.tipo_segmentacao;
      else
        resp = (await Api.delete(`${Endpoints.SegmentationTypes.main}/${data.id}`)).data
          .tipo_segmentacao;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataSegmentationTypes
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.SegmentationTypes.main}/${model.id}/attachments`
            : `/media${Endpoints.SegmentationTypes.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(tipo_segmentacao: IDataSegmentationTypes, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (
        await Api.post(
          `${Endpoints.SegmentationTypes.main}/${tipo_segmentacao.id}/attach`,
          formData
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationTypess', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.SegmentationTypes.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create SegmentationTypess', handleErros);
      throw handleErros;
    }
  }
}
