import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Workflow_Api from 'app/api/Workflow_Api';
import AlertMessage from 'app/components/Alert';
import SelectUser from 'app/components/Inputs/Selects/SelectUser/SelectUsers';
import SelectWorkflow from 'app/components/Inputs/Selects/SelectWorkflow/SelectWorkflow';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataWorkflow, IDataWorkflowErrors } from 'app/types/data/IDataWorkflow';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WorkflowSchema } from '../@Schemas/WorkflowSchema';
import { IFormWorkflow } from '../@Types/IFormWorkflow';
import ProjectContext from 'app/contexts/ProjectContext';
import StoreContext from 'app/contexts/StoreContext';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataWorkflow = {
  id: null,
  name: '',
  description: '',
  started_in: null,
  ends_in: null,
  origin_id: null,
};

const UPDATE_MSG = getMessage('Workflow', 'update');
const CREATE_MSG = getMessage('Workflow', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const WorkflowForm = (props: IFormWorkflow) => {
  const projectCtx = useContext(ProjectContext);
  const storeCtx = useContext(StoreContext);

  const { resetFormList } = useUtilsContext();
  const { getConfigCampo } = useAuth();

  const [data, setData] = React.useState<IDataWorkflow>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [toKeep, setToKeep] = React.useState<string[] | undefined>(undefined);

  useEffect(() => {
    setData((props.data as IDataWorkflow) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormList?.WorkflowForm]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataWorkflow>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(WorkflowSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit(data: IDataWorkflow) {
    const campos = [{
      nome: `Workflows ${storeCtx && projectCtx ? 'do projeto' : 'Agnósticos'}`,
      campo: 'origin_id'
    },
    {
      nome: 'Nome',
      campo: 'name'
    },
    {
      nome: 'Descrição',
      campo: 'description'
    },
    {
      nome: 'Início',
      campo: 'started_in'
    },
    {
      nome: 'Responsável',
      campo: 'leader'
    },
    ]

    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required)
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    setLoading(true);
    try {
      var newData: IDataWorkflow;
      if (data.id) {
        newData = await Workflow_Api.update(data, props.relation);
      } else {
        newData = await Workflow_Api.create(data, props.relation, toKeep);
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
      if (props.onSubmit) props.onSubmit(newData);
    } catch (error: IDataWorkflowErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <FormBox id="formik-Workflow" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {props.relation && !data.id && (
            <>
              {getConfigCampo && !getConfigCampo(`Workflows ${storeCtx && projectCtx ? 'do projeto' : 'Agnósticos'}`, props.campos ?? []).hidden &&
                <Grid item xs={12} marginBottom={2}>
                  <SelectWorkflow
                    id="origin_id"
                    showToKeep
                    label={`Workflows ${storeCtx && projectCtx ? 'do projeto' : 'Agnósticos'}`}
                    placeholder={`Pesquisar um Workflow ${storeCtx && projectCtx ? 'do projeto' : 'da base agnóstica'
                      }`}
                    //value={data.Workflow}
                    relation={
                      storeCtx && projectCtx
                        ? { id: projectCtx.id as number, relation: 'Project' }
                        : undefined
                    }
                    onChange={(workflow) => {
                      if (workflow) {
                        setDataValue(
                          {
                            ...workflow,
                            id: undefined,
                            origin_id: workflow.id as number,
                          },
                          setValue
                        );
                        setData({
                          ...workflow,
                          id: undefined,
                          origin_id: workflow.id as number,
                        });
                      } else {
                        setData(initialValues);
                        setDataValue(initialValues, setValue);
                      }
                    }}
                    onChangeKeep={(keep) => setToKeep(keep)}
                  />
                </Grid>
              }
            </>
          )}

          {props.type === 'update' && (
            <Grid item xs={12} md={2}>
              <Controller
                name="id"
                control={control}
                defaultValue={data.id}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="id"
                    label="ID"
                    type="text"
                    disabled={true}
                    value={data.id}
                    autoComplete={'off'}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setData({ ...data, id: parseInt(e.target.value) });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {getConfigCampo && !getConfigCampo('Nome', props.campos ?? []).hidden &&
            <Grid item xs={12} md={props.type === 'update' ? 10 : 12}>
              <Controller
                name="name"
                control={control}
                defaultValue={data.name}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="name"
                    label="Nome*"
                    type="text"
                    value={data.name}
                    autoComplete={'off'}
                    fullWidth
                    inputProps={{
                      maxLength: 191,
                    }}
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.name?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Descrição', props.campos ?? []).hidden &&
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={data.description}
                render={({ field: { onChange } }) => (
                  <TextField
                    id="description"
                    label="Descrição*"
                    type="text"
                    value={data.description}
                    autoComplete={'off'}
                    fullWidth
                    multiline
                    rows={2}
                    inputProps={{
                      maxLength: 255,
                    }}
                    variant="outlined"
                    size="small"
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.description?.message}
                      </Typography>
                    }
                    onChange={(e) => {
                      setData({ ...data, description: e.target.value });
                      onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
          }
          {getConfigCampo && !getConfigCampo('Início', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="started_in"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Início"
                      value={data.started_in ? moment(data.started_in).toDate() : null}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, started_in: moment(newDate).toDate() });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Início"
                          id="workflow_started_in"
                          sx={{ mb: 2, width: '100%' }}
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.started_in?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Término', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="ends_in"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Término"
                      value={data.ends_in ? moment(data.ends_in).toDate() : null}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setData({ ...data, ends_in: moment(newDate).toDate() });
                      }}
                      renderInput={(props) => (
                        <TextField
                          label="Término"
                          id="mui-pickers-date"
                          sx={{ mb: 2, width: '100%' }}
                          size="small"
                          helperText={
                            <Typography color="red" variant="caption" component="span">
                              {errors.ends_in?.message}
                            </Typography>
                          }
                          {...props}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          }

          {getConfigCampo && !getConfigCampo('Responsável', props.campos ?? []).hidden &&
            <Grid item xs={12} md={4}>
              <Controller
                name="leader"
                control={control}
                defaultValue={data.leader}
                render={({ field: { onChange } }) => (
                  <SelectUser
                    id="leader_id"
                    value={data.leader}
                    label="Responsável"
                    onChange={(leader) => {
                      setValue('leader_id', leader ? leader.id : '');
                      setValue('leader', leader);
                      setData({
                        ...data,
                        leader_id: leader ? leader.id : '',
                        leader: leader,
                      });
                    }}
                  />
                )}
              />
            </Grid>
          }
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton disabled={props.editDisabled} type="submit" color="primary" loading={loading} variant="contained">
            Salvar
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default WorkflowForm;
