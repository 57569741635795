import FormContainer from 'app/components/Containers/FormContainer';
import FormReasonType from 'app/components/Forms/ReasonType';
import { IDataRelation } from 'app/types/data/IData';
import { IDataReasonType, ReasonTypeRelations } from 'app/types/data/IDataReasonType';
import React from 'react';

declare interface IReasonTypeFormContainer {
  data?: IDataReasonType;
  relation?: IDataRelation<ReasonTypeRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataReasonType) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  editDisabled?: boolean;
  typeForm?: string | undefined
}

const ReasonTypeFormContainer = (props: IReasonTypeFormContainer) => {
  const [data, setData] = React.useState<IDataReasonType | undefined>(props.data);

  React.useEffect(() => setData(props.data), [props.data]);

  return (
    <>
      <FormContainer
        key={data?.id}
        uuid={props?.uuid}
        mainForm={
          <FormReasonType
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            typeForm={props.typeForm}
          />
        }
      /* tabs={
      data && [
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={ReasonType_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
    } */
      />
    </>
  );
};

export default ReasonTypeFormContainer;
