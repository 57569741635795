import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Lens } from '@mui/icons-material';
import Attributes_Api from 'app/api/Attributes_Api';
import { AttributeRelations, IDataAttributeErrors } from 'app/types/data/IDataAttribute';
import { IDataRelation } from 'app/types/data/IData';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { useToast } from 'app/hooks/useToast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { LocalsForm } from 'app/components/Forms/@Types/IFormAttribute';
import ClearAllIcon from '@mui/icons-material/ClearAll';
// import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { LoadingButton } from '@mui/lab';
// const FormBox = styled('form')(({ theme }) => ({
//   width: '100%',
// }));

export interface ValueOption {
  id?: number;
  nm_atributo: string;
  type?: string;
}

export interface ValueOptionLong {
  parent_id: number;
  valores: ValueOption[];
}

export declare interface IAttributeSimple {
  id: number;
  nm_atributo: string;
  label?: string;
  multivalorado: boolean;
  options: ValueOption[];
  values: ValueOption[];
}

declare interface IListCardsAttributes {
  attributes: IAttributeSimple[];
  setData: React.Dispatch<React.SetStateAction<IAttributeSimple[]>>;
  relation: IDataRelation<AttributeRelations>;
  onUpdate: () => void;
}

function sortAttributes(attributes: IAttributeSimple[]): IAttributeSimple[] {

  const order = ['Obrigatório', 'Essencial', 'Complementar'];
  return attributes ? attributes.sort((a, b) => {
    const aIndex = order.indexOf(a.label || 'Complementar');
    const bIndex = order.indexOf(b.label || 'Complementar');
    return aIndex - bIndex;
  }) : [];
}

const ListCardsAttributes = (props: IListCardsAttributes) => {
  const [saving, setSaving] = useState({} as Record<number, boolean>);
  const [emptyTextoLongoCards, setEmptyTextoLongoCards] = useState<number[]>([]);
  const theme = useTheme();
  const { attributes, setData, relation, onUpdate } = props;
  const { showToast } = useToast();
  useTrailHistory({
    selected: '',
    selectedName: '',
    displayName: 'Atributos',
    toggleView: () => { },
  });





  const LabelColors = {
    Obrigatório: theme.palette.error.dark,
    Essencial: '#d6cb00',
    Complementar: theme.palette.success.main,
  };

  const handleChangeAttribute = async (
    values: ValueOption[],
    attributeParentID: number,
    valueOption?: ValueOption, // parametro para quando estiver salvando atributos do sku
    texto_longo?: boolean // parametro para quando estiver salvando atributos do tipo texto longo
  ) => {
    setSaving((prev) => ({ ...prev, [attributeParentID]: true }));
    try {
      const textoLogoData = texto_longo
        ? {
          parent_id: attributeParentID,
          valores: values,
        }
        : undefined;
      const resp = await Attributes_Api.updateAtributeValues(
        relation,
        relation.relation === 'Sku' ? valueOption?.id ?? values[0].id : values,
        textoLogoData
      );

      const newAtributes = JSON.parse(JSON.stringify(attributes));

      for (const element of values.filter(f => f['type'] == 'DEL')) {
        let index =
          newAtributes.findIndex(f => f.values.some(x => x.id == element.id));
        newAtributes[index].values = newAtributes[index].values.filter(x => x.id !== element.id);


        index =
          attributes.findIndex(f => f.values.some(x => x.id == element.id));
        attributes[index].values = attributes[index].values.filter(x => x.id !== element.id);

      }
      setData(newAtributes);


      // update attributes
      if (texto_longo) {
        const updatedAttributes = attributes.map((attribute) => {
          if (attribute.id === attributeParentID) {
            attribute.values = [{ id: resp?.[0]?.id, nm_atributo: resp?.[0]?.nm_atributo }];
          }
          return attribute;
        });
        setData(updatedAttributes);
      }
      setSaving((prev) => ({ ...prev, [attributeParentID]: false }));
    } catch (error) {
      setSaving((prev) => ({ ...prev, [attributeParentID]: false }));
      showToast({
        open: true,
        message: 'Ocorreu um erro ao salvar os atributos. Tente novamente mais tarde.',
        severity: 'error',
      });
      onUpdate();
    }
  };

  const handleChange = (
    parentAttributeId: number,
    valueOption: ValueOption,
    texto_longo?: boolean
  ) => {
    const updatedAttributes: IAttributeSimple[] = attributes.map((attribute) => {
      if (attribute.id === parentAttributeId) {
        const valueIndex = attribute.values.findIndex((item) => item.id === valueOption.id);
        if (valueIndex === -1) {
          // Se não estiver presente, adiciona o item à array values
          attribute.values.push(
            texto_longo
              ? valueOption
              : (attribute.options.find((option) => option.id === valueOption.id) as ValueOption)
          );
        } else {
          if (!texto_longo) {
            attribute.values.splice(valueIndex, 1);
          } else {
            attribute.values = [valueOption];
          }
          // Se já estiver presente, remove o item da array values
        }
      }
      return attribute;
    });

    const attributeItem: IAttributeSimple[] = updatedAttributes.filter(
      (item: IAttributeSimple) => item?.id === parentAttributeId
    );

    handleChangeAttribute(attributeItem[0]?.values, parentAttributeId, valueOption, texto_longo);
    setData(updatedAttributes);
  };

  const handleChangeRadio = (parentAttributeId: number, val: ValueOption) => {

    const updatedAttributes = attributes.map((attribute) => {
      if (attribute.id === parentAttributeId) {
        // Remove todos os itens anteriores da array values
        attribute.values = [val];
      }
      return attribute;
    });

    const attributeItem: IAttributeSimple[] = updatedAttributes.filter(
      (item: IAttributeSimple) => item?.id === parentAttributeId
    );

    handleChangeAttribute(attributeItem[0]?.values, parentAttributeId, val);

    // Atualiza o estado dos atributos
    setData(updatedAttributes);
  };


  // const handleSaveAll = () => {

  //   // const updatedAttributes = attributes.map((attribute) => {
  //   //   if (attribute.id === parentAttributeId) {
  //   //     // Remove todos os itens anteriores da array values
  //   //     attribute.values = [val];
  //   //   }
  //   //   return attribute;
  //   // });
  //   // const attributeItem: IAttributeSimple[] = updatedAttributes.filter(
  //   //   (item: IAttributeSimple) => item?.id === parentAttributeId
  //   // );

  //   // const attributeFaltantes = attributes.find((attribute) => attribute?.label === 'Obrigatório' && !attribute.values.length);
  //   // if (attributeFaltantes) {
  //   //   Swal.fire({
  //   //     title: 'Atenção!',
  //   //     icon: 'info',
  //   //     text: 'Todos os campos obrigatórios devem ser selecionados.'
  //   //   })
  //   //   return;

  //   // }

  //   try {
  //     attributesOld.map((d) => {
  //       const atributoValues = attributes.find((f) => f.id == d.id)?.values

  //       if (atributoValues?.length) {
  //         if (JSON.stringify(d.values) !== JSON.stringify(atributoValues)) {
  //           atributoValues.map((x) => {
  //             handleChangeAttribute(atributoValues, d.id, x);
  //           })
  //         }
  //       } else {
  //         if (d.values.length) {
  //           handleChangeAttribute(atributoValues as ValueOption[], d.id, {} as ValueOption);
  //         }
  //       }

  //       setAttributesOld(attributes);
  //       showToast({
  //         open: true,
  //         message: 'Atributos atualizados com sucesso.',
  //         severity: 'success',
  //       });

  //     })

  //   } catch (error) {
  //     showToast({
  //       open: true,
  //       message: 'Ocorreu um erro ao salvar os atributos. Tente novamente mais tarde.',
  //       severity: 'error',
  //     })
  //   }
  // };

  const sortLabelAlphabetically = (arr: Array<IAttributeSimple | any>) => {
    const sortByLabel = (label: string) =>
      arr
        .filter((item) => item.label === label)
        .sort((a, b) => a.nm_atributo.localeCompare(b.nm_atributo));

    const obrigatorio = sortByLabel('Obrigatório');
    const essencial = sortByLabel('Essencial');
    const complementar = sortByLabel('Complementar');

    return [...obrigatorio, ...essencial, ...complementar];
  };

  const cleanAttributes = (parentAttributeId: number) => {
    let updatedAttributes = JSON.parse(JSON.stringify(attributes.map((attribute) => {

      if (attribute.id === parentAttributeId)
        attribute.values.map(x => x['type'] = 'DEL');
      return attribute;
    })));

    handleChangeAttribute(updatedAttributes.find(x => x.id === parentAttributeId).values, parentAttributeId, {} as ValueOption);

    updatedAttributes = updatedAttributes.map(x => {

      if (x.id === parentAttributeId)
        x.values = []

      return x;
    })

    setData(updatedAttributes);
  };

  const colorCardHeader = (
    label: string,
    options: ValueOption[],
    values: ValueOption[],
    textoLongo: boolean,
    parentAttributeId: number
  ) => {
    const isRequired = label === 'Obrigatório';
    if (!isRequired) return 'transparent';
    const hasOptionsButNoValues = options?.length > 0 && !values?.length;
    const hasEmptyTextoLongo =
      textoLongo && (emptyTextoLongoCards?.includes(parentAttributeId) || !values?.length);
    const isNotSaving = !saving?.[parentAttributeId];
    const backgroundColor =
      isRequired && (hasOptionsButNoValues || hasEmptyTextoLongo) && isNotSaving
        ? '#eec7c7'
        : 'transparent';

    return backgroundColor;
  };

  return (
    <Box sx={{ width: '100%', py: 2, px: 1 }}>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        disablePadding
        component="nav"
      >
        <ListItem sx={{ width: 'initial' }} disableGutters>
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Lens sx={{ color: LabelColors['Obrigatório'] }} />
          </ListItemIcon>
          <ListItemText primary="Obrigatório" />
        </ListItem>
        <ListItem sx={{ width: 'initial' }}>
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Lens sx={{ color: LabelColors['Essencial'] }} />
          </ListItemIcon>
          <ListItemText primary="Essencial" />
        </ListItem>
        <ListItem sx={{ width: 'initial' }}>
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Lens sx={{ color: LabelColors['Complementar'] }} />
          </ListItemIcon>
          <ListItemText primary="Complementar" />
        </ListItem>
      </List>
      <Divider sx={{ mt: 0.5, mb: 2 }} />
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={2}>
        {sortLabelAlphabetically(attributes).map((attribute, index) => {
          const currentRadioCheckedItem =
            attribute?.values?.length > 1 ? attribute?.values[0] : null;
          const attributeOptions = attribute?.options?.sort((a, b) =>
            a.nm_atributo.localeCompare(b.nm_atributo)
          );
          return (
            <Box key={index} gridColumn="span 2">
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '520px',
                  position: 'relative',
                }}
              >
                {saving?.[attribute.id] && <LinearProgress />}
                <CardHeader
                  sx={{
                    py: 1,
                    bgcolor: colorCardHeader(
                      attribute?.label,
                      attributeOptions,
                      attribute?.values,
                      attribute?.texto_longo,
                      attribute.id
                    ),
                    borderBottom: '1px solid ' + theme.palette.divider,
                    '& .MuiCardHeader-action': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  title={attribute.nm_atributo}
                  titleTypographyProps={{ fontSize: '1.2rem' }}
                  action={
                    <>
                      <Lens
                        sx={{
                          mt: attribute?.label === 'Obrigatório' ? 1 : 0,
                          color: LabelColors[attribute.label || 'Complementar'],
                        }}
                      />
                      {attribute?.label !== 'Obrigatório' && !attribute?.texto_longo && (
                        <Tooltip title="Limpar">
                          <IconButton size="small" onClick={() => cleanAttributes(attribute.id)}>
                            <ClearAllIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                />
                <CardContent
                  sx={{
                    paddingTop: 1,
                    paddingBottom: '0px !important',
                    height: '100%',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  }}
                >
                  <FormControl fullWidth>
                    {attribute?.texto_longo ? (
                      <TextField
                        key={attribute.id}
                        name={attribute.nm_atributo}
                        placeholder="Informe o nome do atributo aqui..."
                        disabled={saving?.[attribute.id]}
                        defaultValue={attribute?.values[0]?.nm_atributo}
                        multiline
                        fullWidth
                        minRows={4}
                        sx={{ mb: 1 }}
                        onChange={(e) => {
                          if (e.target.value) {
                            setEmptyTextoLongoCards(
                              emptyTextoLongoCards.filter((cardId) => cardId !== attribute.id)
                            );
                          } else {
                            setEmptyTextoLongoCards([...emptyTextoLongoCards, attribute.id]);
                          }
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value !== attribute?.values[0]?.nm_atributo &&
                            e.target.value !== ''
                          ) {
                            handleChange(
                              attribute.id,
                              {
                                id: attribute?.values[0]?.id, nm_atributo: e.target.value,
                              },
                              true
                            );
                          }
                        }}
                      />
                    ) : (
                      <RadioGroup name={attribute.nm_atributo}>
                        {attributeOptions.map((val, i) => {
                          // Verifica se o valor atual está presente em attribute.values
                          const isCheckboxChecked = attribute.values.some(
                            (item) => item.id === val.id
                          );
                          const isRadioChecked = currentRadioCheckedItem
                            ? val?.id === currentRadioCheckedItem?.id
                            : attribute.values.some((item) => item.id === val.id);

                          return (
                            <FormControlLabel
                              key={i}
                              value={val.nm_atributo}
                              control={
                                attribute?.multivalorado ? (
                                  <Checkbox
                                    checked={isCheckboxChecked}
                                    onChange={() => handleChange(attribute.id, val)}
                                  />
                                ) : (
                                  <Radio
                                    size="small"
                                    checked={isRadioChecked}
                                    onChange={() => handleChangeRadio(attribute.id, val)}
                                    onClick={() => isRadioChecked && cleanAttributes(attribute.id)}
                                  />
                                )
                              }
                              label={val.nm_atributo}
                              sx={{ mb: 1 }}
                            />
                          )
                        })}
                      </RadioGroup>
                    )}
                  </FormControl>
                </CardContent>
              </Card>
            </Box>

          )
        })}
      </Box>
      {/* <FormBox id="formik-Comment" autoComplete="off">
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <LoadingButton color="primary" loading={false} variant="contained" onClick={handleSaveAll}>
            Salvar
          </LoadingButton>
        </Grid>
      </FormBox> */}
    </Box>
  );
};

declare interface IDisplayAttributesToSelect {
  relation: IDataRelation<AttributeRelations>;
  displayName: string;
  local?: LocalsForm;
  campos?: IDataBpmCampo[]
  sequency?: any
  onSubmit?: () => void;
}

const DisplayAttributesToSelect = ({
  relation,
  displayName,
  sequency,
  local,
  onSubmit
}: IDisplayAttributesToSelect) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showSequency, setShowSequency] = useState<boolean>(false);
  const [data, setData] = useState<IAttributeSimple[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const Attributes = await Attributes_Api.listAttributeValues(relation);
      const attributeFaltantes = (Attributes ?? []).some((attribute) =>
        attribute?.label === 'Obrigatório' &&
        !attribute.values.filter(x => x['type'] !== 'DEL').length &&
        attribute.options.length
      );

      if (attributeFaltantes) {

        //   // Seleciona o formulário com o ID 'formik-Product'
        //   const form = document.querySelector('#formik-Product');

        //   if (form) {
        //     // Seleciona todos os elementos que vêm depois do formulário
        //     const elementsAfterForm: HTMLElement[] = [];
        //     let currentElement = form.nextElementSibling;

        //     // Coleta todos os elementos que vêm depois do formulário
        //     while (currentElement) {
        //       elementsAfterForm.push(currentElement as HTMLElement);
        //       currentElement = currentElement.nextElementSibling;
        //     }

        //     // Procura por botões com a classe 'uiButtonBase-root' dentro desses elementos
        //     const buttons: HTMLElement[] = [];
        //     elementsAfterForm.forEach(element => {
        //       const foundButtons = element.querySelectorAll('button.MuiButtonBase-root');
        //       foundButtons.forEach(button => {
        //         buttons.push(button as HTMLElement);
        //       });
        //     });

        //     // Itera sobre os botões encontrados e faz algo com cada um deles
        //     buttons.forEach(button => {
        //       if (button.textContent === 'Atributos') {
        //         button.click();
        //       }
        //     });
        //   } else {
        //     console.log('Formulário com o ID formik-Product não encontrado');
        //   }

        // Exibe o alerta antes de permitir que o usuário saia da página
        Swal.fire({
          title: 'Atenção!',
          icon: 'info',
          text: 'Todos os campos obrigatórios devem ser selecionados.',
        });
      }
    };





    return () => {
      // Qualquer lógica de limpeza ou desmontagem pode ser colocada aqui
      fetchData(); // Chama a função assíncrona fetchData
    };
  }, []);


  useTrailHistory({
    selected: '',
    selectedName: '',
    displayName: displayName || 'Atributos',
    toggleView: () => { },
  });

  async function fetchAttributes() {
    setLoading(true);
    try {
      const Attributes = await Attributes_Api.listAttributeValues(relation);
      setData(Attributes ?? []);
    } catch (error: IDataAttributeErrors | unknown) {
      setLoading(false);
      if (error) {
        const err = error as IDataAttributeErrors;
        if (err.errors?.status && err.errors?.message) {
          //setAccessError({ code: err.errors.status, message: err.errors.message });
        }
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAttributes();

  }, []);

  React.useEffect(() => {
    const sequencyName = localStorage.getItem('sequencyName')
    if (sequencyName && sequencyName.toLowerCase().includes('atributos')) {
      setShowSequency(true);
    }

    return () => {
      const sequencyName = localStorage.getItem('sequencyName')
      if (sequencyName && sequencyName.toLowerCase().includes('atributos')) {
        localStorage.removeItem('sequencyName')
      }
    };
  }, []);

  if (loading)
    return (
      <Box width="100%" textAlign="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Toolbar
            options={{
              isLoadingGrid: loading,
              data: data,
            }}
            onClickRefresh={() => fetchAttributes()}
          />
        </Grid>
      </Grid>
      <Grid container item display="flex" flexDirection="column">
        <ListCardsAttributes
          attributes={sortAttributes(data)}
          setData={setData}
          onUpdate={() => fetchAttributes()}
          relation={relation}
        />

        {showSequency && sequency &&
          <Box display="flex" justifyContent="end">
            <LoadingButton color="primary" variant="contained" onClick={() => onSubmit ? onSubmit() : null}>
              Continuar
            </LoadingButton>
          </Box>
        }

      </Grid>
    </Grid>
  );
};

export default DisplayAttributesToSelect;
