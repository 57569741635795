import { Tooltip } from '@mui/material';
import {
  GridRenderEditCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import SelectWorkflowPhases from 'app/components/Inputs/Selects/SelectPhase/SelectPhase';
import SelectUser from 'app/components/Inputs/Selects/SelectUser/SelectUsers';
import { ISelectOption } from 'app/types/ISelectOption';
import { IDataRelation, IDuration } from 'app/types/data/IData';
import { IDataActivity } from 'app/types/data/IDataActivity';
import { IDataUser } from 'app/types/data/IDataUser';
import { IDataWorkflowPhases, WorkflowPhasesRelations } from 'app/types/data/IDataWorkflowPhases';
import { formatDateBR } from 'app/utils/format';
import renderSelectEditInputCell from 'app/utils/renderSelectEditInputCell';

export const hiddenColumns = {
  pr_activity_id: false,
  assign_id: false,
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
  description: false,
  status: true,
  priority: false,
  quality_status: false,
  assignee: false,
  performed_by: false,
  performer_id: false,
  value: false,
  progress: false,
  stage_id: false,
  tags: false,
  created_at: false,
  updated_at: false,
};

const activityStatusOptions = [
  { value: 'A iniciar', label: 'A iniciar' },
  { value: 'Em andamento', label: 'Em andamento' },
  { value: 'Aguardando retorno', label: 'Aguardando retorno' },
  { value: 'Reagendado', label: 'Reagendado' },
  { value: 'Concluída', label: 'Concluída' },
  { value: 'Pausada', label: 'Pausada' },
  { value: 'Cancelada', label: 'Cancelada' },
];
const activityQualityStatusOptions = [
  { value: 'Pré-cadastro Iniciado', label: 'Pré-cadastro Iniciado' },
  { value: 'Pré-cadastro Finalizado', label: 'Pré-cadastro Finalizado' },
  { value: 'Ajuste necessário (Umami.IA)', label: 'Ajuste necessário (Umami.IA)' },
  { value: 'Revisão Qualidade', label: 'Revisão Qualidade' },
  { value: 'Ajuste necessário (Qualidade)', label: 'Ajuste necessário (Qualidade)' },
  { value: 'Finalizado Qualidade', label: 'Finalizado Qualidade' },
];

const activityPriorityStatusOptions = [
  { value: 1, label: 'Muito Alta' },
  { value: 2, label: 'Alta' },
  { value: 3, label: 'Média' },
  { value: 4, label: 'Baixa' },
  { value: 5, label: 'Muito Baixa' },
];

interface IGetColumns {
  relation?: IDataRelation<WorkflowPhasesRelations> | null;
  hasParent?: boolean;
}

export const getColumns = ({ relation, hasParent }: IGetColumns = {}) => [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'summary',
    headerName: 'Nome',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: activityStatusOptions,
    flex: 1,
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<ISelectOption>) =>
      params.value ? params.value.label : '',
    renderEditCell: (params) =>
      renderSelectEditInputCell({ ...params, options: activityStatusOptions }),
  },
  {
    field: 'description',
    headerName: 'Descrição',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'stage_id',
    headerName: 'Etapa ID',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'stage',
    headerName: 'Etapa',
    flex: 1,
    type: 'singleSelect',
    //width: 150,
    editable: !hasParent,
    valueGetter: (params) => params?.value || '',
    valueFormatter: (params: GridValueFormatterParams<IDataWorkflowPhases>) =>
      params.value ? params.value.name : '',
    //valueGetter: (params) => (params.row ? params?.row?.stage?.name : ''),
    renderCell: (params: any) => (
      <Tooltip
        enterDelay={500}
        leaveDelay={100}
        title={params?.row?.stage?.name}
        key={`${params?.row?.stage?.name}-${params.row?.id}`}
      >
        <span>{params?.row?.stage?.name}</span>
      </Tooltip>
    ),
    renderEditCell: (params: GridRenderEditCellParams<IDataWorkflowPhases>) =>
      renderSelectEditInputCell({
        ...params,
        options: [],
        value: params.value,
        relation: relation || undefined,
        asyncSelect: SelectWorkflowPhases,
        fieldsToBeChanged: [{ field: 'stage_id', relativeTo: 'id' }],
      }),
  },
  {
    field: 'assign',
    headerName: 'Responsável',
    flex: 1,
    //width: 150,
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
    renderEditCell: (params) =>
      renderSelectEditInputCell({
        ...params,
        options: [],
        asyncSelect: SelectUser,
        fieldsToBeChanged: [{ field: 'assign_id', relativeTo: 'id' }],
      }),
  },
  {
    field: 'assign_id',
    headerName: 'Responsável ID',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'start_date',
    headerName: 'Início',
    width: 140,
    hideable: true,
    editable: true,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      params.value ? formatDateBR(params.value, true) : '-',
  },
  {
    field: 'end_date',
    headerName: 'Fim',
    width: 140,
    hideable: true,
    editable: true,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) =>
      params.value ? formatDateBR(params.value, true) : '-',
  },
  {
    field: 'duration',
    headerName: 'Duração',
    width: 150,
    editable: false,
    valueGetter: (params: GridValueGetterParams<IDuration, IDataActivity>) =>
      params.row.duration_for_humans ?? params.value,
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: 1,
    type: 'number',
    //width: 150,
    editable: true,
  },
  {
    field: 'progress',
    headerName: 'Progresso',
    flex: 1,
    type: 'number',
    //width: 150,
    editable: true,
  },
  {
    field: 'performer_id',
    headerName: 'Executante ID',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'performer',
    headerName: 'Executante',
    flex: 1,
    //width: 150,
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
    renderEditCell: (params) =>
      renderSelectEditInputCell({
        ...params,
        options: [],
        asyncSelect: SelectUser,
        fieldsToBeChanged: [{ field: 'performer_id', relativeTo: 'id' }],
      }),
  },
  {
    field: 'predecessor',
    headerName: 'Predecessor',
    flex: 1,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataActivity>) =>
      params.value ? params.value.summary : '',
  },
  {
    field: 'pr_activity_id',
    headerName: 'Predecessor ID',
    flex: 1,
    editable: false,
  },

  {
    field: 'tags',
    headerName: 'Marcadores',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'priority',
    headerName: 'Prioridade',
    type: 'singleSelect',
    valueOptions: activityPriorityStatusOptions,
    flex: 1,
    editable: true,
    renderEditCell: (params) =>
      renderSelectEditInputCell({ ...params, options: activityPriorityStatusOptions }),
  },
  {
    field: 'quality_status',
    headerName: 'Qualidade Status',
    type: 'singleSelect',
    valueOptions: activityQualityStatusOptions,
    flex: 1,
    editable: true,
    renderEditCell: (params) =>
      renderSelectEditInputCell({ ...params, options: activityQualityStatusOptions }),
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataUser>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    hideable: true,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    hideable: true,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];
