import Endpoints from "app/config/Endpoints";
import { PermissionFields, IDataPermissionErrors, IHookFormPermissions, IDataPermission } from "app/types/data/IDataPermission";
import { AxiosError } from "axios";
import Api from "./api";
import Model_Api, { IDataErrors } from "./Model_Api";

export default class Permissions_Api extends Model_Api {

 static self = new Permissions_Api();

 protected handleErrors(error: AxiosError | Error | string | any): IDataPermissionErrors {
  const resp: IDataErrors<IDataPermissionErrors> = this.formatErrors(error);
  const errors: IDataPermissionErrors = resp.errors
  const hookErrors: IHookFormPermissions[] = []

  Object.keys(errors).forEach((key) => {
   const name = key as PermissionFields
   const item: IHookFormPermissions = {
    type: "manual",
    name: name,
    message: errors[key][0]
   };
   hookErrors.push(item)
  });

  errors.hookForm = hookErrors

  return errors

 }

 static async list(): Promise<IDataPermission[]> {
  try {
   return (await Api.get(Endpoints.Permission.main)).data
  } catch (error) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: List Group User', handleErros);
   throw handleErros
  }
 }

 static async give(role: number, permission: number) {
  try {
   return (await Api.post(`${Endpoints.GroupUser.main}/${role}/permission/${permission}`)).data
  } catch (error) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: List Group User', handleErros);
   throw handleErros
  }
 }

 static async revoke(role: number, permission: number) {
  try {
   return (await Api.delete(`${Endpoints.GroupUser.main}/${role}/permission/${permission}`)).data
  } catch (error) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: List Group User', handleErros);
   throw handleErros
  }
 }

 static async attach(data: IDataPermission, files: File[]) {
  try {
   const formData = new FormData();
   files.forEach((file, index) => {
    formData.append(`file[${index}]`, file);
   });

   return (await Api.post(`${Endpoints.Orientation.main}/${data.id}/attach`, formData)).data;
  } catch (error) {
   const handleErros = this.self.handleErrors(error);
   console.log('error: Create Orientations', handleErros);
   throw handleErros;
  }
 }

}