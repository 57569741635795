import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, styled, TextField, Typography } from '@mui/material';
import ServiceType_Api from 'app/api/ServiceType_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataServiceType, IDataServiceTypeErrors } from 'app/types/data/IDataServiceType';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ServiceTypeSchema } from '../@Schemas/ServiceTypeSchema';
import { IFormServiceType } from '../@Types/IFormServiceType';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataServiceType = {
  id: '',
  name: '',
  description: '',
};

const UPDATE_MSG = getMessage('Tipo de Serviço', 'update');
const CREATE_MSG = getMessage('Tipo de Serviço', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const ServiceTypeForm = (props: IFormServiceType) => {
  const { resetFormList } = useUtilsContext();

  const [data, setData] = React.useState<IDataServiceType>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataServiceType>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataServiceType> | null>(
    props.data
      ? { value: props.data?.id as number, label: props.data?.name, data: props.data }
      : null
  );
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    setData((props.data as IDataServiceType) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
  }, [resetFormList?.ServiceTypeForm]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataServiceType>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(ServiceTypeSchema),
  });

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function load() {
    setComboLoading(true);
    try {
      const items = await ServiceType_Api.list({ q: search });

      const combo: ISelectOption<IDataServiceType>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataServiceTypeErrors | unknown) {
      if (error) {
        const err = error as IDataServiceTypeErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  async function onSubmit(data: IDataServiceType) {
    setLoading(true);
    try {
      var newData: IDataServiceType;
      if (data.id) {
        newData = await ServiceType_Api.update(data, props.relation);
      } else {
        newData = await ServiceType_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit(newData);
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataServiceTypeErrors | unknown) {
      if (error) {
        const err = error as IDataServiceTypeErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FormBox id="formik-ServiceType" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {/*Fields*/}
        <Grid container spacing={1} maxWidth={800}>
          <Grid item xs={12}>
            {errors.message?.message && errors.status && (
              <AlertMessage title={errors.status.message} message={errors.message.message} />
            )}
          </Grid>
          {props.relation && (
            <>
              <Grid item xs={12} marginBottom={2}>
                <Autocomplete
                  noOptionsText="Nenhum Canal de Vendas"
                  options={comboData}
                  defaultValue={comboOption}
                  value={comboOption}
                  loading={comboLoading}
                  onClose={() => setSearch('')}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(event, option) => {
                    if (option && option.data) {
                      setData(option.data);
                      setComboOption(option);
                      setDataValue(option.data, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(option.data);
                    } else {
                      setData(initialValues);
                      setComboOption(null);
                      setDataValue(initialValues, setValue);
                      if (props.onChangeRelation) props.onChangeRelation(undefined);
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography noWrap>
                        [{option.value}] {option.label}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TimedTextField
                      {...params}
                      label="Canais de Vendas"
                      placeholder="Pesquisar uma canal de vendas"
                      type="text"
                      size="small"
                      onChange={(e) => setSearch(e.target.value)}
                      helperText={
                        <Typography color="red" variant="caption" component="span">
                          {errors.id?.message}
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {props.type === 'update' && (
            <>
              <Grid item xs={12} md={2}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue={data.id}
                  render={({ field: { onChange } }) => (
                    <TextField
                      id="id"
                      label="ID"
                      type="text"
                      disabled={true}
                      value={data.id}
                      autoComplete={'off'}
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: Boolean(data.id) }}
                      onChange={(e) => {
                        setData({ ...data, id: parseInt(e.target.value) });
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={10}></Grid>
            </>
          )}

          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue={data.name}
              render={({ field: { onChange } }) => (
                <TextField
                  id="form-name"
                  label="Nome*"
                  type="text"
                  value={data.name}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.name?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue={data.description}
              render={({ field: { onChange } }) => (
                <TextField
                  id="form-description"
                  label="Descrição"
                  type="text"
                  multiline
                  rows={10}
                  value={data.description}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.description?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, description: e.target.value });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {/*Buttons*/}
        <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
          <Button onClick={props.onCancel} color="primary">
            Voltar
          </Button>
          <LoadingButton disabled={props.editDisabled}
            type="submit" color="primary" loading={loading} variant="contained">
            Salvar
          </LoadingButton>
        </Grid>
      </FormBox>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </>
  );
};

export default ServiceTypeForm;
