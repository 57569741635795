import React from 'react';
import { Backdrop, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import VirtualShowcase_Api from 'app/api/VirtualShowcase_Api';
import { IDataVirtualShowcase } from 'app/types/data/IDataVirtualShowcase';
import ViewShowcaseProduct from './ViewShowcaseProduct';
import { MatxLoading } from 'app/components';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '40px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const BackdropLoading = () => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  //onClick={handleClose}
  >
    <MatxLoading />
  </Backdrop>
);

const ViewPublicShowcaseProduct = () => {
  const [product, setProduct] = React.useState<IDataVirtualShowcase | undefined>(undefined);

  const { produto } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getProduto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produto]);

  async function getProduto() {
    try {
      const prod = await VirtualShowcase_Api.find(produto as string);
      setProduct(prod);
    } catch (error) {
      console.log('Erro: ', error);
      navigate("/404");
    }
  }


  return (
    <ContentBox>
      {!product && <BackdropLoading />}
      {product && <ViewShowcaseProduct product={product} />}
    </ContentBox>
  );

};

export default ViewPublicShowcaseProduct;
