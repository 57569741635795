import Endpoints from 'app/config/Endpoints';
import {
  IDataUser,
  IDataUserErrors,
  IHookFormUsers,
  IUserFilter,
  IUserPagination,
  UserFields,
  UserRelations,
} from 'app/types/data/IDataUser';
import Model_Api from './Model_Api';
import { AxiosError } from 'axios';
import Api from './api';
import { IDataRelation } from 'app/types/data/IData';
import { IGroupUserPagination } from 'app/types/data/IDataGroupUser';

export default class User_Api extends Model_Api {
  static self = new User_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataUserErrors {
    const resp: IDataUserErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormUsers[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as UserFields;
      const item: IHookFormUsers = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IUserFilter,
    relation?: IDataRelation<UserRelations>
  ): Promise<IUserPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/usuario`, {
            params,
          })
        ).data as IUserPagination;
      else return (await Api.get(Endpoints.User.main, { params })).data as IUserPagination;
    } catch (error: AxiosError | Error | string | any) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List User', handleErros);
      throw handleErros;
    }
  }

  // precisamos usar esse!!!
  static async roles(
    id: number
  ): Promise<IGroupUserPagination> {
    try {
      return (
        await Api.get(`${Endpoints.User.main}/${id}/role`)
      ).data as IGroupUserPagination;
    } catch (error: AxiosError | Error | string | any) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List User', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataUser,
    relation?: IDataRelation<UserRelations>
  ): Promise<IDataUser> {
    try {
      const resp = (await Api.post(Endpoints.User.main, { ...data, is_curator: +data.is_curator }))
        .data as IDataUser;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/usuario/${resp.id}`);

      return resp;
    } catch (error: AxiosError | any) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create User', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataUser,
    relation?: IDataRelation<UserRelations>
  ): Promise<IDataUser> {
    try {
      const resp = (
        await Api.put(`${Endpoints.User.main}/${data.id}`, {
          ...data,
          is_curator: +data.is_curator,
        })
      ).data;
      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/usuario/${data.id}`);

      return resp;
    } catch (error: AxiosError | any) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update User', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataUser,
    relation?: IDataRelation<UserRelations>
  ): Promise<IDataUser> {
    try {
      var resp: IDataUser;

      if (relation)
        resp = (
          await Api.delete(`${Endpoints[relation.relation].main}/${relation.id}/usuario/${data.id}`)
        ).data;
      else resp = (await Api.delete(`${Endpoints.User.main}/${data.id}`)).data;

      return resp;
    } catch (error: AxiosError | any) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete User', handleErros);
      throw handleErros;
    }
  }
}
