import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  IDataSegmentation,
  IDataSegmentationErrors,
  IHookFormSegmentation,
  ISegmentationFilter,
  ISegmentationPagination,
  SegmentationFields,
  SegmentationRelations,
} from 'app/types/data/IDataSegmentation';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Model_Api from './Model_Api';
import Api from './api';

export default class Segmentation_Api extends Model_Api {
  static self = new Segmentation_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataSegmentationErrors {
    const resp: IDataSegmentationErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormSegmentation[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as SegmentationFields;
      const item: IHookFormSegmentation = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: ISegmentationFilter,
    relation?: IDataRelation<SegmentationRelations>
  ): Promise<ISegmentationPagination> {
    try {
      const allParams = { ...params, sort: params?.model_sort };
      delete allParams.model_sort;
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/segmentacao`, {
            params: allParams,
            paramsSerializer(allParams) {
              return QueryString.stringify(allParams);
            },
          })
        ).data as ISegmentationPagination;
      else
        return (
          await Api.get(Endpoints.Segmentation.main, {
            params: allParams,
            paramsSerializer(allParams) {
              return QueryString.stringify(allParams);
            },
          })
        ).data as ISegmentationPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataSegmentation): Promise<IDataSegmentation> {
    try {
      return (await Api.get(`${Endpoints.Segmentation.main}/${data.id}`)).data
        .segmentacao as IDataSegmentation;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataSegmentation,
    relation?: IDataRelation<SegmentationRelations>
  ): Promise<IDataSegmentation> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/segmentacao`, data)
        ).data.segmentacao;
      } else {
        resp = (await Api.post(Endpoints.Segmentation.main, data)).data.segmentacao;
      }

      return resp as IDataSegmentation;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataSegmentation,
    relation?: IDataRelation<SegmentationRelations>
  ): Promise<IDataSegmentation> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/segmentacao`, data)
        ).data.segmentacao;
      } else {
        resp = (await Api.put(`${Endpoints.Segmentation.main}/${data.id}`, data)).data.segmentacao;
      }
      return resp as IDataSegmentation;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataSegmentation,
    relation?: IDataRelation<SegmentationRelations>
  ): Promise<IDataSegmentation> {
    try {
      var resp: IDataSegmentation;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/segmentacao/${data.id}`
          )
        ).data.segmentacao;
      else resp = (await Api.delete(`${Endpoints.Segmentation.main}/${data.id}`)).data.segmentacao;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataSegmentation
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Segmentation.main}/${model.id}/attachments`
            : `/media${Endpoints.Segmentation.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(
    segmentacao: IDataSegmentation,
    files: File[],
    links?: String[],
    as_copy?: number
  ) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      links?.forEach((link, index) => {
        formData.append(`link[${index}]`, link + '');
      });
      return (
        await Api.post(`${Endpoints.Segmentation.main}/${segmentacao.id}/attach`, formData, {
          params: { as_copy },
        })
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Segmentation.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async detachMetaImage(midia_id: number) {
    try {
      return (await Api.delete(`${Endpoints.Segmentation.main}/${midia_id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Segmentation', handleErros);
      throw handleErros;
    }
  }

  static async duplicate(
    segmentation_id: number,
    relation?: IDataRelation<SegmentationRelations>
  ): Promise<IDataSegmentation> {
    try {
      var resp;
      if (relation) {
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${
            relation.id
          }/segmentacao/${segmentation_id}/copiar`
        );
      } else {
        resp = (await Api.post(`${Endpoints.Segmentation.main}/${segmentation_id}/copiar`)).data
          .segmentacao;
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Segmentation', handleErros);
      throw handleErros;
    }
  }
}
