import * as yup from 'yup';
import { FormMessages } from '../@Messages';
import { IDataWorkflowPhases } from 'app/types/data/IDataWorkflowPhases';
import { IDataUser } from 'app/types/data/IDataUser';
import { ISelectOption } from 'app/types/ISelectOption';

export const ActivitySchema = yup.object().shape({
  summary: yup.string().required(FormMessages('Resumo').validations.required),
  stage_id: yup.mixed<IDataWorkflowPhases>().required(FormMessages('Etapa').validations.required),
  performed_by: yup.mixed<IDataUser>().nullable(),
  assignee: yup.mixed<IDataUser>().nullable(),
  priority: yup.mixed<ISelectOption>().required(FormMessages('Prioridade').validations.required),
  start_date: yup.date().typeError('O Valor deve ser uma data válida').nullable(),
  end_date: yup
    .date()
    .typeError('O Valor deve ser uma data válida')
    .nullable()
    .test('maiorData', 'A data de fim deve ser maior ou igual a data de início', function (value) {
      const { start_date } = this.parent;
      if (value) return value >= start_date;
      else return true;
    }),
  description: yup.string().nullable(),
  status: yup.mixed<ISelectOption>().required(FormMessages('Status').validations.required),
  /*quality_status: yup
    .mixed<ISelectOption>()
    .required(FormMessages('Qualidade Status').validations.required),
  */
  progress: yup
    .number()
    .required(FormMessages('Progresso').validations.required)
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'O valor mínimo para progresso é 0')
    .max(100, 'O valor máximo para progresso é 100'),
  value: yup
    .number()
    .required(FormMessages('Valor').validations.required)
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'O valor mínimo para valor é 0')
    .max(9999, 'O valor máximo para valor é 9999'),
});
