import React, { Fragment } from 'react';
import { Avatar, Card, CircularProgress, Grid, styled } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import FormUser from 'app/components/Forms/User';
import { IDataUser, IDataUserErrors } from 'app/types/data/IDataUser';
import Profile_Api from 'app/api/Profile_Api';
import Toast from 'app/components/Toast';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '40px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const CardBox = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative'
}));

const AvatarUser = styled(Avatar)(({ theme }) => ({
  width: 128,
  height: 128,
  marginBottom: theme.spacing(2),
  cursor: 'pointer'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));


const UPDATE_MSG = getMessage('Perfil', "update");
const AVATAR_MSG = getMessage('Avatar', "update");

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
}

const Profile = () => {
  const { user, refresh } = useAuth();
  const [avatar, setAvatar] = React.useState(user?.avatar);
  const [loadingAvatar, setLoadingAvatar] = React.useState(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  function handleCloseToast() { setToast({ open: true, ...toast }) }

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setAvatar(user?.avatar)
      return
    }

    try {
      setLoadingAvatar(true);
      await Profile_Api.avatar(e.target.files[0]) as IDataUser;
      await refresh();
      setAvatar(URL.createObjectURL(e.target.files[0]))
      setToast({ open: true, message: AVATAR_MSG, severity: initialToast.severity })
      //setAvatar(resp.avatar)
    } catch (error: IDataUserErrors | unknown) {
      const err = error as IDataUserErrors
      if (err) {
        setToast({ open: true, message: err.errors.message || getMessage('Avatar', 'error'), severity: 'error' })
      }
    } finally { setLoadingAvatar(false); }
  }

  return (
    <Fragment>
      <ContentBox>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <CardBox>
              <FlexContainer>
                <input hidden accept="image/*" className="input" id="avatar-button-file" type="file" onChange={onSelectFile} />
                {loadingAvatar && <CircularProgress color='secondary' sx={{ position: 'absolute', top: 45, zIndex: 99 }} />}
                <label htmlFor="avatar-button-file">
                  <AvatarUser src={avatar} sx={loadingAvatar ? { '& img': { filter: 'grayscale(100%)' } } : {}} />
                </label>
                <Title>{user?.name}</Title>
                <SubTitle>{user?.email}</SubTitle>
              </FlexContainer>
            </CardBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardBox>
              <FormUser data={user as IDataUser} profile type="update" onSubmit={() => refresh()} />
            </CardBox>
          </Grid>
        </Grid>
      </ContentBox>
      <Toast open={toast.open} onClose={handleCloseToast} severity={toast.severity} message={toast.message} />
    </Fragment>
  );
};

export default Profile;
