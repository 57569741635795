import WorkflowPhases_Api from 'app/api/WorkflowPhases_Api';
import { useKanban } from 'app/contexts/Kanban/KanbanStepsContext';
import { KanbanStep } from 'app/types/IKanban';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataWorkflowPhases,
  IDataWorkflowPhasesErrors,
  WorkflowPhasesRelations,
} from 'app/types/data/IDataWorkflowPhases';
import _ from 'lodash';
import React from 'react';

interface Props {
  relation?: IDataRelation<WorkflowPhasesRelations>;
}

const useWorkflowPhases = ({ relation }: Props) => {
  const { setCurrentStep } = useKanban();
  const [data, setData] = React.useState<IDataWorkflowPhases[]>([]);
  const [filteredWorkflowSteps, setFilteredWorkflowSteps] = React.useState<KanbanStep[]>([]);
  const [status, setStatus] = React.useState('idle');
  const [errors, setErrors] = React.useState<string>();

  React.useEffect(() => {
    async function load() {
      setStatus('pending');
      try {
        const items = await WorkflowPhases_Api.list({ q: '' }, relation);
        const sortedSteps = _.orderBy(
          items.data,
          ['started_in', 'solve_until', 'id'],
          ['asc', 'asc', 'asc']
        ); // Sort steps by 'started_in' (desc), 'solve_until' (asc), and 'id' (asc)
        const steps = sortedSteps?.map((item) => ({
          id: item.id,
          label: item.name,
          content: item,
        }));
        setData(sortedSteps);
        setCurrentStep({ step: 1, data: { content: sortedSteps[0] } });
        setFilteredWorkflowSteps(steps);
      } catch (error: IDataWorkflowPhasesErrors | unknown) {
        if (error) {
          const err = error as IDataWorkflowPhasesErrors;
          setErrors(err.errors?.message);
        }
      } finally {
        setStatus('resolved');
      }
    }
    load();
  }, [relation]);

  return { data, status, errors, filteredWorkflowSteps };
};

export default useWorkflowPhases;
