import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box, Button, Divider, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { KanbanStep } from 'app/types/IKanban';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import 'moment/locale/pt-br';

function QontoStepIcon(props: StepIconProps) {
  const { active } = props;

  return active ? (
    <RadioButtonCheckedIcon color="primary" />
  ) : (
    <RadioButtonUncheckedIcon color="disabled" />
  );
}

interface StepsProps {
  steps: KanbanStep[];
  onStepChange?: (step: number, content: KanbanStep) => void;
}

const Steps = ({ steps, onStepChange }: StepsProps) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const handleStep = (step: number, content: KanbanStep) => () => {
    setActiveStep(step);
    onStepChange && onStepChange(step, content);
  };
  return (
    <Stepper nonLinear activeStep={activeStep}>
      {steps.map((item, index) => (
        <Button
          sx={{
            width: '300px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          color="inherit"
          onClick={handleStep(index, item)}
          key={index}
        >
          <Step
            key={item.label}
            active={activeStep === index}
            sx={{
              '& .MuiStepLabel-root': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                '& .MuiStepLabel-iconContainer': {
                  paddingRight: 0,
                },
              },
            }}
          >
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Box>
                <Typography>{item.label}</Typography>
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                      bgcolor: 'inherent',
                      color: 'text.secondary',
                      '& svg': {
                        m: 1.5,
                      },
                      '& hr': {
                        mx: 0.5,
                      },
                    }}
                  >
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Typography variant="caption">
                        {format(
                          moment(item.content?.started_in).locale('pt-br').toDate(),
                          'dd MMMM',
                          {
                            locale: ptBR,
                          }
                        )}
                      </Typography>
                      <CalendarTodayIcon fontSize="small" />
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <EventAvailableIcon fontSize="small" />
                      <Typography variant="caption">
                        {format(
                          moment(item.content?.solve_until).locale('pt-br').toDate(),
                          'dd MMMM',
                          {
                            locale: ptBR,
                          }
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
            </StepLabel>
          </Step>
        </Button>
      ))}
    </Stepper>
  );
};

export default Steps;
