import axios from 'axios';

//const hostname = 'localhost';
//const apiURL = process.env.REACT_APP_API_URL || `http://${hostname}/api`;

const hostname = window.location.hostname;
const apiURL = hostname === 'localhost' ? `http://${hostname}:8000/api` : `https://${hostname}/api`;
const Api = axios.create({
  baseURL: apiURL,
});
if (!process.env.REACT_APP_API_URL) {
  const f = await axios.get(__dirname + "config.json");
  Api.defaults.baseURL = f.data.REACT_APP_API_URL;
}



Api.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Api.interceptors.request.use(
  (config) => {
    const accessToken = window.sessionStorage.getItem('accessToken');
    config.headers['Authorization'] = 'bearer ' + accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default Api;
