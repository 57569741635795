import { Fragment } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import propTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

function FormatCurrency(props) {
  const { inputRef, onChange, value, ...other } = props;
  const precision = 2;

  const nValue = Number(value).toLocaleString(undefined, {
    style: 'decimal',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  });

  //const nValue = value.replace('.', '').replace(',', '.');

  return (
    <CurrencyInput
      {...other}
      value={nValue}
      getInputRef={inputRef}
      onChangeEvent={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.target.value.replace('.', '').replace(',', '.'),
          },
        });
      }}
      precision={precision}
      decimalSeparator=","
      thousandSeparator="."
    />
  );
}

FormatCurrency.propTypes = {
  inputRef: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

const MoneyInput = (props: TextFieldProps) => {
  return (
    <Fragment>
      <TextField
        {...props}
        InputProps={{ inputComponent: FormatCurrency, ...props.InputProps }}
      />
    </Fragment>
  );
}

export default MoneyInput;