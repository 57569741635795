import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  OrientationFields,
  IDataOrientation,
  IDataOrientationErrors,
  IHookFormOrientation,
  OrientationRelations,
  IOrientationPagination,
  IOrientationFilter,
} from 'app/types/data/IDataOrientation';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class Orientation_Api extends Model_Api {
  static self = new Orientation_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataOrientationErrors {
    const resp: IDataOrientationErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormOrientation[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as OrientationFields;
      const item: IHookFormOrientation = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IOrientationFilter,
    relation?: IDataRelation<OrientationRelations>
  ): Promise<IOrientationPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/orientacao`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IOrientationPagination;
      else
        return (
          await Api.get(Endpoints.Orientation.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IOrientationPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Orientations', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataOrientation): Promise<IDataOrientation> {
    try {
      return (await Api.get(`${Endpoints.Orientation.main}/${data.id}`)).data as IDataOrientation;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Orientations', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataOrientation,
    relation?: IDataRelation<OrientationRelations>
  ): Promise<IDataOrientation> {
    try {
      var resp;
      const relationNames = ['Sku'];
      if (relationNames.includes(relation?.relation as string)) {
        resp = await Api.post(
          `${Endpoints[relation?.relation].main}/${relation?.id}/orientacao`,
          data
        );
      } else {
        resp = (await Api.post(Endpoints.Orientation.main, data)).data
          .orientacao as IDataOrientation;
        if (relation)
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/orientacao/${resp.id}`
          );
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Orientations', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataOrientation,
    relation?: IDataRelation<OrientationRelations>
  ): Promise<IDataOrientation> {
    try {
      var resp;
      const relationNames = ['Sku'];
      if (relationNames.includes(relation?.relation as string)) {
        resp = await Api.post(
          `${Endpoints[relation?.relation].main}/${relation?.id}/orientacao`,
          data
        );
      } else {
        resp = (await Api.put(`${Endpoints.Orientation.main}/${data.id}`, data)).data.orientacao;

        if (relation)
          await Api.post(
            `${Endpoints[relation.relation].main}/${relation.id}/orientacao/${data.id}`
          );
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Orientations', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataOrientation,
    relation?: IDataRelation<OrientationRelations>
  ): Promise<IDataOrientation> {
    try {
      var resp: IDataOrientation;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/orientacao/${data.id}`
          )
        ).data.orientacao;
      else resp = (await Api.delete(`${Endpoints.Orientation.main}/${data.id}`)).data.orientacao;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Orientations', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataOrientation
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Orientation.main}/${model.id}/attachments`
            : `/media${Endpoints.Orientation.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Store', handleErros);
      throw handleErros;
    }
  }

  static async attach(orientacao: IDataOrientation, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Orientation.main}/${orientacao.id}/attach`, formData))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Orientations', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Orientation.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Orientations', handleErros);
      throw handleErros;
    }
  }
}
