import * as yup from 'yup';
import { FormMessages } from '../@Messages';
import { IDataReasonType } from 'app/types/data/IDataReasonType';

export const ReasonsSchema = yup.object().shape({
  summary: yup.string().required(FormMessages('Resumo').validations.required),
  type_reason_id: yup
    .mixed<IDataReasonType>()
    .required(FormMessages('Tipo de motivo').validations.required),
  description: yup.string().nullable(),
});
