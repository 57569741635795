import { MultiBackend, NodeModel, Tree, getBackendOptions } from '@minoru/react-dnd-treeview';
import { CircularProgress, CssBaseline, Paper, ThemeProvider, styled } from '@mui/material';
import Categories_Api from 'app/api/Categories_Api';
import { IDataCategory } from 'app/types/data/IDataCategory';
import { IDataPartnerCategory } from 'app/types/data/IDataPartnerCategory';
import React from 'react';
import { DndProvider } from 'react-dnd';
import styles from './App.module.css';
import { CustomDragPreview } from './components/CustomDragPreview';
import { CustomNode } from './components/CustomNode';
import { Placeholder } from './components/Placeholder';
import { theme } from './components/theme';

const ContentBox = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 1,
  zIndex: 999,
}));

interface ITreeViewCategories {
  data: NodeModel<IDataCategory | IDataPartnerCategory>[];
  nodeSelected?: NodeModel<IDataCategory | IDataPartnerCategory>;
  loading?: boolean;
  clearSelected?: boolean;
  onDrop?: () => void;
  onUpdate?: (node: NodeModel<IDataCategory | IDataPartnerCategory>, newValue: string) => void;
  onClickNode: (node: NodeModel<IDataCategory | IDataPartnerCategory>) => void;
}

export default function TreeViewCategories(props: ITreeViewCategories) {
  const [treeData, setTreeData] = React.useState<NodeModel<IDataCategory | IDataPartnerCategory>[]>(
    props.data
  );
  const [selectedNode, setSelectedNode] = React.useState<NodeModel<
    IDataCategory | IDataPartnerCategory
  > | null>(null);

  const handleDrop = async (newTree: NodeModel<IDataCategory>[], options) => {
    const { dragSourceId, dropTargetId, dragSource } = options;
    try {
      const subTree = newTree.filter((node) => node.parent === dropTargetId);
      const newIndex = subTree.findIndex((node) => node.id === dragSourceId);
      await Categories_Api.update({
        ...dragSource.data,
        classificacao: newIndex + 1,
        parent_id: dropTargetId || null,
      });
      setTreeData(newTree);
      if (props.onDrop) props.onDrop();
    } catch (error) {}
  };

  React.useEffect(() => {
    if (props.nodeSelected) setSelectedNode(props.nodeSelected);
  }, [props.nodeSelected]);

  React.useEffect(() => {
    if (props.clearSelected) setSelectedNode(null);
  }, [props.clearSelected]);

  React.useEffect(() => {
    setTreeData(props.data);
  }, [props.data]);

  function handleSelect(node: NodeModel<IDataCategory>) {
    setSelectedNode(node);
    props.onClickNode(node);
  }

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.app}>
            {props.loading && (
              <ContentBox>
                <CircularProgress color="primary" />
              </ContentBox>
            )}
            <Tree
              tree={treeData}
              rootId={0}
              render={(
                node: NodeModel<IDataCategory | IDataPartnerCategory>,
                { depth, isOpen, onToggle }
              ) => (
                <CustomNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={onToggle}
                  onSelect={handleSelect}
                  isSelected={node.id === selectedNode?.id}
                  onUpdate={(value) => (props.onUpdate ? props.onUpdate(node, value) : undefined)}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps} />
              )}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                placeholder: styles.placeholderContainer,
              }}
              sort={false}
              insertDroppableFirst={false}
              canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                if (dragSource?.parent === dropTargetId) {
                  return true;
                }
              }}
              dropTargetOffset={10}
              placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
            />
          </div>
        </DndProvider>
      </ThemeProvider>
    </Paper>
  );
}
