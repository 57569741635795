import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography as MUITypography,
  styled,
} from '@mui/material';
import correctedWords from 'app/utils/lang/pt-BR';
import { useLocation, useNavigate, Link as RouteLink } from 'react-router-dom';
import { navigations } from 'app/navigations';

function withRouter(Child) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child {...props} navigate={navigate} location={location} />;
  };
}

const NavLink = styled(RouteLink)(() => ({
  textTransform: 'capitalize',
  textDecoration: 'none',
}));

const Typography = styled(MUITypography)(() => ({
  textTransform: 'capitalize',
}));

const BreadcrumbComponent = styled(MUIBreadcrumbs)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
  margin: '15px',
}));

const Breadcrumbs = (props) => {
  const {
    location: { pathname },
  } = props;
  const pathnames = pathname
    .toLowerCase()
    .split('/')
    .filter((x) => x);
  return (
    <BreadcrumbComponent aria-label="breadcrumb">
      {pathnames.map((name, index) => {
        const hasPath = navigations.filter((x) => x.name === correctedWords[name]);
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        if (isLast)
          return (
            <Typography
              color="#838383"
              key={name}
              sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
            >
              {correctedWords[name] || name}
            </Typography>
          );
        if (hasPath.path) {
          return (
            <NavLink to={routeTo}>
              <Link
                underline="none"
                color="inherit"
                key={name}
                sx={{ textTransform: 'capitalize' }}
              >
                {correctedWords[name] || name}
              </Link>
            </NavLink>
          );
        }
        return (
          <Link underline="none" color="inherit" key={name} sx={{ textTransform: 'capitalize' }}>
            {correctedWords[name] || name}
          </Link>
        );
      })}
    </BreadcrumbComponent>
  );
};

export default withRouter(Breadcrumbs);
