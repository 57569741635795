import React, { Fragment } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  IconButton,
  SelectChangeEvent,
  styled,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add, Close, FilterAlt, FilterAltOff } from '@mui/icons-material';
import {
  GridColDef,
  GridColType,
  GridRenderEditCellParams,
  GridValueOptionsParams,
  ValueOptions,
} from '@mui/x-data-grid';
import { IParamsQs } from 'app/types/IParams';
import SelectSimple from 'app/components/Inputs/Selects/SelectSimple/SelectSimple';
import { ISelectOption } from 'app/types/ISelectOption';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';
import ptBRDatePicker from 'rsuite/locales/pt_BR';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDbDate, removeExtraSpaces } from 'app/utils/format';
import { parseISO } from 'date-fns';
import DateRangePicker from 'app/components/Inputs/DateRangePicker/DateRangePicker';

const FormBox = styled('form')(({ theme }) => ({
  width: 600,
  padding: theme.spacing(2, 3, 1),
}));

export type operators = 'where' | 'like' | '>' | '>=' | '<' | '<=' | 'in' | 'between';

export interface ValueOptionObject {
  value: any;
  label: string;
}

export interface IFilters {
  field: string;
  dateType: GridColType;
  valueOptions?: ValueOptions[] | ((params: GridValueOptionsParams<any>) => ValueOptions[]);
  operator: operators | string;
  value: string;
  valueOptionSelect?: ValueOptions | ValueOptions[] | null;
  component?: (params: GridRenderEditCellParams<any>) => React.ReactNode;
}

export interface IFilterForm {
  fields: GridColDef[];
  onFilterApply: (filter: IParamsQs) => void;
}

export function customComponentFilterOnChange(value: any) {
  return value;
}

interface FilterRange {
  [key: string]: {
    start: string;
    end: string;
  };
}

const FilterForm = (props: IFilterForm) => {
  const filterableFields = props.fields.filter((field, index) => field.filterable !== false);
  const gridFields = filterableFields.map((field, index) => ({
    value: field.field,
    label: field.headerName || field.field,
  }));
  const [numberBetween, setNumberBetween] = React.useState<FilterRange>({});

  const filters_initial: IFilters[] = [
    {
      field: filterableFields[1].field,
      dateType: filterableFields[1].type || 'string',
      valueOptions: filterableFields[1].valueOptions,
      operator: 'where',
      value: '',
      component: undefined,
    },
  ];
  const [filtersOptions, setFiltersOptions] = React.useState<IFilters[]>(filters_initial);

  function addFilter() {
    setFiltersOptions([...filtersOptions, filters_initial[0]]);
  }

  function removeFilter(index) {
    const nFilters = [...filtersOptions];
    nFilters.splice(index, 1);
    setFiltersOptions(nFilters);
  }

  function handleChangeField(
    filter: IFilters,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number
  ) {
    let nFilters = [...filtersOptions];
    const field = filterableFields.filter((value) => value.field === e.target.value);
    nFilters[index] = {
      ...nFilters[index],
      [e.target.name]: e.target.value,
      valueOptions: field[0] && field[0].valueOptions ? field[0].valueOptions : undefined,
      dateType: field[0] && field[0].type ? (field[0].type as string) : 'string',
      component: field[0].renderEditCell,
    };
    nFilters[index].operator =
      (getOperators(nFilters[index])?.[0]?.value as string) || nFilters[index].operator;
    setFiltersOptions(nFilters);
  }

  function handleChangeValue(
    filter: IFilters,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number
  ) {
    let nFilters = [...filtersOptions];
    //const field = filterableFields.filter(value => value.field === e.target.value);
    nFilters[index] = {
      ...filter,
      ...nFilters[index],
      [e.target.name]: e.target.value,
    };
    setFiltersOptions(nFilters);
  }

  function handleNumberRangeChange(
    filter: IFilters,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number,
    rangeData: { start?: number | string; end?: number | string }
  ) {
    let nFilters = [...filtersOptions];
    nFilters[index] = {
      ...filter,
      ...nFilters[index],
      value: `${rangeData.start},${rangeData.end}`,
    };
    setFiltersOptions(nFilters);
  }

  function handleChangeCheckField(
    filter: IFilters,
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    let nFilters = [...filtersOptions];
    //const field = filterableFields.filter(value => value.field === e.target.value);
    nFilters[index] = {
      ...nFilters[index],
      [e.target.name]: e.target.checked ? '1' : '0',
      valueOptions: undefined,
      dateType: 'boolean',
    };
    setFiltersOptions(nFilters);
  }

  function handleChangeOperator(
    filter: IFilters,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    index: number
  ) {
    let nFilters = [...filtersOptions];
    //const field = filterableFields.filter(value => value.field === e.target.value);
    nFilters[index] = { ...nFilters[index], [e.target.name]: e.target.value };

    setFiltersOptions(nFilters);
  }

  function handleChangeDate(filter: IFilters, dateValue: Date | null, index: number) {
    if (dateValue instanceof Date && !isNaN(Number(dateValue))) {
      let nFilters = [...filtersOptions];
      nFilters[index] = {
        ...nFilters[index],
        value: dateValue ? formatDbDate(dateValue, false) : '',
        dateType: (filter.dateType as string) || 'string',
      };

      setFiltersOptions(nFilters);
    }
  }

  function handleChangeRangeDate(filter: IFilters, dateValue: Date[] | null, index: number) {
    let nFilters = [...filtersOptions];
    nFilters[index] = {
      ...nFilters[index],
      value: dateValue
        ? `${formatDbDate(dateValue[0], false)},${formatDbDate(dateValue[1], false)}`
        : '',
      dateType: (filter.dateType as string) || 'string',
    };

    setFiltersOptions(nFilters);
  }

  function handleChangeTags(filter: IFilters, index: number, newValue: string[] | ValueOptions[]) {
    let nFilters = [...filtersOptions];
    let values = newValue.filter((v) => v !== '');
    nFilters[index] = {
      ...nFilters[index],
      value: values.join(',') || '',
      dateType: (filter.dateType as string) || 'string',
    };

    setFiltersOptions(nFilters);
  }

  function handleChangeSingleSelect(
    filter: IFilters,
    index: number,
    newValue: ValueOptions | ValueOptions[] | null
  ) {
    let nFilters = [...filtersOptions];
    let values: string | string[] = '';

    if (!newValue) values = '';
    else if (Array.isArray(newValue)) {
      values = newValue
        .map((item: ValueOptions, index) => (item as ValueOptionObject).value)
        .join(',');
    }

    nFilters[index] = {
      ...nFilters[index],
      operator: 'in',
      value: values,
      valueOptionSelect: newValue,
      dateType: (filter.dateType as string) || 'string',
    };

    setFiltersOptions(nFilters);
  }

  function getOperators(filter: IFilters): ISelectOption[] {
    let options = [
      { value: 'where', label: 'igual' },
      { value: 'like', label: 'contém' },
      { value: 'in', label: 'está em' },
    ];

    switch (filter.dateType) {
      case 'string':
        options = [
          { value: 'where', label: 'igual' },
          { value: 'like', label: 'contém' },
          { value: 'in', label: 'está em' },
        ];
        break;
      case 'singleSelect':
        options = [
          //{ value: 'where', label: 'igual' },
          { value: 'in', label: 'está em' },
        ];
        break;
      case 'number':
        options = [
          { value: '=', label: 'igual' },
          { value: '>', label: 'maior' },
          { value: '>=', label: 'maior ou igual' },
          { value: '<', label: 'menor' },
          { value: '<=', label: 'menor ou igual' },
          { value: 'between', label: 'está entre' },
        ];
        break;
      case 'date' || 'dateTime':
        options = [
          { value: '=', label: 'igual' },
          { value: '>', label: 'maior' },
          { value: '>=', label: 'maior ou igual' },
          { value: '<', label: 'menor' },
          { value: '<=', label: 'menor ou igual' },
          { value: 'between', label: 'está entre' },
        ];
        break;
      case 'boolean':
        options = [{ value: 'where', label: 'igual' }];
        break;
    }

    return options;
  }

  function getInputComponent(filter: IFilters, index: number) {
    if (filter.valueOptions) {
      return (
        <Autocomplete
          multiple
          options={filter.valueOptions as ValueOptions[]}
          //isOptionEqualToValue={(option, value) => (option as ValueOptionObject).value === value}
          //value={filter.operator === 'in' ? filter.valueOptionSelect as ValueOptions | ValueOptions[] : filter.value.split(",")}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Valores" fullWidth size="small" />
          )}
          onChange={(e, newValue) => handleChangeSingleSelect(filter, index, newValue)}
        />
      );
    } else if (['date', 'datetime'].includes(filter.dateType) && filter.operator !== 'between') {
      return (
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data"
            value={filter.value ? parseISO(filter.value) : null}
            onChange={(newDate) => handleChangeDate(filter, newDate, index)}
            //onChange={(newDate) => console.log('newDate', newDate)}
            renderInput={(props) => (
              <TextField
                label="Data"
                name="value"
                fullWidth
                size="small"
                variant="standard"
                {...props}
              />
            )}
          />
        </LocalizationProvider>
      );
    } else if (filter.dateType === 'boolean') {
      filter.value = filter.value || '0';
      return (
        <Checkbox
          name="value"
          value={filter.value}
          checked={filter.value === '1'}
          onChange={(e) => handleChangeCheckField(filter, e, index)}
        />
      );
    } else if (filter.operator === 'in') {
      return (
        <Autocomplete
          multiple
          options={[]}
          value={filter.value.split(',')}
          freeSolo
          renderTags={(value, getTagProps) => {
            const filteredValue = removeExtraSpaces(value);
            return filteredValue.map((option, i) => (
              <Chip variant="outlined" size="small" label={option} {...getTagProps({ index: i })} />
            ));
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Valores" fullWidth size="small" />
          )}
          onChange={(e, newValue: string[]) => handleChangeTags(filter, index, newValue)}
        />
      );
    } else if (filter.operator === 'between' && ['date', 'datetime'].includes(filter.dateType)) {
      return (
        <DateRangePicker
          block
          locale={ptBRDatePicker.Calendar}
          size="sm"
          placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
          format="dd/MM/yyyy"
          onChange={(value) => handleChangeRangeDate(filter, value, index)}
          cleanable={false}
          style={{ marginTop: 12 }}
        />
      );
    } else if (filter.operator === 'between' && filter.dateType === 'number') {
      return (
        <Box display="flex">
          <TextField
            type="number"
            name="value"
            variant="standard"
            label="De"
            fullWidth
            size="small"
            sx={{ mr: 1 }}
            onChange={(e) => {
              const newStartValue = e.target.value;
              setNumberBetween((prev) => ({
                ...prev,
                [filter.field]: { ...prev[filter.field], start: newStartValue },
              }));
              handleNumberRangeChange(filter, e, index, {
                start: newStartValue,
                end: numberBetween?.[filter.field]?.end || '',
              });
            }}
          />
          <TextField
            type="number"
            name="value"
            variant="standard"
            label="Até"
            fullWidth
            size="small"
            onChange={(e) => {
              const newEndValue = e.target.value;
              setNumberBetween((prev) => ({
                ...prev,
                [filter.field]: { ...prev[filter.field], end: newEndValue },
              }));
              handleNumberRangeChange(filter, e, index, {
                start: numberBetween?.[filter.field]?.start || '',
                end: newEndValue,
              });
            }}
          />
        </Box>
      );
    } else {
      return (
        <TextField
          label="Valor"
          type={filter.dateType === 'number' ? 'number' : 'text'}
          name="value"
          value={filter.value || ''}
          autoComplete={'off'}
          variant="standard"
          size="small"
          fullWidth
          onChange={(e) => handleChangeValue(filter, e, index)}
        />
      );
    }
  }

  function applyFilters() {
    var nFilters: IParamsQs = {};
    filtersOptions.forEach((filter, index) => {
      if (filter.value) {
        if (['where'].includes(filter.operator) && filter.dateType !== 'number') {
          if (
            ['boolean', 'date', 'datetime', 'string'].includes(filter.dateType) ||
            !filter.dateType
          ) {
            nFilters[filter.field] = filter.value;
          } else {
            if (!nFilters[filter.field]) nFilters[filter.field] = [];
            nFilters[filter.field].push(filter.value);
          }
        } else if (['in'].includes(filter.operator)) {
          if (!nFilters[filter.field]) nFilters[filter.field] = [];
          let vals = filter.value.split(',');
          nFilters[filter.field] = [...nFilters[filter.field], ...vals];
        } else if (['like'].includes(filter.operator)) {
          nFilters[`${filter.field}[${filter.operator}]`] = `%${filter.value}%`;
        } else if (['between'].includes(filter.operator)) {
          const filterDates = filter.value.split(',');
          nFilters[`${filter.field}[start]`] = filterDates[0];
          nFilters[`${filter.field}[end]`] = filterDates[1];
        } else {
          nFilters[`${filter.field}[operator]`] = filter.operator;
          nFilters[`${filter.field}[value]`] = filter.value;
        }
      }
    });
    props.onFilterApply(nFilters);
  }

  function cleanFilters() {
    setFiltersOptions(filters_initial);
    applyFilters();
  }

  function handleSetOperator(filter: IFilters): string {
    const operators = getOperators(filter);
    const operator = operators.filter((value) => value.value === filter.operator);

    return operator[0] ? (operator[0].value as string) : (operators[0].value as string);
  }

  return (
    <FormBox id="formik-Contact" autoComplete="off">
      {/*Fields*/}
      <Grid container spacing={2} maxWidth={800}>
        {filtersOptions.map((fltr, index) => (
          <Fragment key={index}>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <IconButton
                sx={{ position: 'absolute', left: -6, top: 23 }}
                aria-label="Remover Filtro"
                onClick={() => removeFilter(index)}
              >
                <Close />
              </IconButton>
              <FormControl variant="filled" sx={{ width: '90%', mt: 1.55, ml: 1.5 }}>
                <SelectSimple
                  labelId="filter-field-label"
                  //id="filter-field"
                  name="field"
                  label="Campo"
                  value={fltr.field || (gridFields[1].value as string)}
                  variant="standard"
                  onChange={(e) => handleChangeField(fltr, e, index)}
                  options={gridFields}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="filled" sx={{ width: '100%', mt: 1.55 }}>
                <SelectSimple
                  labelId="filter-operator-label"
                  //id="filter-operator"
                  name="operator"
                  label="Operador"
                  value={handleSetOperator(fltr)}
                  variant="standard"
                  onChange={(e) => handleChangeOperator(fltr, e, index)}
                  options={getOperators(fltr)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              {getInputComponent(fltr, index)}
            </Grid>
          </Fragment>
        ))}
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="space-between" marginTop={3}>
        <Button color="primary" variant="text" onClick={addFilter} startIcon={<Add />}>
          Adicionar
        </Button>
        <div>
          <Button
            color="inherit"
            variant="text"
            onClick={cleanFilters}
            startIcon={<FilterAltOff />}
          >
            Limpar
          </Button>
          <LoadingButton
            color="primary"
            variant="text"
            onClick={applyFilters}
            startIcon={<FilterAlt />}
          >
            Aplicar
          </LoadingButton>
        </div>
      </Grid>
    </FormBox>
  );
};

export default FilterForm;
