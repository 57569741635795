import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import { IAttachmentFilter, IAttachmentPagination, IDataAttachment } from 'app/types/data/IDataAttachment';
import {
  StoreStatusFields,
  IDataStoreStatus,
  IDataStoreStatusErrors,
  IHookFormStoreStatus,
  StoreStatusRelations,
  IStoreStatusPagination,
  IStoreStatusFilter,
} from 'app/types/data/IDataStoreStatus';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class StoreStatus_Api extends Model_Api {
  static self = new StoreStatus_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataStoreStatusErrors {
    const resp: IDataStoreStatusErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormStoreStatus[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as StoreStatusFields;
      const item: IHookFormStoreStatus = {
        type: 'manual',
        name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IStoreStatusFilter,
    relation?: IDataRelation<StoreStatusRelations>
  ): Promise<IStoreStatusPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/status_loja`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IStoreStatusPagination;
      else
        return (await Api.get(Endpoints.StoreStatus.main,
          {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }))
          .data as IStoreStatusPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataStoreStatus): Promise<IDataStoreStatus> {
    try {
      return (await Api.get(`${Endpoints.StoreStatus.main}/${data.id}`)).data as IDataStoreStatus;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataStoreStatus,
    relation?: IDataRelation<StoreStatusRelations>
  ): Promise<IDataStoreStatus> {
    try {
      const resp = (await Api.post(Endpoints.StoreStatus.main, data)).data
        .status_loja as IDataStoreStatus;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/status_loja/${resp.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataStoreStatus,
    relation?: IDataRelation<StoreStatusRelations>
  ): Promise<IDataStoreStatus> {
    try {
      const resp = (await Api.put(`${Endpoints.StoreStatus.main}/${data.id}`, data)).data
        .status_loja;

      if (relation)
        await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/status_loja/${data.id}`
        );

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataStoreStatus,
    relation?: IDataRelation<StoreStatusRelations>
  ): Promise<IDataStoreStatus> {
    try {
      var resp: IDataStoreStatus;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/status_loja/${data.id}`
          )
        ).data.status_loja;
      else resp = (await Api.delete(`${Endpoints.StoreStatus.main}/${data.id}`)).data.status_loja;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataStoreStatus,
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.StoreStatus.main}/${model.id}/attachments`
            : `/media${Endpoints.StoreStatus.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List StoreStatus', handleErros);
      throw handleErros;
    }
  }

  static async attach(status_loja: IDataStoreStatus, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.StoreStatus.main}/${status_loja.id}/attach`, formData))
        .data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create StoreStatuss', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.StoreStatus.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create StoreStatuss', handleErros);
      throw handleErros;
    }
  }
}
