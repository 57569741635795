import Workflow_Api from 'app/api/Workflow_Api';
import AttachmentModal, { IAttachmentModal } from 'app/components/Modal/AttachmentModal';
import Toast from 'app/components/Toast';
import { IDataErrors } from 'app/types/data/IData';
import { IDataWorkflow, IDataWorkflowErrors } from 'app/types/data/IDataWorkflow';
import { getMessage } from 'app/utils/messages';
import React from 'react';

declare interface IWorkflowCreateAttachment extends IAttachmentModal {
  data: IDataWorkflow;
  onUpload?: (orientation: IDataWorkflow) => void;
}

const CREATE_MSG = getMessage('Anexo', 'create');

const WorkflowCreateAttachment = (props: IWorkflowCreateAttachment) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clean, setClean] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IDataErrors | undefined>(undefined);

  React.useEffect(() => setOpen(props.open), [props.open]);

  function handleClose() {
    setOpenToast(false);
  }

  async function sendAttachments(files: File[]) {
    setErrors(undefined);
    setLoading(true);
    try {
      const newOrientation = await Workflow_Api.attach(props.data, files);
      setClean(true);
      setOpenToast(true);
      handleCancel();
      if (props.onUpload) props.onUpload(newOrientation);
    } catch (error: IDataWorkflowErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowErrors;
        if (err.errors.status && err.errors.message) setErrors(err.errors);
      }
    } finally {
      setLoading(false);
      setClean(false);
    }
  }

  function handleCancel() {
    setErrors(undefined);
    setOpen(false);
    if (props.onCancel) props.onCancel();
    if (props.onClose) props.onClose();
  }

  return (
    <>
      <AttachmentModal
        errors={errors}
        loading={loading}
        clearFiles={clean}
        open={open}
        onClose={() => {
          setErrors(undefined);
          props.onClose && props.onClose();
        }}
        onCancel={handleCancel}
        onSubmit={sendAttachments}
      />
      <Toast open={openToast} onClose={handleClose} severity="success" message={CREATE_MSG} />
    </>
  );
};

export default WorkflowCreateAttachment;
