const getDateBackgroundColor = (end_date: string) => {
  const currentDate = new Date();
  const endDate = new Date(end_date as string);

  if (currentDate > endDate) {
    return { background: '#ffebe6', color: '#de350b' };
  } else if (
    currentDate < endDate &&
    endDate.getTime() - currentDate.getTime() <= 24 * 60 * 60 * 1000
  ) {
    return { background: '#ffe37c7d' };
  } else {
    return {};
  }
};
export default getDateBackgroundColor;
