import * as yup from 'yup';
import { FormMessages } from '../@Messages';
import { ISelectOption } from 'app/types/ISelectOption';

export const CategorySchema = yup.object().shape({
  nm_categoria: yup.string().when('$isFormHidden', {
    is: false,
    then: yup.string().required(FormMessages('Nome').validations.required),
    otherwise: yup.string().nullable(),
  }),
  alias: yup.string().when('$isFormHidden', {
    is: false,
    then: yup.string().nullable(),//required(FormMessages('Alias Interno').validations.required),
    otherwise: yup.string().nullable(),
  }),
  parent_id: yup.mixed<ISelectOption>().nullable(),
});
