const FixedSelectOptions = {
  Product: {
    integration_status: {
      new: { value: 'new', label: 'Produto Novo' },
      updated_price: { value: 'updated_price', label: 'Produto Atualizado Preço' },
      updated_stock: { value: 'updated_stock', label: 'Produto Atualizado Estoque' },
      updated_price_stock: {
        value: 'updated_price_stock',
        label: 'Produto Atualizado Preço e Estoque',
      },
    },
    integration_situation: {
      received: { value: 'received', label: 'Recebido' },
      imported_worksheet: { value: 'imported_worksheet', label: 'Importado via planilha' },
      sent: { value: 'sent', label: 'Enviado' },
      exported_worksheet: { value: 'exported_worksheet', label: 'Exportado via planilha' },
    },
  },
  Sku: {
    integration_status: {
      new: { value: 'new', label: 'Produto Novo' },
      updated_price: { value: 'updated_price', label: 'Produto Atualizado Preço' },
      updated_stock: { value: 'updated_stock', label: 'Produto Atualizado Estoque' },
      updated_price_stock: {
        value: 'updated_price_stock',
        label: 'Produto Atualizado Preço e Estoque',
      },
    },
    integration_situation: {
      received: { value: 'received', label: 'Recebido' },
      imported_worksheet: { value: 'imported_worksheet', label: 'Importado via planilha' },
      sent: { value: 'sent', label: 'Enviado' },
      exported_worksheet: { value: 'exported_worksheet', label: 'Exportado via planilha' },
    },
  },
  Atrribute: {
    label: {
      1: { value: 1, label: 'Obrigatório' },
      2: { value: 2, label: 'Essencial' },
      3: { value: 3, label: 'Complementar' },
    },
    relative_to: {
      sku: { value: 'sku', label: 'SKU' },
      produto: { value: 'produto', label: 'Produto' },
    },
  },
  Loja: {
    informacao: {
      type: {
        fotos: { value: 'fotos', label: 'Fotos' },
        servico: { value: 'servico', label: 'Serviço' },
        catalogo: { value: 'catalogo', label: ' Aproveitamento de catálogo' },
        troca_colecao: { value: 'troca_colecao', label: 'Troca de coleção' },
        particularidade_agrupamento: {
          value: 'particularidade_agrupamento',
          label: 'Particularidade agrupamento',
        },
        particularidade_criação_de_codigos_regras: {
          value: 'particularidade_criação_de_codigos_regras​',
          label: 'Particularidade criação de códigos - regras​',
        },
        particularidade_coleta_de_imagens: {
          value: 'particularidade_coleta_de_imagens',
          label: 'Particularidade coleta de imagens​',
        },
        canais_de_vendas: {
          value: 'canais_de_vendas',
          label: 'Canais de vendas (site do cliente, outros mkp, ecom, etc)​',
        },
        diagnostico_de_negócio: {
          value: 'diagnostico_de_negócio',
          label: 'Diagnóstico de negócio​',
        },
      },
    },
  },
  Activity: {
    status: {
      'A iniciar': { label: 'A iniciar', value: 'A iniciar' },
      'Em andamento': { label: 'Em andamento', value: 'Em andamento' },
      'Aguardando retorno': { label: 'Aguardando retorno', value: 'Aguardando retorno' },
      Reagendado: { label: 'Reagendado', value: 'Reagendado' },
      Concluída: { label: 'Concluída', value: 'Concluída' },
      Pausada: { label: 'Pausada', value: 'Pausada' },
      Cancelada: { label: 'Cancelada', value: 'Cancelada' },
    },
    quality_status: {
      'Pré-cadastro Iniciado': { label: 'Pré-cadastro Iniciado' },
      'Pré-cadastro Finalizado': { label: 'Pré-cadastro Finalizado' },
      'Ajuste necessário (Umami.IA)': { label: 'Ajuste necessário (Umami.IA)' },
      'Revisão Qualidade': { label: 'Revisão Qualidade' },
      'Ajuste necessário (Qualidade)': { label: 'Ajuste necessário (Qualidade)' },
      'Finalizado Qualidade': { label: 'Finalizado Qualidade' },
    },
    priority: {
      1: { value: 1, label: 'Muito Alta' },
      2: { value: 2, label: 'Alta' },
      3: { value: 3, label: 'Média' },
      4: { value: 4, label: 'Baixa' },
      5: { value: 5, label: 'Muito Baixa' },
    },
    notification_conf: {
      sending_frequency: {
        daily: { value: 'daily', label: 'Diariamente' },
        weekly: { value: 'weekly', label: 'Semanalmente' },
        monthly: { value: 'monthly', label: 'Mensalmente' },
      },
    },
  },
};

export default FixedSelectOptions;
