import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  CategoryFields,
  CategoryRelations,
  ICategoryFilter,
  ICategoryPagination,
  IDataCategory,
  IDataCategoryErrors,
  IHookFormCategory,
} from 'app/types/data/IDataCategory';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';

export default class CategoriesAttribute_Api extends Model_Api {
  static self = new CategoriesAttribute_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataCategoryErrors {
    const resp: IDataCategoryErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormCategory[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as CategoryFields;
      const item: IHookFormCategory = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: ICategoryFilter,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<ICategoryPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}${relation.id ? `/${relation.id}` : ""}/categoria`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as ICategoryPagination;
      else
        return (
          await Api.get(Endpoints.Category.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as ICategoryPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Categories', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    //const categoriaId = data?.categorias_id?.value;
    try {
      const resp = (await Api.post(Endpoints.Category.main, data)).data.categoria as IDataCategory;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/categoria/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Categories', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      const resp = (await Api.put(`${Endpoints.Category.main}/${data.id}`, data)).data.categoria;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataCategory,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      var resp: IDataCategory;
      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`
          )
        ).data.categoria;
      else resp = (await Api.delete(`${Endpoints.Category.main}/${data.id}`)).data.categoria;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Categories', handleErros);
      throw handleErros;
    }
  }

  static async combo(search: string = '') {
    try {
      return (await Api.get(Endpoints.Category.combo, { params: { q: search } })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Combo Categories', handleErros);
      throw handleErros;
    }
  }

  static async duplicate(
    category_id: number,
    relation?: IDataRelation<CategoryRelations>
  ): Promise<IDataCategory> {
    try {
      var resp;
      if (relation) {
        resp = await Api.post(
          `${Endpoints[relation.relation].main}/${relation.id}/categoria/${category_id}/copy`
        );
      } else {
        resp = (await Api.post(`${Endpoints.Category.main}/${category_id}/copy`)).data.categoria;
      }

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }
}
