import { Grid, Paper, TablePagination } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import Segmentation_Api from 'app/api/Segmentation_Api';
import useAuth from 'app/hooks/useAuth';
import FeedbackPages, { IFeedbackPages } from 'app/components/FeedbackPages';
import AlertModal, { IAlertModal } from 'app/components/Modal/AlertModal';
import Toast from 'app/components/Toast';
import Toolbar from 'app/components/Toolbar/Toolbar';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IParamsQs } from 'app/types/IParams';
import { IToast } from 'app/types/IToast';
import { IDataRelation } from 'app/types/data/IData';
import {
  IDataSegmentation,
  IDataSegmentationErrors,
  SegmentationRelations,
} from 'app/types/data/IDataSegmentation';
import filterObjectColumns from 'app/utils/filterObjectColumns';
import { formatDateBR } from 'app/utils/format';
import { getMessage } from 'app/utils/messages';
import React, { Fragment, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import CreateAttachment from '../CreateAttachment';
import SegmentationFormContainer from '../SegmentationFormContainer';
import SegmentationLibrary from './SegmentationLibrary';
import selectRow from 'app/utils/selectRow';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import { useLocation, useParams } from 'react-router-dom';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
import * as XLSX from 'xlsx';

/* const hiddenColumns = {
  create_user: false,
  create_user_id: false,
  update_user: false,
  update_user_id: false,
  nm_host: false,
  ds_iphost: false,
}; */

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.1, type: 'number' },
  {
    field: 'name',
    headerName: 'Nome',
    flex: 1,
    //width: 150,
    editable: true,
  },
  {
    field: 'modelo_segmentacao_id',
    headerName: 'Modelo de Segmentação',
    flex: 1,
    //width: 150,
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataSegmentation>) => {
      const row = params?.api?.getRow(params.id);
      return row?.modelo_segmentacao ? row.modelo_segmentacao.name : '';
    },
  },
  {
    field: 'segmento_segmentacao_id',
    headerName: 'Segmento',
    flex: 1,
    //width: 150,
    editable: true,
    valueFormatter: (params: GridValueFormatterParams<IDataSegmentation>) => {
      const row = params?.api?.getRow(params.id);
      return row?.segmento_segmentacao ? row.segmento_segmentacao.name : '';
    },
  },
  {
    field: 'nm_host',
    headerName: 'Host',
    hideable: true,
  },
  {
    field: 'ds_iphost',
    headerName: 'IP',
    hideable: true,
  },
  {
    field: 'create_user_id',
    headerName: 'Criador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'create_user',
    headerName: 'Criador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataSegmentation>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'update_user_id',
    headerName: 'Atualizador ID',
    hideable: true,
    editable: false,
  },
  {
    field: 'update_user',
    headerName: 'Atualizador',
    hideable: true,
    editable: false,
    valueFormatter: (params: GridValueFormatterParams<IDataSegmentation>) =>
      params.value ? params.value.name : '',
  },
  {
    field: 'created_at',
    headerName: 'Criado em',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
  {
    field: 'updated_at',
    headerName: 'Última atualização',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params: GridValueFormatterParams<string>) => formatDateBR(params.value),
  },
];

const header = columns.map((item) => item.headerName);

const UPDATE_MSG = getMessage('Segmentação', 'update');
const DELETE_MSG = getMessage('Segmentação', 'delete');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const initialConfirmDeleteModal: IAlertModal = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
  loading: true,
};

declare interface IDisplaySegmentation {
  relation?: IDataRelation<SegmentationRelations>;
  onSubmit?: (item?: IDataSegmentation) => void;
  sequency?: any;
  displayName?: string;
  campos?: IDataBpmCampo[]
}

const DisplaySegmentation = (props: IDisplaySegmentation) => {
  const { handleResetForm } = useUtilsContext();
  const sequencyName = localStorage.getItem('sequencyName')
  const [isGridView, toggleView] = React.useState(!(sequencyName && sequencyName.toLowerCase().includes('biblioteca de árvores')));
  const [typeForm, setTypeForm] = React.useState('');
  const { permissionsTags, showAction } = useAuth();
  const [data, setData] = React.useState<IDataSegmentation[]>([]);
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [params, setParams] = React.useState<IParamsQs | undefined>(undefined);
  const [selected, setSelected] = React.useState<IDataSegmentation | undefined>(undefined);
  //const [isModalOpen, toggleModal] = React.useState<boolean>(false);
  const [openAttach, toggleAttachModal] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accessError, setAccessError] = React.useState<IFeedbackPages | null>(null);
  const [alertModal, setAlertModal] = React.useState<IAlertModal>(initialConfirmDeleteModal);
  //const [rowStopReason, setRowStopReason] = React.useState<string>();
  //const [onSaveRow, setOnSaveRow] = React.useState<string | null>(null);
  const [isLoadingAllGridRows, setLoadingAllGridRows] = React.useState<boolean>(false);
  const [allPageRows, setAllPageRows] = React.useState<any[][]>([]);
  const [rowToSelect, setRowToSelect] = React.useState<string | null>(null);
  const [requestLoading, setRequestLoading] = React.useState({ duplicateCategorie: false });
  const csvLink = React.useRef<any | null>();
  const { optionsShopping, optionsProjeto, optionsLoja, optionsClientes } = useAuth();
  const location = useLocation()
  const { id } = useParams()

  const { uuid } = useTrailHistory({
    selected,
    selectedName: selected?.name,
    displayName: props?.displayName || 'Segmentações',
    toggleView,
  });

  const currentSelectedRowIndex = data?.findIndex((item) => item?.id === selected?.id);

  React.useEffect(() => {
    setTotal((prevRowCountState) => (total !== undefined ? total : prevRowCountState));
  }, [total, setTotal]);

  React.useEffect(() => {
    if (isGridView) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageSize, page, params, isGridView]);

  React.useEffect(() => {
    if (!selected) {
      setSelected(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (sequencyName && sequencyName.toLowerCase().includes('biblioteca de árvores')) {
      toggleView(false);
      setSelected(undefined);
      setTypeForm('create')
    }

    return () => {
      const sequencyName = localStorage.getItem('sequencyName')
      if (sequencyName && sequencyName.toLowerCase().includes('biblioteca de árvores')) {
        localStorage.removeItem('sequencyName')
      }
    };
  }, []);

  async function load() {



    setLoading(true);
    try {
      const items =
        await Segmentation_Api.list(
          {
            term: search,
            page: -1,
            per_page: pageSize,
            model_sort: 'modelo_segmentacao_id',
            ...params,
          },
          props.relation
        );

      setData(items.data);
      setTotal(items.pagination.total);
      setTotalPages(items.pagination.total_pages);
      if (isGridView) {
        selectRow<IDataSegmentation>({
          data: items.data,
          selected,
          currentSelectedRowIndex,
          rowToSelect,
          setSelected,
        });
      }
    } catch (error: IDataSegmentationErrors | unknown) {
      if (error) {
        const err = error as IDataSegmentationErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    } finally {
      //toggleModal(false);
      setLoading(false);
    }
  }

  /*   const processRowUpdate = async (newRow, oldRow) => {
      if (rowStopReason === 'enterKeyDown' || onSaveRow) {
        const response = await Segmentation_Api.update(newRow);
        load();
        setToast({ open: true, message: UPDATE_MSG, severity: initialToast.severity });
        setOnSaveRow(null);
        return response;
      }
      setOnSaveRow(null);
      return oldRow;
    }; */

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  function setDeleteModal() {
    setAlertModal({
      open: true,
      title: 'Remover Segmentação',
      message: (
        <span>
          Tem certeza que deseja {props.relation ? 'retirar' : 'remover'} a segmentação{' '}
          <strong>
            {selected?.id} -{' '}
            {selected && selected.name.length > 20
              ? `${selected?.name.substring(0, 20)}...`
              : selected?.name}
          </strong>
          ?
        </span>
      ),
      onClose: () => setAlertModal({ ...alertModal, open: false }),
      onConfirm: () => handleDelete(),
    });
  }

  async function handleDelete() {
    try {
      setAlertModal((prev) => ({ ...prev, loading: true }));
      if (selected) await Segmentation_Api.delete(selected, props.relation);
      setAlertModal((prev) => ({ ...prev, open: false, loading: false }));
      load();
      setToast({ open: true, message: DELETE_MSG, severity: initialToast.severity });
      setAlertModal({ ...alertModal, open: false });
    } catch (error: IDataSegmentationErrors | unknown) {
      if (error) {
        const err = error as IDataSegmentationErrors;
        if (err.errors.status && err.errors.message)
          setAlertModal({
            open: true,
            title: `${err.errors.status} - Não foi possível prosseguir`,
            message: err.errors.message,
            onClose: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
            onConfirm: () => setAlertModal((prev) => ({ ...prev, open: false, loading: false })),
          });
      }
    }
  }

  function handleUpload(Segmentation: IDataSegmentation) {
    setSelected(Segmentation);
  }
  async function loadAllGridRows() {
    try {
      setLoadingAllGridRows(true);
      const items = await Segmentation_Api.list(
        { term: search, page: 1, per_page: total, ...params },
        props.relation
      );
      items.data.forEach((item) => {
        item['created_at'] = formatDateBR(item['created_at']);
        item['updated_at'] = formatDateBR(item['updated_at']);
      });
      const fields = columns.map((item) => item.field);
      const result: SetStateAction<any[][]> = [
        fields,
        ...items.data.map((obj) => fields.map((key) => obj[key])),
      ];
      const filteredResult = filterObjectColumns(result);
      filteredResult[0] = header;
      setAllPageRows(filteredResult);
      setLoadingAllGridRows(false);
      const dados = await filterObjectColumns(result, 'nm_parceiro');
      const ws = XLSX.utils.aoa_to_sheet(dados);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Projetos');
      XLSX.writeFile(wb, 'Projetos.xlsx');
      // csvLink?.current?.link?.click();
    } catch (error: IDataSegmentationErrors | unknown) {
      setLoadingAllGridRows(false);
      if (error) {
        const err = error as IDataSegmentationErrors;
        if (err?.errors?.status && err?.errors?.message)
          setAccessError({ code: err.errors.status, message: err.errors.message });
      }
    }
  }

  function onFilter(filters: IParamsQs) {
    setPage(0);
    setSelected(undefined);
    setRowToSelect(null);
    setParams({ ...filters, page: 0 });
  }

  async function duplicateRow() {
    try {
      setRequestLoading((prev) => ({ ...prev, duplicateSegmentation: true }));
      await Segmentation_Api.duplicate(selected?.id as number, props.relation);
      load();
      setToast({
        open: true,
        message: 'Item copiado com sucesso',
        severity: initialToast.severity,
      });
      setRequestLoading((prev) => ({ ...prev, duplicateSegmentation: false }));
    } catch (error) {
      setToast({
        open: true,
        message: 'Não foi possível realizar a cópia. Tente novamente mais tarde',
        severity: 'error',
      });
      setRequestLoading((prev) => ({ ...prev, duplicateSegmentation: false }));
    }
  }

  const ToolbarComponent = (props) => (
    <Toolbar
      data={data}
      rowSelection={selected}
      options={{
        total: {
          value: total,
          total_pages: totalPages,
        },
        cancel: {
          onClick: () => setSelected(undefined),
        },
        view: {
          state: isGridView ? 'grid' : 'form',
        },
        filter: {
          columns,
          onFilter: onFilter,
        },
        page: page + 1,
        isLoadingAllGridRows: isLoadingAllGridRows,
        isLoadingGrid: loading,
        data: data,
        selectedRow: selected,
        requestLoading,
        typeItem: 'segmentacao'
      }}
      onAddClick={() => {
        setSelected(undefined);
        toggleView(false);
        setTypeForm('create')
      }}
      onMinusClick={!props.relation ? undefined : () => setDeleteModal()}
      onClickView={() => toggleView(!isGridView)}
      onClickRefresh={() => load()}
      onDeleteClick={props.relation ? undefined : () => setDeleteModal()}
      //onClickAttach={() => toggleAttachModal(true)}
      searchValue={search}
      onSearchChange={(value) => setSearch(value)}
      onResetForm={() => handleResetForm('SegmentationForm')}
      //onSaveRow={() => setOnSaveRow('save')}
      duplicateRow={() => duplicateRow()}
      exportGridData={() => loadAllGridRows()}
      navigate={(value) => {
        setSelected(value);
      }}
      navigateToPage={(page, rowToSelect = 'first') => {
        setRowToSelect(rowToSelect);
        setPage(page - 1);
      }}
      sticky
      {...props}
    />
  );

  if (accessError) {
    return <FeedbackPages code={accessError.code} message={accessError.message} />;
  } else {
    return (
      <Fragment>
        <Grid
          container
          display="flex"
          flexDirection="column"
          spacing={3}
          style={{ position: 'relative' }}
        >
          {/* {!isGridView && <ToolbarComponent />} */}

          <ToolbarComponent hasExportButton={isGridView} fileName="UMAMI _ Segmentação" />
          {/* {isGridView && (
            <TableMUI
              initialState={{
                columns: {
                  columnVisibilityModel: hiddenColumns,
                },
              }}
              onCellDoubleClick={(event) => {
                if (event.field === 'id') {
                  toggleView(false);
                }
              }}
              columns={columns}
              rows={data}
              page={page}
              rowCount={total}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
onSortModelChange={(model) => {
                handleOnSortModelChange(model, setParams, setRowToSelect);
              }}
              autoHeight
              onRowEditStop={(params) => {
                setRowStopReason(params.reason);
              }}
              processRowUpdate={processRowUpdate}
              loading={loading}
              onRowClick={(row) => {
                setSelected(row.row);
              }}
              onSelectionModelChange={(newSelection) => {
                const row = data.filter((item) => item.id === newSelection[0]);
                setSelected(row[0]);
              }}
              selectionModel={[selected?.id as number]}
              customToolbar={() => (
                <ToolbarComponent hasExportButton isGridRef fileName="UMAMI _ Segmentação" />
              )}
            />
          )} */}
          <CSVLink
            data={allPageRows}
            target="_blank"
            ref={csvLink}
            filename="Segmentação"
            separator=";"
          />
          {!isGridView && (
            <SegmentationFormContainer
              uuid={uuid}
              data={selected}
              setSelected={(data) => setSelected(data)}
              relation={props.relation}
              onSubmit={(data) => {
                setSelected(data);
                if (props.sequency && props.onSubmit) {
                  props.onSubmit();
                } else {
                  load();
                }
              }}
              cancel={() => toggleView(!isGridView)}
              onClickAttach={() => toggleAttachModal(true)}
              refreshGrid={() => load()}
              editDisabled={!showAction({ action: 'edit', tag: 'segmentacao' }, permissionsTags)}
              campos={props.campos}
              sequency={props.sequency}
              typeForm={typeForm}
            />
          )}
          {isGridView && (
            <Paper sx={{ position: 'relative', padding: '1rem' }}>
              <SegmentationLibrary
                selected={selected}
                data={data}
                setSelected={(data) => setSelected(data)}
                loadingData={loading}
                toggleView={(value) => toggleView(value)}
              />
              {Boolean(data.length) && !loading && isGridView && (
                <TablePagination
                  style={{
                    position: 'absolute',
                    bottom: 5,
                    right: 1,
                  }}
                  component="div"
                  labelRowsPerPage="Linhas por página:"
                  count={total}
                  page={page}
                  onPageChange={(
                    event: React.MouseEvent<HTMLButtonElement> | null,
                    newPage: number
                  ) => {
                    setPage(newPage);
                    setRowToSelect(null);
                  }}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
                  labelDisplayedRows={({ from, count, to }) =>
                    `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
                  }
                />
              )}
            </Paper>
          )}
        </Grid>
        {/* <CreateSegmentationModal
          open={isModalOpen}
          relation={props.relation}
          onClose={() => toggleModal(false)}
          onSubmit={() => load()}
        /> */}
        {selected && (
          <CreateAttachment
            data={selected}
            open={openAttach}
            onClose={() => toggleAttachModal(false)}
            onUpload={handleUpload}
          />
        )}
        <AlertModal
          open={alertModal?.open}
          loading={alertModal?.loading}
          title={alertModal?.title}
          message={alertModal?.message}
          onClose={alertModal.onClose}
          onConfirm={alertModal.onConfirm}
        />
        <Toast
          open={toast.open}
          onClose={handleCloseToast}
          severity={toast.severity}
          message={toast.message}
        />
      </Fragment>
    );
  }
};

export default DisplaySegmentation;
