import { IParamsQs } from '../IParams';
import { ISelectOption } from '../ISelectOption';
import { HookForm, IAttachments, IData, IDataErrors, IDataPagination } from './IData';
import { IDataUser } from './IDataUser';
import { IDataWorkflow } from './IDataWorkflow';
import { IDataWorkflowPhases } from './IDataWorkflowPhases';

export type ActivityFields =
  | 'id'
  | 'summary'
  | 'description'
  | 'performer_id'
  | 'assign_id'
  | 'pr_activity_id'
  | 'position'
  | 'status'
  | 'start_date'
  | 'end_date'
  | 'order';
export type ActivityRelations = 'Workflow' | 'Activity' | 'WorkflowPhases' | 'ActivitySuccessors';

export interface NotificationConf {
  sending_frequency?: ISelectOption | null;
  start_in?: Date | null;
  send_email?: boolean;
  email_text?: string | null;
  send_sms?: boolean;
  sms_text?: string | null;
  send_whatsapp?: boolean;
  whatsapp_text?: string | null;
}

interface IDataPredecessor extends IDataActivity {}

export interface IDataActivity extends IData, IAttachments {
  summary: string;
  description?: string;
  workflow_id?: number | string | null;
  workflow?: IDataWorkflow;
  duration?: string | number | null;
  duration_for_humans?: string | null;
  stage_id: number | string | null;
  stage?: IDataWorkflowPhases;
  pr_activity_id?: number | string | null;
  predecessor?: IDataPredecessor | null | undefined;
  start_date?: Date | null | string;
  end_date?: Date | null | string;
  //duration: IDuration;
  delivery_date?: Date | null;
  performer?: IDataUser;
  performer_id: number | null;
  assign?: IDataUser;
  assign_id: number | null;
  quality_status: string | null;
  status?: string | null;
  priority: ISelectOption | null;
  tags: string[] | null;
  value: number | null;
  progress: number | null;
  position?: number | null;
  order?: number;
  nivel: string;
  notification_conf?: NotificationConf;
  parent_id?: string | number;
  has_children?: boolean;
  internal_message?: string;
}

export const initialCopyActivity: IDataCopyActivity = {
  workflow_id: null,
  workflow: null,
  stage_id: null,
  stage: null,
  to_keep: [],
};

export interface IActivityFilter extends IParamsQs<ActivityFields> {
  id?: string;
  atividade_atual?: number | string | null;
  summary?: string;
  description?: string;
  stage_id?: number;
  pr_activity_id?: number | string | null;
  start_date?: string;
  end_date?: string;
  delivery_date?: string;
  performer_id?: number | string;
  assign_id?: number | string;
  quality_status?: string;
  priority?: number | string;
  tags?: string;
  value?: number;
  progress?: number;
  status?: string[];
  nm_host?: string;
  ds_iphost?: string;
  create_user_id?: number;
  create_user?: string;
  update_user_id?: number;
  update_user?: string;
}

export interface IActivityPagination extends IDataPagination<IDataActivity> {}

declare interface ActivityErrors extends IDataErrors {
  id?: string[];
  summary: string;
  assignee_id: string;
  perfomed_by: string;
  pr_activity_id?: string;
}

export declare interface IHookFormActivity extends HookForm {
  name: ActivityFields;
}

export interface IDataActivityErrors {
  errors: ActivityErrors;
  hookForm?: IHookFormActivity[];
}

/**
 * Activityes Copy
 */

export type ActivityCopyFields = 'workflow_id' | 'stage_id';

export declare interface IHookFormActivityCopy extends HookForm {
  name: ActivityCopyFields;
}
export interface IDataCopyActivity {
  workflow_id: number | string | null;
  workflow: IDataWorkflow | null;
  stage_id: number | string | null;
  stage: IDataWorkflowPhases | null;
  to_keep?: string[];
}

declare interface ActivityCopyErrors extends IDataErrors {
  workflow_id?: string[];
  stage_id?: string;
}

export interface IDataActivityCopyErrors {
  errors: ActivityCopyErrors;
  hookForm?: IHookFormActivityCopy[];
}

export interface IDataIDClone {
  workflow_id: number | string;
  stage_id: number | string;
  to_keep?: string[];
  id: number[] | string[];
}
