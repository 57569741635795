import React from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import Workflow_Api from 'app/api/Workflow_Api';
import { ISelectOption } from 'app/types/ISelectOption';
import {
  IDataWorkflow,
  IDataWorkflowErrors,
  IWorkflowPagination,
  WorkflowRelations,
} from 'app/types/data/IDataWorkflow';
import Option from '../Option/Option';
import { Search } from '@mui/icons-material';
import { IDataRelation } from 'app/types/data/IData';
import TimedTextField from '../../TimedTextField/TimedTextField';

export interface ISelectWorkflow {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: IDataWorkflow;
  disabled?: boolean;
  error?: string;
  relation?: IDataRelation<WorkflowRelations>;
  allWorkflows?: boolean; //Buscará por todos os Workflows, com ou sem projetos
  showToKeep?: boolean;
  showSecondaryOption?: boolean;
  onChange?: (Workflow?: IDataWorkflow) => void;
  onChangeKeep?: (toKeep?: string[]) => void;
}

const to_keep_options = [
  { name: 'Etapas', value: 'etapas' },
  { name: 'Atividades', value: 'atividades' },
  { name: 'Anexos', value: 'midia' },
];

const SelectWorkflow = (props: ISelectWorkflow) => {
  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataWorkflow>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataWorkflow> | undefined>(
    undefined
  );

  const [errors, setErros] = React.useState(props.error);
  const [toKeep, setToKeep] = React.useState<string[]>(to_keep_options.map((obj) => obj.value));

  React.useEffect(() => setErros(props.error), [props.error]);

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (props.onChangeKeep) props.onChangeKeep(toKeep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toKeep]);

  React.useEffect(() => {
    const comboValue: ISelectOption<IDataWorkflow> | undefined = props.value
      ? {
          label: props.value?.name,
          value: props.value?.id as number,
          data: props.value,
        }
      : undefined;
    setComboOption(comboValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function load() {
    setLoading(true);
    try {
      let items: IWorkflowPagination | undefined = undefined;

      if (props.allWorkflows) items = await Workflow_Api.all({ q: search });
      else items = await Workflow_Api.list({ q: search }, props.relation);

      const combo: ISelectOption<IDataWorkflow>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item?.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataWorkflowErrors | unknown) {
      if (error) {
        const err = error as IDataWorkflowErrors;
        // eslint-disable-next-line eqeqeq
        if (err.errors.status != '403') setErros(err.errors?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Autocomplete
        id={props.id}
        noOptionsText="Nenhum Workflow encontrado"
        options={comboData}
        defaultValue={comboOption}
        value={comboOption || null}
        loading={loading}
        disabled={props.disabled}
        onClose={() => setSearch('')}
        //isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, option) => {
          if (option && option.data) {
            setComboOption(option);
            if (props.onChange) props.onChange(option.data);
          } else {
            setComboOption(undefined);
            if (props.onChange) props.onChange(undefined);
          }
        }}
        renderOption={(optionProps, option) => (
          <Option
            key={option.value}
            props={optionProps}
            option={option}
            secondary={
              props.showSecondaryOption
                ? option.data?.loja
                  ? `Loja: ${option.data?.loja.id} - ${option.data?.loja.nm_loja}`
                  : option.data?.projeto
                  ? `Projeto: ${option.data?.projeto.id} - ${option.data?.projeto.nm_projeto}`
                  : 'Agnóstico'
                : undefined
            }
          />
        )}
        renderInput={(params) => (
          <TimedTextField
            {...params}
            label={props.label || 'Workflows'}
            placeholder={props.placeholder || `Pesquisar um Workflow`}
            type="text"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            helperText={
              <Typography color="red" variant="caption" component="span">
                {errors}
              </Typography>
            }
          />
        )}
      />
      {comboOption && props.showToKeep && (
        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {to_keep_options.map((option) => (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={toKeep?.includes(option.value)}
                      onChange={() => {
                        if (toKeep?.includes(option.value)) {
                          const filteredOptions = toKeep.filter((opt) => opt !== option.value);
                          setToKeep(filteredOptions);
                        } else {
                          setToKeep([...(toKeep as string[]), option.value]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={option.name}
                />
              </FormGroup>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SelectWorkflow;
