import { styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IDataAttachment } from 'app/types/data/IDataAttachment';
import { Image } from 'pure-react-carousel';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  navigation: {
    width: 100,
    height: 100,
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#FFF',
    marginBottom: 8,
  },
  image: {
    objectFit: 'contain',
  },
  media: {
    //objectFit: 'contain',
    border: 0,
    width: '100%',
  },
  mediaContainerVideo: (props) => ({
    height: 388,
    '& video': {
      height: 'auto !important',
    },
  }),
}));

const MediaContainerVideo = styled('div')(({ theme }) => ({
  height: 420,
  [theme.breakpoints.down('xl')]: {
    height: 550,
  },
  '& video': {
    height: 'auto !important',
  },
}));

export interface IMediaView {
  media: IDataAttachment;
}
const MediaView = (props: IMediaView) => {
  const classes = useStyles();

  const { media } = props;
  if (media.mime_type.startsWith('video/')) {
    return (
      <MediaContainerVideo>
        <ReactPlayer
          url={media.original_url}
          width="100%"
          height="inherit"
          playing
          playIcon={<img src="/assets/images/buttons/play-button-yellow-64.png" alt="Play" />}
          light={media.thumb}
          controls
        />
      </MediaContainerVideo>
    );
  } else {
    return <Image hasMasterSpinner src={media.original_url} className={classes.image} />;
  }
};

export default MediaView;
