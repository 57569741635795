import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  IAttachmentFilter,
  IAttachmentPagination,
  IDataAttachment,
} from 'app/types/data/IDataAttachment';
import {
  ReasonsFields,
  IDataReasons,
  IDataReasonsErrors,
  IHookFormReasons,
  ReasonsRelations,
  IReasonsPagination,
  IReasonsFilter,
} from 'app/types/data/IDataReasons';
import { AxiosError } from 'axios';
import QueryString from 'qs';
import Api from './api';
import Model_Api from './Model_Api';

export default class Reasons_Api extends Model_Api {
  static self = new Reasons_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataReasonsErrors {
    const resp: IDataReasonsErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormReasons[] = [];

    Object.keys(errors).forEach((key) => {
      const summary = key as ReasonsFields;
      const item: IHookFormReasons = {
        type: 'manual',
        summary,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    params?: IReasonsFilter,
    relation?: IDataRelation<ReasonsRelations>
  ): Promise<IReasonsPagination> {
    try {
      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/motivo`, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IReasonsPagination;
      else
        return (
          await Api.get(Endpoints.Reasons.main, {
            params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          })
        ).data as IReasonsPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async show(data: IDataReasons): Promise<IDataReasons> {
    try {
      return (await Api.get(`${Endpoints.Reasons.main}/${data.id}`)).data as IDataReasons;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Show Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataReasons,
    relation?: IDataRelation<ReasonsRelations>
  ): Promise<IDataReasons> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/motivo`, data)
        ).data.motivo as IDataReasons;
      } else {
        resp = (await Api.post(Endpoints.Reasons.main, data)).data.motivo as IDataReasons;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataReasons,
    relation?: IDataRelation<ReasonsRelations>
  ): Promise<IDataReasons> {
    try {
      var resp;
      if (relation) {
        resp = (
          await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/motivo`, data)
        ).data.motivo as IDataReasons;
      } else {
        resp = (await Api.put(`${Endpoints.Reasons.main}/${data.id}`, data)).data
          .motivo as IDataReasons;
      }
      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataReasons,
    relation?: IDataRelation<ReasonsRelations>
  ): Promise<IDataReasons> {
    try {
      var resp: IDataReasons;

      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/motivo/${data.id}`
          )
        ).data.motivo;
      else resp = (await Api.delete(`${Endpoints.Reasons.main}/${data.id}`)).data.motivo;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async listAttachments(
    params?: IAttachmentFilter,
    model?: IDataReasons
  ): Promise<IAttachmentPagination> {
    try {
      return (
        await Api.get(
          model
            ? `${Endpoints.Reasons.main}/${model.id}/attachments`
            : `/media${Endpoints.Reasons.main}`,
          {
            params: params,
            paramsSerializer(params) {
              return QueryString.stringify(params);
            },
          }
        )
      ).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Reasons', handleErros);
      throw handleErros;
    }
  }

  static async attach(motivo: IDataReasons, files: File[]) {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });

      return (await Api.post(`${Endpoints.Reasons.main}/${motivo.id}/attach`, formData)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Reasonss', handleErros);
      throw handleErros;
    }
  }

  static async detach(attachment: IDataAttachment) {
    try {
      return (await Api.delete(`${Endpoints.Reasons.main}/${attachment.id}/detach`)).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Reasonss', handleErros);
      throw handleErros;
    }
  }
}
