import DataTypes from 'app/types/data';

/**
 * Esta função será responsável por selecionar uma linha na tabela, independente se o usuário
 * estiver mudando de página ou se estiver usando a navegação de linhas pela toolbar.
 */

interface SelectRowParams<T extends DataTypes> {
  data: T[];
  selected: T | undefined;
  currentSelectedRowIndex: number;
  rowToSelect: string | null;
  setSelected: React.Dispatch<React.SetStateAction<T | undefined>>;
}

const selectRow = <T extends DataTypes>({
  data,
  selected,
  currentSelectedRowIndex,
  rowToSelect,
  setSelected,
}: SelectRowParams<T>) => {
  var selectedRow: T;
  if (!selected) {
    selectedRow = rowToSelect === 'last' ? data[data.length - 1] : data[0];
  } else {
    selectedRow =
      rowToSelect === 'first'
        ? data[0]
        : rowToSelect === 'last'
        ? data[data.length - 1]
        : data[currentSelectedRowIndex] || data[0];
  }
  setSelected(selectedRow);
};

export default selectRow;
