import { MultiBackend, NodeModel, Tree, getBackendOptions } from '@minoru/react-dnd-treeview';
import { CircularProgress, CssBaseline, Paper, ThemeProvider, styled } from '@mui/material';
import Activity_Api from 'app/api/Activity_Api';
import { IDataRelation } from 'app/types/data/IData';
import { ActivityRelations, IDataActivity } from 'app/types/data/IDataActivity';
import React from 'react';
import { DndProvider } from 'react-dnd';
import styles from './App.module.css';
import { CustomDragPreview } from './components/CustomDragPreview';
import { CustomNode } from './components/CustomNode';
import { Placeholder } from './components/Placeholder';
import { theme } from './components/theme';

const ContentBox = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 1,
  zIndex: 999,
}));

interface ITreeViewActivity {
  data: NodeModel<IDataActivity>[];
  nodeSelected?: NodeModel<IDataActivity>;
  loading?: boolean;
  clearSelected?: boolean;
  onDrop?: () => void;
  onUpdate?: (node: NodeModel<IDataActivity>, newValue: string) => void;
  onClickNode: (node: NodeModel<IDataActivity>) => void;
  relation?: IDataRelation<ActivityRelations>;
}

export default function TreeViewActivity(props: ITreeViewActivity) {
  const [treeData, setTreeData] = React.useState<NodeModel<IDataActivity>[]>(props.data);
  const [selectedNode, setSelectedNode] = React.useState<NodeModel<IDataActivity> | null>(null);
  const [dropLoading, setDropLoading] = React.useState(false);

  const handleDrop = async (newTree: NodeModel<IDataActivity>[], options) => {
    const { dragSourceId, dropTargetId, dragSource } = options;
    try {
      setDropLoading(true);
      const subTree = newTree.filter((node) => node.parent === dropTargetId);
      const newIndex = subTree.findIndex((node) => node.id === dragSourceId);
      await Activity_Api.update(
        {
          ...dragSource.data,
          order: newIndex + 1,
          parent_id: dropTargetId || null,
        },
        props.relation
      );
      setTreeData(newTree);
      if (props.onDrop) props.onDrop();
      setDropLoading(false);
    } catch (error) {
      setDropLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.nodeSelected) setSelectedNode(props.nodeSelected);
  }, [props.nodeSelected]);

  React.useEffect(() => {
    if (props.clearSelected) setSelectedNode(null);
  }, [props.clearSelected]);

  React.useEffect(() => {
    setTreeData(props.data);
  }, [props.data]);

  function handleSelect(node: NodeModel<IDataActivity>) {
    setSelectedNode(node);
    props.onClickNode(node);
  }

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.app}>
            {props.loading ||
              (dropLoading && (
                <ContentBox>
                  <CircularProgress color="primary" />
                </ContentBox>
              ))}
            <Tree
              tree={treeData}
              rootId={0}
              render={(node: NodeModel<IDataActivity>, { depth, isOpen, onToggle }) => (
                <CustomNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={onToggle}
                  onSelect={handleSelect}
                  isSelected={node.id === selectedNode?.id}
                  onUpdate={(value) => (props.onUpdate ? props.onUpdate(node, value) : undefined)}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps} />
              )}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                placeholder: styles.placeholderContainer,
              }}
              sort={false}
              insertDroppableFirst={false}
              canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                if (dragSource?.parent === dropTargetId) {
                  return true;
                }
              }}
              dropTargetOffset={10}
              placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
            />
          </div>
        </DndProvider>
      </ThemeProvider>
    </Paper>
  );
}
