import { styled } from '@mui/material';
import Breadcrumbs from 'app/components/Breadcrumbs';
import { UtilsContextProvider } from 'app/contexts/UtilsContext';
import DisplayStoreStatus from './components/DisplayStoreStatus';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '40px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const StoreStatus = () => {
  return (
    <UtilsContextProvider>
      <div>
        <Breadcrumbs />
      </div>
      <ContentBox>
        <DisplayStoreStatus />
      </ContentBox>
    </UtilsContextProvider>
  );
};

export default StoreStatus;
