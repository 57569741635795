import Endpoints from 'app/config/Endpoints';
import { IDataRelation } from 'app/types/data/IData';
import {
  PartnerCategoryFields,
  PartnerCategoryRelations,
  IPartnerCategoryPagination,
  IDataPartnerCategory,
  IDataPartnerCategoryErrors,
  IHookFormPartnerCategory,
  IDataFilterPartnerCategory,
} from 'app/types/data/IDataPartnerCategory';
import IParams from 'app/types/IParams';
import { AxiosError } from 'axios';
import Api from './api';
import Model_Api from './Model_Api';
import qs from 'qs';

export default class PartnerCategories_Api extends Model_Api {
  static self = new PartnerCategories_Api();

  protected handleErrors(error: AxiosError | Error | string | any): IDataPartnerCategoryErrors {
    const resp: IDataPartnerCategoryErrors = this.formatErrors(error);
    const { errors } = resp;
    const hookForm: IHookFormPartnerCategory[] = [];

    Object.keys(errors).forEach((key) => {
      const name = key as PartnerCategoryFields;
      const item: IHookFormPartnerCategory = {
        type: 'manual',
        name: name,
        message: Array.isArray(errors) ? errors[key][0] : errors[key],
      };
      hookForm.push(item);
    });

    return { errors, hookForm };
  }

  static async list(
    parameters?: IParams<PartnerCategoryFields, IDataFilterPartnerCategory[]>,
    relation?: IDataRelation<PartnerCategoryRelations>
  ): Promise<IPartnerCategoryPagination> {
    try {
      const extra = parameters?.filter;
      var params = parameters;
      delete parameters?.filter;
      params = { filter: extra, ...parameters };

      if (relation)
        return (
          await Api.get(`${Endpoints[relation.relation].main}/${relation.id}/categoria`, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as IPartnerCategoryPagination;
      else
        return (
          await Api.get(Endpoints.PartnerCategory.main, {
            params,
            paramsSerializer: (params) => {
              return qs.stringify(params);
            },
          })
        ).data as IPartnerCategoryPagination;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: List Categories', handleErros);
      throw handleErros;
    }
  }

  static async create(
    data: IDataPartnerCategory,
    relation?: IDataRelation<PartnerCategoryRelations>
  ): Promise<IDataPartnerCategory> {
    //const categoriaId = data?.categorias_id?.value;
    try {
      const resp = (await Api.post(Endpoints.PartnerCategory.main, data)).data
        .categoria as IDataPartnerCategory;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/categoria/${resp.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Create Categories', handleErros);
      throw handleErros;
    }
  }

  static async update(
    data: IDataPartnerCategory,
    relation?: IDataRelation<PartnerCategoryRelations>
  ): Promise<IDataPartnerCategory> {
    try {
      const resp = (await Api.put(`${Endpoints.PartnerCategory.main}/${data.id}`, data)).data
        .categoria;

      if (relation)
        await Api.post(`${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`);

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Update Categories', handleErros);
      throw handleErros;
    }
  }

  static async delete(
    data: IDataPartnerCategory,
    relation?: IDataRelation<PartnerCategoryRelations>
  ): Promise<IDataPartnerCategory> {
    try {
      var resp: IDataPartnerCategory;
      if (relation)
        resp = (
          await Api.delete(
            `${Endpoints[relation.relation].main}/${relation.id}/categoria/${data.id}`
          )
        ).data.categoria;
      else resp = (await Api.delete(`${Endpoints.PartnerCategory.main}/${data.id}`)).data.categoria;

      return resp;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Delete Categories', handleErros);
      throw handleErros;
    }
  }

  static async combo(search: string = '') {
    try {
      return (await Api.get(Endpoints.PartnerCategory.combo, { params: { q: search } })).data;
    } catch (error) {
      const handleErros = this.self.handleErrors(error);
      console.log('error: Combo Categories', handleErros);
      throw handleErros;
    }
  }
}
